import fangoutsPublicService from './fangoutsPublicService'
import ActionTypes from '../fangouts/ActionTypes'
import publicProfileService from "../publicProfile/publicProfileService";
import {ActionTypes as HomeActionTypes} from '../homeReducer'
import {getAllShows} from '../createShow/showService';

export function setFangouts(dispatch) {
	return fangoutsPublicService.getFangouts().then(res => {
		dispatch({
			type: ActionTypes.SET_FANGOUTS,
			fangouts: res.data
		})
		return res
	})
}

export function setShows(dispatch) {
	return getAllShows().then(res => {
		dispatch({
			type: ActionTypes.SET_SHOWS,
			shows: res.data
		})
		return res
	})
}


export function setFangoutData(dispatch, data) {
	dispatch({
		type: ActionTypes.SET_FANGOUT_DATA,
		data
	})
}

export function getTopPopular(dispatch, count) {
	return publicProfileService.getTopPopular(count)
		.then(res => {
			dispatch({
				type: HomeActionTypes.SET_TOP_POPULAR,
				payload: res.data
			})
		})
}

export function getTopActive(dispatch, count) {
	return publicProfileService.getTopActive(count)
		.then(res => {
			dispatch({
				type: HomeActionTypes.SET_TOP_ACTIVE,
				payload: res.data
			})
		})
}