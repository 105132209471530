import React from 'react';
import { Container } from '../../styling/globalStyling';
import Heading from '../../components/heading/heading';
import GoBack from '../../components/goBack/goBack';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getReferrals } from '../profile/profileActions';
import { withRouter } from 'react-router-dom';
import IsVerifiedBadge from '../../components/isVerifiedBadge/isVerifiedBadge';
import emptyAvatar from '../../assets/images/empty-avatar.jpg';
import ReactGA from 'react-ga4';

class Referrals extends React.PureComponent {
  componentDidMount() {
    ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });
    this.props.getReferrals();
  }

  goToUserPublicProfile = (referral) => {
    this.props.history.push({
      pathname: '/public/' + referral.id,
      user: referral,
    });
  };

  render() {
    return (
      <Container>
        <Heading title={this.props.lang.referrals_title} />
        <GoBack customAction={() => this.props.history.push('/profile')} />
        <ContainerExtended className={'p016 mt2'}>
          {this.props.referrals &&
            this.props.referrals.map((referral) => {
              const isVerified = referral.verified === 'VERIFIED';
              return (
                <Referral key={referral.username}>
                  <div
                    className={'userDetails'}
                    onClick={() => this.goToUserPublicProfile(referral)}
                  >
                    {/*<img src={post.user.profilePhoto} alt={'avatar'}/>*/}
                    <div className={'relative'}>
                      <img
                        src={referral.profilePhoto || emptyAvatar}
                        alt={'avatar'}
                      />
                      <IsVerifiedBadge
                        isVerified={isVerified}
                        fontSize={'10px'}
                      />
                    </div>
                    <div style={{ marginLeft: '12px' }}>
                      <div className={'username'}>@{referral.username}</div>
                      <div className={'name'}>
                        {referral.name} - {referral.profession}
                      </div>
                    </div>
                  </div>
                </Referral>
              );
            })}
        </ContainerExtended>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    referrals: state.profile.referrals,
    lang: state.home.language,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getReferrals: () => getReferrals(dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Referrals)
);

const ContainerExtended = styled(Container)`
  .avatar {
    width: 100%;
    border-radius: 10px;
  }
`;
const Referral = styled.div`
  &:nth-child(1) {
    padding-top: 10px;
  }
  margin-bottom: 12px;
  .userDetails,
  .caption {
    padding: 0 16px;
  }
  .userDetails {
    font-size: 12px;
    display: flex;
    img {
      width: 32px;
      height: 32px;
      border-radius: 6px;
    }
    .name {
      font-weight: 500;
    }
    .username {
      font-weight: 600;
    }
  }
`;
