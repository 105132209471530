import config from "../../config";
import axios from "axios";

export function checkoutTickets(data: any) {
	const url = `${config.baseApi}/v1/tickets`
	return axios.post(url, data)
}

export function getMyTickets(data: any = {
	onlyValid: true,
	showId: 0
}) {
	const url = `${config.baseApi}/v1/tickets/mytickets`
	return axios.post(url, data)
}