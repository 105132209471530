import visaCheck from '../../assets/images/paymentLogotips/visa-secure.jpg';
import mcIdCheck from '../../assets/images/paymentLogotips/mc_idcheck_vrt_rgb_pos.png';
import chipLogo from '../../assets/images/paymentLogotips/ChipCard LOGO 2021_rgb.png';
import dina from '../../assets/images/paymentLogotips/dina.jpg';
import visa from '../../assets/images/paymentLogotips/visa.png';
import maestro from '../../assets/images/paymentLogotips/ms_acc_opt_70_1x.png';
import mc from '../../assets/images/paymentLogotips/mc_acc_opt_70_1x.png';
import bancaIntesa from '../../assets/images/paymentLogotips/banca_intesa.png';
import { HTMLAttributes } from 'react';

import styles from './PaymentLogotips.module.scss';

const PaymentLogotips = (props: HTMLAttributes<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return (
    <div className={`${styles.container} ${className}`} {...rest}>
      <ul>
        <li>
          <img src={maestro} alt='Maestro' />
        </li>
        <li>
          <img src={mc} alt='Master' />
        </li>
        <li>
          <img src={dina} alt='Visa' />
        </li>
        <li>
          <img src={visa} alt='Visa' />
        </li>
        <li>
          <a href='https://chipcard.rs/ecommerce/'>
            <img src={chipLogo} alt='Chip Card' />
          </a>
        </li>

        <li>
          <a href='https://rs.visa.com/pay-withvisa/security-and-assistance/protected-everywhere.html'>
            <img
              className={styles.logoProgram}
              src={visaCheck}
              alt='Visa protect'
            />
          </a>
        </li>
        <li>
          <a href='http://www.mastercard.com/rs/consumer/credit-cards.html'>
            <img
              className={styles.logoProgram}
              src={mcIdCheck}
              alt='Mastercard securecode'
            />
          </a>
        </li>
      </ul>
      {/* <div className={styles.group}>
        <a
          href='https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html'
          target='_blank'
          rel='noreferrer'
        >
          <img
            className={styles.logoProgram}
            src={visaCheck}
            alt='visa-secure'
          />
        </a>
        <a
          href='http://www.mastercard.com/rs/consumer/credit-cards.html'
          target='_blank'
          rel='noreferrer'
        >
          <img
            className={styles.logoProgram}
            src={mcIdCheck}
            alt='mastercard'
          />
        </a>
      </div>
      <div className={styles.group}>
        <a
          href='https://chipcard.rs/ecommerce/'
          target='_blank'
          rel='noreferrer'
        >
          <img className={styles.chip} src={chipLogo} alt='chipcard' />
        </a>
        <img className={styles.logo} src={mc} alt='mastercard' />
        <div className={styles.group}>
        <img className={styles.logo} src={maestro} alt='maestro' />
        <img className={styles.logo} src={dina} alt='dina' />
        <img className={styles.logo} src={visa} alt='visa' />
        </div>
      </div> */}
    </div>
  );
};
export default PaymentLogotips;
