import React from 'react'
import {withRouter} from 'react-router-dom'
import styled from 'styled-components'
import Heading from '../../components/heading/heading'
import queryString from 'query-string'
import authService from './authService'
import * as constants from '../../constants'
import beerSpinner from '../../assets/images/beer.gif'
import {Container} from '../../styling/globalStyling'

const Verify = (props) => {

	const [verify, setVerify] = React.useState(null)
	const [spinner, setSpinner] = React.useState(false)
	const [error, setError] = React.useState('')

	React.useEffect(() => {
		const values = queryString.parse(props.location.search)
		if (values.token) {
			setError('')
			setSpinner(true)
			setTimeout(() => {
				authService.verifyAccount(values.token)
				.then(res => {
					localStorage.setItem(constants.SESSION_KEY, JSON.stringify(res.data));
					setVerify(true)
					setTimeout(() => {props.history.push('/home')}, 3000)
				})
				.catch(e => {
					setError(e.message)
				})
				.finally(() => {setSpinner(false)})
			}, 2000)
		}
	},[props.match, props.history])

	return <Container className={'verify'}>
		<Heading title={'Verify'}/>
		{spinner && <SpinnerWrapper>
			<img src={beerSpinner} alt={'spinner'}/>
			<div className={'text-center text-size-s'}>Verifying...</div>
		</SpinnerWrapper>}
		{error && <div className={'text-center text-danger'}>{error}</div>}
		{verify !== null && (verify ? <div className={'mt3'}>
			<div className={'text-bold text-size-xl text-center'}>Verified Successfully</div>
			<div className={'text-normal text-size-s text-center'}>(You'll be redirected in few seconds)</div>
		</div> : <div className={'text-center mt3'}>Validation Token is not valid</div>)}
	</Container>
}

export default withRouter(Verify)

const SpinnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;	
	height: 100%;
	align-items: center;
	img {
		width: 100;
	}
`
