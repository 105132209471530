import ActionTypes from './ActionTypes'

const initalState = {
	fangouts: [],
	shows: [],
	fangoutData: null,
	fangoutRequestData: null
}

export default function fangoutsReducer(state = initalState, action) {
	switch (action.type) {
		case ActionTypes.SET_FANGOUTS:
			return Object.assign({}, state, {fangouts: action.fangouts.content})
		case ActionTypes.SET_SHOWS:
			return Object.assign({}, state, {shows: action.shows.content})
		case ActionTypes.SET_FANGOUT_REQUEST_DATA:
			return Object.assign({}, state, {fangoutRequestData: action.data})
		case ActionTypes.SET_FANGOUT_DATA:
			return Object.assign({}, state, {fangoutData: action.data})
		default:
			return state
	}
}
