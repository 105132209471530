import config from "../../config";
import axios from "axios";
import {ActionTypes} from "../homeReducer";


class SubscriptionService {

	getSubscriptions() {
		const url = config.baseApi + '/v1/subscriptions'
		return axios.get(url)
	}

	// data = {subject: any}
	subscribeToUser(data) {
		const url = config.baseApi + '/v1/subscriptions'
		return axios.post(url, data)
	}

	/*
	data = {
		subscribedPointLat: number,
		subscribedPointLon: number,
		subscriptionRadius: number,
		subscriptionLocation: any
 	}
	*/
	subscribeToLocation(data) {
		const url = config.baseApi + '/v1/subscriptions'
		return axios.post(url, data)
	}

	/*
		subscriptionID: number
	 */
	unsubscribe(id) {
		const url = config.baseApi + '/v1/subscriptions/unsubscribe/' + id
		return axios.delete(url)
	}
}

export default new SubscriptionService();