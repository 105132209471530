import React from 'react';
import Heading from '../../components/heading/heading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import emptyAvatar from '../../assets/images/empty-avatar.jpg';
import Input from '../../components/input/input';
import {
  colourStyles,
  validateFullname,
  validateName,
  validateSocNetUrl,
  validateBio,
} from '../Login/utils';
import {
  getUserData,
  saveUserDetails,
  setPTR,
  setUserData,
  uploadImage,
} from '../profile/profileActions';
import Portal from '../../components/portal/portal';
import Popup from '../../components/popup/popup';
import facebook from '../../assets/images/fb_icon.png';
import instagram from '../../assets/images/instagram_logo.png';
import twitter from '../../assets/images/twitter_logo.png';
import onlyfans from '../../assets/images/onlyfans_logo.png';
import linkedin from '../../assets/images/linkedin_logo.png';
import tiktok from '../../assets/images/tiktok_logo.png';
import youtube from '../../assets/images/youtube_logo.png';
import GoBack from '../../components/goBack/goBack';
import {
  Button,
  Container,
  ImageEditIcon,
  Label,
} from '../../styling/globalStyling';
import ReactCrop from 'react-image-crop';
import { getSocNetworks } from '../../utils';
import countries from './countries';
import ReactGA from 'react-ga4';
import { TextArea } from '../../components/fangoutComponent/requestFangoutComponent';
import toast from 'react-hot-toast';

export const socNetMap = {
  fb: {
    name: 'Facebook',
    icon: facebook,
  },
  insta: {
    name: 'Instagram',
    icon: instagram,
  },
  tw: {
    name: 'Twitter',
    icon: twitter,
  },
  yt: {
    name: 'Youtube channel',
    icon: youtube,
  },
  tiktok: {
    name: 'TikTok',
    icon: tiktok,
  },
  onlyf: {
    name: 'OnlyFans',
    icon: onlyfans,
  },
  ln: {
    name: 'Linkedin',
    icon: linkedin,
  },
};

class PersonalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    this.professoinSelect = React.createRef();
    this.countrySelect = React.createRef();
    this.state = {
      userData: {
        name: '',
        fullName: '',
        email: '',
        bio: '',
        bankAccount: '',
        bankCountry: '',
        bankName: '',
      },
      selectedOption: null,
      selectedCountryOption: null,
      openSocNetDialog: false,
      socNetInputKey: null,
      socNetUrl: '',
      socialNetworks: socNetMap,
      src: null,
      crop: {
        unit: 'px',
        aspect: 1,
        width: 120,
        x: 30,
        y: 30,
      },
      croppedImageUrl: null,
      croppedImage: null,
      croppedImageB64: null,
      croppedImageApplied: false,
    };
  }

  componentDidMount() {
    ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });
    // Disable PTR
    this.props.setPTR(false);
    if (!this.props.userData.email) {
      this.props.getUserData().then((res) => {
        this.setState({
          userData: Object.assign({}, this.state.userData, res.data),
          selectedOption: {
            label: res.data.profession,
            value: res.data.profession,
          },
          selectedCountryOption: {
            label: res.data.bankCountry,
            value: res.data.bankCountry,
          },
          socialNetworks: getSocNetworks(res.data.socialNetworks),
        });
      });
    } else {
      this.setState({
        userData: this.props.userData,
        selectedOption: {
          label: this.props.userData.profession,
          value: this.props.userData.profession,
        },
        selectedCountryOption: {
          label: this.props.userData.bankCountry,
          value: this.props.userData.bankCountry,
        },
        socialNetworks: socNetMap,
      });
    }
  }

  componentWillUnmount() {
    this.props.setPTR(true);
  }

  handleUploadClick = () => {
    this.hiddenFileInput.current.click();
  };

  handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      selectedFile.convertToBase64().then((obj) => {
        const pureBase64 = obj.result.split(',')[1];
        uploadImage(pureBase64)
          .then((res) => {
            this.setState({
              userData: Object.assign({}, this.state.userData, {
                profilePhoto: obj.result,
              }),
            });
          })
          .catch((err) => {
            console.log('err', err);
          });
      });
    }
  };

  savePhoto = () => {
    const pureBase64 = this.state.croppedImageB64.split(',')[1];
    uploadImage(pureBase64)
      .then(() => {
        this.setState({ croppedImageApplied: true, src: null });
        this.props.getUserData();
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('Failed to save the photo.');
      });
  };

  handleChangeSelect = (selectedOption) => {
    selectedOption.label =
      selectedOption.label.charAt(0).toUpperCase() +
      selectedOption.label.slice(1);
    this.setState({
      selectedOption,
      userData: Object.assign({}, this.state.userData, {
        profession: selectedOption.label,
      }),
    });
  };

  handleChangeCountrySelect = (selectedCountryOption) => {
    this.setState({
      selectedCountryOption,
      userData: Object.assign({}, this.state.userData, {
        bankCountry: selectedCountryOption.label,
      }),
    });
  };

  addSocNet = (key) => {
    ReactGA.event({
      category: 'User',
      action: 'Click',
      label: 'Add soc network',
    });
    this.setState({ openSocNetDialog: true, socNetInputKey: key });
  };

  getSocNetInput = () => {
    return (
      <Input
        wrapperstyle={{ width: '100%' }}
        className={'borderPrimary'}
        type={'text'}
        name={'socNetUrl'}
        value={this.state.socNetUrl}
        onChange={(e) => this.setState({ socNetUrl: e.target.value })}
        isvalid={validateSocNetUrl.call(this)}
      />
    );
  };

  onSocNetPopupSubmit = () => {
    if (this.state.socNetInputKey !== null) {
      const newState = { ...this.state };
      newState.socialNetworks[this.state.socNetInputKey].url =
        this.state.socNetUrl;
      this.setState({
        ...newState,
        socNetUrl: '',
        socNetInputKey: null,
        openSocNetDialog: false,
      });
    }
  };

  save = () => {
    const userData = this.state.userData;
    userData.socialNetworks = Object.keys(this.state.socialNetworks).map(
      (key) => ({
        type: key,
        url: this.state.socialNetworks[key].url || '',
      })
    );
    delete userData.id;
    saveUserDetails(userData).then((res) => {
      // this.props.setUserData(res.data)
      this.props.getUserData();
      this.props.history.push('/profile');
    });
  };

  handleFile = (e) => {
    if (e.target.files.length) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => this.setState({ src: fileReader.result });
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    if (canvas.height > 0 && canvas.width > 0) {
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      const reader = new FileReader();
      canvas.toBlob((blob) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.setState({ croppedImageB64: reader.result });
          this.dataURLtoFile(reader.result, 'cropped.jpg');
        };
      });
    }
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({ croppedImage: croppedImage });
  }

  render() {
    const selectStyles = colourStyles(this.props.theme);
    const avatarSrc = this.state.userData.profilePhoto || emptyAvatar;
    const photoUrl = this.state.croppedImageApplied
      ? this.state.croppedImageB64
      : avatarSrc;
    const { src, crop, socialNetworks } = this.state;

    const lang = this.props.lang;

    const countriesOptions = JSON.parse(countries).map((c) => ({
      value: c.name,
      label: c.name,
    }));

    const saveDisabled =
      validateFullname.call(this) === 'error' ||
      validateName.call(this) === 'error' ||
      validateBio.call(this) === 'error';

    return (
      <Container>
        <Heading title={'Profile'} />
        <GoBack customAction={() => this.props.history.push('/profile')} />
        <ContainerExtended className={'p016'}>
          <div className={'headerContent'}>
            {src ? (
              <CropContainer>
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                  minHeight={80}
                  minWidth={80}
                  keepSelection={true}
                />
                <ImageEditIcon
                  className={'fas fa-save'}
                  onClick={this.savePhoto}
                />
                <ImageEditIcon
                  className={'fas fa-camera'}
                  onClick={this.handleUploadClick}
                />
                {/*<img onClick={this.handleUploadClick} className={'uploadImage'} src={add_image_icon} alt={'upload image'}/>*/}
              </CropContainer>
            ) : (
              <ImageContainer>
                <img className={'avatar'} src={photoUrl} alt={'avatar'} />
                {/*<img onClick={this.handleUploadClick} className={'uploadImage'} src={add_image_icon} alt={'upload image'}/>*/}
                {/*<ImageEditIcon className={'fas fa-camera'} onClick={this.handleUploadClick}/>*/}
              </ImageContainer>
            )}

            <div className={'userDetails'}>
              <div className={'name'}>{this.state.userData.name}</div>
              <div className={'username'}>@{this.state.userData.username}</div>
              <div className={'username'}>{this.state.userData.email}</div>
            </div>
          </div>
          <input
            type='file'
            style={{ display: 'none' }}
            ref={this.hiddenFileInput}
            // onChange={this.handleFileInputChange}
            onChange={this.handleFile}
            accept='image/*'
            // accept="image/x-png,image/gif,image/jpeg"
          />
          <div className={'controlsWrapper'}>
            <Input
              disabled={this.state.userData.verified === 'VERIFIED'}
              type={'text'}
              label={lang.label_full_name}
              name={'fullName'}
              value={this.state.userData.fullName}
              onChange={(e) =>
                this.setState({
                  userData: Object.assign({}, this.state.userData, {
                    fullName: e.target.value,
                  }),
                })
              }
              isvalid={validateFullname.call(this)} // function
              description={lang.label_not_visible_to_others}
              descriptionPositionUp
            />
            <Label>{lang.label_bio}</Label>
            <TextArea
              value={this.state.userData.bio || ''}
              className={validateBio.call(this)}
              onChange={(e) =>
                this.setState({
                  userData: Object.assign({}, this.state.userData, {
                    bio: e.target.value,
                  }),
                })
              }
            />

            <Button
              style={{ marginBottom: '32px' }}
              className='mt3 mb3'
              onClick={this.save}
              disabled={saveDisabled}
            >
              Save
            </Button>
          </div>
        </ContainerExtended>
        {this.state.openSocNetDialog && (
          <Portal>
            <Popup
              title={`${
                this.state.socialNetworks[this.state.socNetInputKey].name
              } url`}
              content={this.getSocNetInput()}
              cancelAction={() =>
                this.setState({
                  openSocNetDialog: false,
                  socNetInputKey: null,
                  socNetUrl: '',
                })
              }
              submitAction={this.onSocNetPopupSubmit}
              submitDisabled={!this.state.socNetUrl}
            />
          </Portal>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
    userData: state.profile.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserData: () => getUserData(dispatch),
    setUserData: (userData) => setUserData(dispatch, userData),
    setPTR: (state) => setPTR(dispatch, state),
  };
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalDetails))
);

const ContainerExtended = styled(Container)`
  align-items: center;
  .headerContent {
    display: flex;
    align-self: flex-start;
  }
  .userDetails {
    display: flex;
    flex-direction: column;
  }
  .avatar {
    width: 100%;
    border-radius: 10px;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
  }
  .username,
  .fullName {
    font-size: 12px;
    font-weight: 600;
  }
  .controlsWrapper {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 60px;
  height: 65px;
  display: block;
  border-radius: 10px;
  margin-right: 16px;
  .uploadImage {
    position: absolute;
    width: 32px;
    bottom: -5px;
    right: -10px;
    z-index: 999;
  }
`;
export const CropContainer = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  max-width: calc(100% - 32px);
  z-index: 1;
  .uploadImage {
    position: absolute;
    width: 32px;
    bottom: -5px;
    right: -10px;
    z-index: 999;
  }
`;
