import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { Container, TextContainer } from 'src/styling/globalStyling';

const DeliveryMethods = () => {
  return (
    <Container>
      <GoBack />
      <Heading title='Način dostave' />
      <TextContainer>
        <h3>Način dostave online kupljenih ulaznica</h3>
        <p>
          <b>PDF-TICKET</b>
          <br />
          <span>
            Vaša PDF ulaznica će Vam biti poslata na e-mail. Ulaznicu je
            poželjno odštampati na papiru A4 formata. Ulaznicu čuvajte.
            Odštampanu ulaznicu donesite na događaj.
          </span>
        </p>
        <p>
          <b>E-WALLET</b>
          <br />
          <span>
            Vaša ulaznica će Vam biti poslata u Vašem e-novčaniku na portalu
            https://ulaznice-nisville.shop
            <br />
            Ukoliko imate telefon pri sebi uvek možete pristupiti ulaznicama.
            <br />
            Ulaznice iz e-novčanika možete deliti (sherovati) klikom na gornje
            desno share dugme.
          </span>
        </p>
      </TextContainer>
    </Container>
  );
};

export default DeliveryMethods;
