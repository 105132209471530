import axios from 'axios'
import config from '../../config'

class fangoutsPublicService {
	getFangouts(fromFangoutId = -1) {
		const url = config.baseApi + '/v1/public/fangouts/all/' + fromFangoutId
		return axios.create().get(url)
	}

	getFangoutById(id) {
		const url = config.baseApi + '/v1/public/fangouts/' + id
		return axios.create().get(url)
	}

	findUsers(term) {
		const url = config.baseApi + '/v1/public/profile/find'
		return axios.create().post(url, {searchString: term})
	}

	findFangouts(data, operatorOR) {
		let url = config.baseApi + '/v1/public/filter'
		if (operatorOR) {
			url+= '/?operator=or'
		}
		return axios.create().post(url, data)
	}
}

export default new fangoutsPublicService()
