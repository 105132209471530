import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { Container } from '../../styling/globalStyling';
import Spinner from '../../components/Spinner/Spinner';
import config from '../../config';
import axios from 'axios';
import Ticket from '../../components/ticket/Ticket';
import StreamingTicket from '../../components/ticket/StreamingTicket';

const TicketPage = () => {
  const history = useHistory();
  const { id } = useParams<{ [key: string]: string }>();

  const getTicketData = (id: string) => {
    const url = config.baseApi + '/v1/public/tickets/findByInvalidationId';
    return axios.create().post(url, {
      ticketInvalidationId: id,
    });
  };

  const { data, isLoading, error } = useQuery(['ticket', id], ({ queryKey }) =>
    getTicketData(queryKey[1])
  );

  return (
    <TicketContainer>
      {(data as any) &&
        (data?.data?.ticketType.isStreaming ? (
          <StreamingTicket ticket={data?.data} />
        ) : (
          <Ticket
            expandable={false}
            defaultExpanded={true}
            ticket={data?.data}
          />
        ))}
      {isLoading && <Spinner fullscreen />}
      {error && (
        <>
          <div className={'text-center text-danger mt1'}>
            {(error as any)?.message}
          </div>
          <div
            className={'text-center mt1 fw500'}
            onClick={() => history.push('/')}
          >
            Home page
          </div>
        </>
      )}
    </TicketContainer>
  );
};

export default TicketPage;

const TicketContainer = styled(Container)`
  padding: 10px 6px;
  height: 100vh;
  background: ${({ theme }) => theme.secondBackground};

  .ticketComponent {
    position: unset;
  }

  .qrcode {
    margin-top: 20px;
  }
`;
