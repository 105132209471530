import styled from 'styled-components'
import logo from '../../assets/images/logo.svg'
import React from 'react'


const TitleWrapper = styled.div`
		display: flex;
		img {
			filter: ${({theme}) => theme.text === '#1e2e33' ? 'invert(100%)' : '' };
		}
	`
const LogoTitle = () => <TitleWrapper>
	<img src={logo} alt={'logo'}/>
</TitleWrapper>

export default LogoTitle
