import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';
import { getStore } from './store';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initGA } from './utils/analytics';

// Initialize analytics
initGA();

export const store = getStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // NOTE: disabled retry because of Axios Service conflict
      retry: false,
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
    },
  },
});

const RootApp = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
    </QueryClientProvider>
  </Provider>
);

ReactDOM.render(RootApp(), document.getElementById('root'));

const offlineAlert = (state) => {
  store.dispatch({
    type: 'SET_OFFLINE',
    payload: state,
  });
};

window.addEventListener('load', () => {
  function checkNetworkStatus(event) {
    if (!navigator.onLine) {
      offlineAlert(true);
    }
  }

  window.addEventListener('offline', checkNetworkStatus());
});

//The second addEventListener is for detecting the offline status while using the appliaction, the first one checks only for initial load
window.addEventListener('offline', () => {
  offlineAlert(true);
});

window.addEventListener('online', () => {
  offlineAlert(false);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    store.dispatch({
      type: 'UPDATE_AVAILABLE',
      payload: registration,
    });
  },
});

// if ('serviceWorker'  in navigator) {
// 	navigator.serviceWorker.register('./firebase-messaging-sw.js')
// 		.then(function(registration) {
// 			console.log('Registration was successful: ', registration)
// 		})
// 		.catch(function(e) {
// 			console.error('Registration has filed', e)
// 		})
// }

// Prototyping
File.prototype.convertToBase64 = function () {
  return new Promise(
    function (resolve, reject) {
      const reader = new FileReader();
      reader.onloadend = function (e) {
        resolve({
          fileName: this.name,
          result: e.target.result,
          error: e.target.error,
        });
      };
      reader.readAsDataURL(this);
    }.bind(this)
  );
};
