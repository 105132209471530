import React from 'react';
import { Container } from '../../styling/globalStyling';
import MainTabs from '../../components/tabs/tabs';
import Spinner from '../../components/Spinner/Spinner';
import { connect } from 'react-redux';
import { getRequests } from './requestsActions';
import { Tabs, Tab } from '../../components/sweapable-tabs';
import { withTheme } from 'styled-components';
import FangoutComponent from '../../components/fangoutComponent/fangoutComponent';
import RequestFangoutComponent from '../../components/fangoutComponent/requestFangoutComponent';
import Portal from '../../components/portal/portal';
import Popup from '../../components/popup/popup';
import createFangoutService from '../createFangout/createFangoutService';
import { sortByDate, sortByFangoutStatus, sortPendingFirst } from '../../utils';
import { setFangouts } from '../fangouts/fangoutsActions';
import { Events, scroller } from 'react-scroll';
import ReactGA from 'react-ga4';
import Heading from '../../components/heading/heading';
import { NothingHere } from '../myTickets/myTickets';

const fangoutStatusMap = ['ACCEPT', 'REJECT', 'COMPLETE'];

class Requests extends React.Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const selectedTab =
      match.params.selectedTab !== undefined ? match.params.selectedTab : 0;
    this.state = {
      spinner: false,
      selectedTab,
      expanded: {},
      openFangoutConfirmDialog: false,
      shouldBeApproved: false,
      fangoutToBeApproved: null,
      fangoutConfirmContent: null,
      submitAction: null,
      submitDisabled: false,
      popupError: '',
    };

    this.intervals = {};
  }

  componentWillMount() {
    ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });

    this.getRequests();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.selectedTab !== undefined) {
      this.setState({ selectedTab: nextProps.match.params.selectedTab });
    }
    if (nextProps.match.params.fangoutId) {
      const { fangoutId, selectedTab } = nextProps.match.params;
      this.setState({
        expanded: { ...this.state.expanded, [fangoutId]: true },
      });
      this.scrollToWithContainer(selectedTab, fangoutId);
    }
  }

  scrollToWithContainer(tab = 0, fId) {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });
      scroller.scrollTo(`scroll-container-${tab}`, {
        duration: 0,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    });
    goToContainer.then(() =>
      scroller.scrollTo(fId, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: `scroll-container-${tab}`,
      })
    );
  }

  getRequests() {
    this.setState({ spinner: true });
    this.props.getRequests().finally(() => {
      this.setState({ spinner: false });
    });
  }

  goToProfile = (e, celebrity) => {
    e.stopPropagation();
    this.props.history.push({
      pathname: '/public/' + celebrity.id,
      user: celebrity,
    });
  };

  // Approve or dismiss fangout request form other user
  confirmFangout = (fangout, approve) => {
    const fangoutConfirmContent =
      this.props.lang.requests_confirm_content(approve);
    this.setState({
      openFangoutConfirmDialog: true,
      fangoutToBeApproved: fangout,
      shouldBeApproved: approve,
      fangoutConfirmContent,
      submitAction: this.submitFangoutApproval,
    });
  };

  submitFangoutApproval = () => {
    this.setState({ popupError: '', submitDisabled: true });
    const statusString = fangoutStatusMap[this.state.shouldBeApproved];
    createFangoutService
      .approveFangout(this.state.fangoutToBeApproved.id, statusString)
      .then((res) => {
        this.getRequests();
        this.props.setFangouts();
        this.setState({
          openFangoutConfirmDialog: false,
          shouldBeApproved: false,
          fangoutToBeApproved: null,
          submitAction: null,
        });
      })
      .catch((e) => {
        this.setState({ popupError: e.message });
      })
      .finally(() => this.setState({ submitDisabled: false }));
  };

  // Used params for toBeApproved but used toBeCompleted
  completeFangout = (fangoutToBeApproved) => {
    this.setState({
      openFangoutConfirmDialog: true,
      fangoutToBeApproved,
      fangoutConfirmContent: this.props.lang.requests_confirm_complete_content,
      submitAction: this.submitCompleteFangout,
    });
  };

  submitCompleteFangout = () => {
    this.setState({ popupError: '', submitDisabled: true });
    createFangoutService
      .completeFangout(this.state.fangoutToBeApproved.id)
      .then((res) => {
        this.getRequests();
        this.props.setFangouts();
        this.setState({
          openFangoutConfirmDialog: false,
          shouldBeApproved: false,
          fangoutToBeApproved: null,
          submitAction: null,
          selectedTab: 1, // request_history
        });
      })
      .catch((e) => {
        this.setState({ popupError: e.message });
      })
      .finally(() => this.setState({ submitDisabled: false }));
  };

  expandFangout = (e, id) => {
    e.stopPropagation();
    this.setState({
      expanded: Object.assign({}, this.state.expanded, {
        [id]: !this.state.expanded[id],
      }),
    });
  };

  render() {
    const lang = this.props.lang;
    return (
      <Container>
        <Heading title={lang.tabs_fangouts} />
        <Tabs
          value={this.state.selectedTab}
          onChange={(t) => this.setState({ selectedTab: t.key })}
          tabBarCSS={`font-size: 16px;
        font-weight: 500;
        height: 56px;
        margin-bottom: 32px;
        margin-top: -16px;
        `}
          tabItemCSS={`line-height: 56px;`}
          styleProps={{
            barColor: 'transparent',
            selectedHeaderTextColor: this.props.theme.primary,
            headerTextColor: '#fff',
            activeInkBarColor: this.props.theme.primary,
            inkBarColor: 'transparent',
            inkBarSize: '1px',
            size: 'medium',
            lineHeigth: '56px',
          }}
        >
          <Tab label={lang.requests_received} key={0}>
            <div className={'p016 pb200 noscroll'} id={'scroll-container-0'}>
              {this.state.spinner && <Spinner />}
              {this.props.requests.toMe
                .filter(filterActiveRequests)
                .sort((a, b) => sortByDate(a.date, b.date))
                .sort(sortPendingFirst)
                .map((f) => {
                  return (
                    <RequestFangoutComponent
                      toMe={true}
                      f={f}
                      id={f.id}
                      key={f.id}
                      goToProfile={this.goToProfile}
                      confirmFangout={this.confirmFangout}
                      completeFangout={this.completeFangout}
                      expanded={this.state.expanded}
                      expandFangout={this.expandFangout}
                    />
                  );
                })}
              {!this.state.spinner &&
                !this.props.requests.toMe.filter(filterActiveRequests)
                  ?.length && <NothingHere>{lang.no_requests}</NothingHere>}
            </div>
          </Tab>
          <Tab label={lang.requests_sent} key={1}>
            <div className={'p016 pb200 noscroll'} id={'scroll-container-1'}>
              {this.props.requests.fromMe
                .sort((a, b) => sortByDate(a.date, b.date))
                .sort(sortByFangoutStatus)
                .map((f) => {
                  return (
                    <RequestFangoutComponent
                      key={f.id}
                      id={f.id}
                      fromMe={true}
                      f={f}
                      goToProfile={this.goToProfile}
                      confirmFangout={this.confirmFangout}
                      completeFangout={this.completeFangout}
                      expanded={this.state.expanded}
                      expandFangout={this.expandFangout}
                    />
                  );
                })}
              {!this.state.spinner && !this.props.requests.fromMe?.length && (
                <NothingHere>{lang.no_requests_sent}</NothingHere>
              )}
            </div>
          </Tab>
          <Tab label={lang.requests_history} key={2} id={'scroll-container-2'}>
            <div className={'p016 pb200 noscroll'}>
              {this.props.requests.completed
                .sort((a, b) => sortByDate(a.date, b.date, true))
                .map((f) => {
                  return (
                    <FangoutComponent
                      f={f}
                      key={f.id}
                      goToProfile={this.goToProfile}
                      isHistory
                      expanded={this.state.expanded}
                      expandFangout={this.expandFangout}
                    />
                  );
                })}
              {!this.state.spinner &&
                !this.props.requests.completed?.length && (
                  <NothingHere>{lang.no_requests_history}</NothingHere>
                )}
            </div>
          </Tab>
        </Tabs>
        <MainTabs active={2} />
        {this.state.openFangoutConfirmDialog && (
          <Portal>
            <Popup
              title={lang.requests_confirm_title}
              content={this.state.fangoutConfirmContent}
              cancelAction={() =>
                this.setState({
                  openFangoutConfirmDialog: false,
                  shouldBeApproved: false,
                  fangoutToBeApproved: null,
                  fangoutConfirmContent: null,
                  submitAction: null,
                })
              }
              submitAction={this.state.submitAction}
              submitDisabled={this.state.submitDisabled}
              submitText={lang.yes}
              cancelText={lang.cancel}
              error={this.state.popupError}
            />
          </Portal>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
    requests: state.requests.requests,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRequests: () => getRequests(dispatch),
    setFangouts: () => setFangouts(dispatch),
  };
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(Requests)
);

function filterActiveRequests(r) {
  return r.status !== 'AVAILABLE';
}
