import english from './langFiles/english'
import srpski from './langFiles/srpski'
import makedonski from './langFiles/makedonski'

const languages = {
	'EN': english,
	'SR': srpski,
	'MK': makedonski
}

export default languages
