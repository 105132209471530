// Create function that will receive a html selector as an input and output the image of that html element
import * as htmlToImage from 'html-to-image';

export function getImageOfHtml(selector) {
	const node = document.querySelector(selector);
	return htmlToImage.toPng(node)
		.then(function (dataUrl) {
			return dataUrl
			// const img = new Image();
			// img.src = dataUrl;
			// document.body.appendChild(img);
		})
		.catch(function (error) {
			console.error('oops, something went wrong!', error);
			return error
		});
}