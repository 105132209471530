import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import emptyAvatar from '../../assets/images/empty-avatar.jpg';
import adminService from './adminService';
import styled, { useTheme } from 'styled-components';
import Input from '../../components/input/input';
import Select from 'react-select';
import moment from 'moment';
import { dateFormat } from '../../constants';
import Spinner from '../../components/Spinner/Spinner';
import { Tab } from './utils';
import Giveaway from './components/Giveaway';

interface ISelectedUser {
  id: string;
  idType: string;
  name?: string;
  fullName?: string;
  email?: string;
  profession?: string;
  verified?: boolean;
  idNumber?: string;
  verificationPhotoUrl?: string;
  activityCounter: number;
  popularityCounter: number;
  isBusiness: boolean;
  isPremium?: string;
}

const now = moment().add(15, 'd');

const AdminHook = (props) => {
  const theme = useTheme();
  const [usersData, setUsersData] = useState({ content: [] });
  const [selectedUser, setSelectedUser] = useState<ISelectedUser>({
    id: '',
    idType: 'ID',
    idNumber: '',
    activityCounter: 0,
    popularityCounter: 0,
    isBusiness: false,
  });
  const [error, setError] = useState('');
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [refreshTable, setRefreshTable] = React.useState(0); // change to trigger table refresh

  const [tab, setTab] = useState(0);
  const [premiumValidTo, setPremiumValidTo] = useState(now.format(dateFormat));
  const [premiumDuration, setPremiumDuration] = useState(12);
  const [premiumKey, setPremiumKey] = useState('');
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setData(usersData.content);
  }, [usersData]);

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    setError('');
    adminService
      .getUsers(-1, pageSize, pageIndex)
      .then((res) => {
        setUsersData(res.data);
        setPageCount(res.data.totalPages);
        if (Object.keys(selectedUser).length) {
          const user = res.data.content.find((u) => u.id === selectedUser.id);
          if (user) {
            setSelectedUser(user);
          }
        }
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, []);

  const setIdType = (type) => {
    setSelectedUser({ ...selectedUser, idType: type.value });
  };

  const handleIdNumChange = (e) => {
    setSelectedUser({ ...selectedUser, idNumber: e.target.value });
  };

  const verifyUser = (status) => {
    setError('');
    const fetchId = ++fetchIdRef.current;
    adminService
      .updateUser(selectedUser.id, status, {
        idType: selectedUser.idType,
        idNumber: selectedUser.idNumber,
      })
      .then(() => {
        setRefreshTable(fetchId);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const setActivity = (val) => {
    setSelectedUser({ ...selectedUser, activityCounter: val });
  };

  const setPopularity = (val) => {
    setSelectedUser({ ...selectedUser, popularityCounter: val });
  };

  const saveActivity = () => {
    setError('');
    const fetchId = ++fetchIdRef.current;
    adminService
      .setUserActivity(selectedUser.id, selectedUser.activityCounter)
      .then(() => {
        setRefreshTable(fetchId);
      })
      .catch((e) => {
        setError(e.message);
      });
  };
  const savePopularity = () => {
    setError('');
    const fetchId = ++fetchIdRef.current;
    adminService
      .setUserPopularity(selectedUser.id, selectedUser.popularityCounter)
      .then(() => {
        setRefreshTable(fetchId);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  const generatePremiumKey = () => {
    setSpinner(true);
    setPremiumKey('');
    setError('');
    const data = {
      activationCodeValidUntil: moment(premiumValidTo).utc().format(),
      validMonths: premiumDuration,
    };
    adminService
      .generatePremiumKey(data)
      .then((res: any) => {
        setPremiumKey(res.data?.premiumCode);
      })
      .catch((e) => setError(e.message))
      .finally(() => setSpinner(false));
  };

  const copyKey = () => {
    navigator.clipboard.writeText(premiumKey || 'No key');
  };

  return (
    <Styles>
      <Header className={'adminHeader'}>
        <Tab index={0} selectedTab={tab} setIndex={setTab}>
          Users
        </Tab>
        <Tab index={1} selectedTab={tab} setIndex={setTab}>
          Other
        </Tab>
        <Tab index={2} selectedTab={tab} setIndex={setTab}>
          Giveaway
        </Tab>
      </Header>
      <TabContent isActive={tab === 0}>
        <TableWrapper>
          {error && <div className={'text-danger'}>{error}</div>}
          <Table
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            setSelectedUser={setSelectedUser}
            refresh={refreshTable}
          />
        </TableWrapper>
        <UserDetails>
          {Object.keys(selectedUser).length > 0 && (
            <div className={'userDetails'}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '30px' }}>
                  <div className={'info'}>ID</div>
                  <label>
                    {selectedUser.id}{' '}
                    {selectedUser.isBusiness ? 'Business' : 'Regular'}
                  </label>
                  <div className={'info'}>
                    Premium:
                    {selectedUser.isPremium
                      ? moment(selectedUser.isPremium).format('DD/MM/yyyy')
                      : ''}
                  </div>
                  <br />
                  <div className={'info'}>Full Name</div>
                  <label>{selectedUser.fullName}</label>
                  <br />
                  <div className={'info'}>Stage Name</div>
                  <label>{selectedUser.name}</label>
                  <br />
                  <div className={'info'}>Email</div>
                  <label>{selectedUser.email}</label>
                  <br />
                  <div className={'info'}>Profession</div>
                  <label>{selectedUser.profession}</label>
                  <br />
                  <div className={'info'}>Verified</div>
                  <label>{selectedUser.verified}</label>
                  <br />
                </div>
                <div style={{ width: '300px' }}>
                  <label>ID Type</label>
                  <Select
                    value={idTypesMAP[selectedUser.idType]}
                    className={'select'}
                    // styles={selectStyles}
                    options={idTypes}
                    onChange={setIdType}
                  />
                  <Input
                    type={'text'}
                    label={'ID number'}
                    name={'idNumber'}
                    value={selectedUser.idNumber}
                    onChange={handleIdNumChange}
                    description={'(PUBLIC)'}
                    descriptionPositionUp
                  />
                </div>
              </div>
              <div className={'mt1'}>
                <InputGroup>
                  <Input
                    type={'number'}
                    label={'Activity'}
                    name={'activity'}
                    value={selectedUser.activityCounter}
                    onChange={(e) => setActivity(e.target.value)}
                  />
                  <button disabled={!selectedUser.id} onClick={saveActivity}>
                    SET
                  </button>
                </InputGroup>
                <InputGroup>
                  <Input
                    type={'number'}
                    label={'Popularity'}
                    name={'popularity'}
                    value={selectedUser.popularityCounter}
                    onChange={(e) => setPopularity(e.target.value)}
                  />
                  <button disabled={!selectedUser.id} onClick={savePopularity}>
                    SET
                  </button>
                </InputGroup>
              </div>
              <label className={'mt2 mb1'}>Set status</label>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button
                  disabled={!selectedUser.id}
                  className={'verification'}
                  onClick={() => verifyUser('VERIFIED')}
                >
                  VERIFIED
                </button>
                <button
                  disabled={!selectedUser.id}
                  className={'verification'}
                  onClick={() => verifyUser('PENDING')}
                  style={{ background: theme.yellow }}
                >
                  PENDING
                </button>
                <button
                  disabled={!selectedUser.id}
                  className={'verification'}
                  onClick={() => verifyUser('UNVERIFIED')}
                  style={{ background: theme.red }}
                >
                  UNVERIFIED
                </button>
              </div>
              {selectedUser.verificationPhotoUrl && (
                <div className={'idphoto'}>
                  <img
                    width={'100%'}
                    src={selectedUser.verificationPhotoUrl}
                    alt={'verification'}
                  />
                </div>
              )}
            </div>
          )}
        </UserDetails>
      </TabContent>
      <TabContent isActive={tab === 1}>
        <div>
          <Input
            wrapperstyle={{ marginTop: '10px' }}
            label={'Date up to when key is valid'}
            type={'date'}
            value={premiumValidTo}
            inputstyle={{
              minWidth: '-webkit-fill-available',
              paddingRight: '5px',
              paddingLeft: '8px',
            }}
            onChange={(e) => setPremiumValidTo(e.target.value)}
            min={moment().format(dateFormat)}
            isvalid={moment().isAfter(premiumValidTo) ? 'error' : 'success'}
          />
          <div className={'flex align-end'}>
            <Input
              wrapperstyle={{ marginTop: '10px' }}
              value={premiumDuration}
              label={'Duration'}
              type={'number'}
              min={1}
              descriptionPositionUp
              description={`(months)`}
              onChange={(e) => setPremiumDuration(e.target.value)}
            />
            <button onClick={generatePremiumKey} className={'generateKey'}>
              <i className={'icon-logo_glass'} />
            </button>
          </div>
          {premiumKey && (
            <div className={'flex align-center space-between mt1 mb2'}>
              <PremiumKey>{premiumKey}</PremiumKey>
              <button className={'generateKey'} onClick={copyKey}>
                <i className={'fas fa-copy'} />
              </button>
            </div>
          )}
          {error && <div className={'text-danger'}>{error}</div>}
        </div>
        {spinner && <Spinner fullscreen />}
      </TabContent>
      <TabContent isActive={tab === 2}>
        <Giveaway />
      </TabContent>
    </Styles>
  );
};

export default AdminHook;

const columns = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    width: 40,
  },
  {
    id: 'profilePhoto',
    Header: '#',
    accessor: (d) => (
      <div>
        <img
          className={'profilePhoto'}
          src={d.profilePhoto || emptyAvatar}
          alt={'profile'}
          style={{ border: d.isBusiness ? '3px solid #00fff0' : '' }}
        />
        {d.isPremium && (
          <div style={{ fontSize: '10px' }}>
            {moment(d.isPremium).format('DD/MM/yy')}
          </div>
        )}
      </div>
    ), // String-based value accessors!
  },
  {
    id: 'verified',
    Header: 'Verified',
    accessor: (d) =>
      d.verified === 'VERIFIED' ? (
        <span className={'text-primary'}>Verified</span>
      ) : d.verified === 'UNVERIFIED' ? (
        <span className={'text-danger'}>Unverified</span>
      ) : (
        <span className={'text-warning'}>Pending</span>
      ),
    // accessor: 'verified',
    // filterable: true
  },
  {
    Header: 'Username/FullName',
    // accessor: 'username' // String-based value accessors!
    Cell: ({ row }) => {
      const { original } = row;
      return (
        <span className='number'>
          <div>{original.username}</div>
          <div>{original.fullName}</div>
        </span>
      );
    }, // Custom cell components!
  },
  {
    Header: 'Stage Name',
    accessor: 'name',
    width: 200,
  },
  {
    id: 'profession', // Required because our accessor is not a string
    Header: 'Profession',
    accessor: (d) => d.profession, // Custom value accessors!
  },
  {
    Header: (props) => <span>Email</span>, // Custom header components!
    accessor: 'email',
  },
];

const idTypes = [
  { value: 'ID', label: 'Id' },
  { value: 'PASSPORT', label: 'Passport' },
];
const idTypesMAP = {
  ID: { value: 'ID', label: 'Id' },
  PASSPORT: { value: 'PASSPORT', label: 'Passport' },
};

const PremiumKey = styled.div`
  font-size: 18px;
  font-weight: bold;
  user-select: all;
  line-height: 44px;
  margin-right: 20px;
`;
const Header = styled.div`
  height: 30px;
  display: flex;
`;

const TabContent = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  overflow: scroll;
  input {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 16px;
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      outline: unset;
      margin-left: 2px;
      &:active,
      &:focus {
        opacity: 0.7;
      }
    }
  }
  .generateKey {
    margin-left: 20px;
    padding: 14px;
    min-height: 44px;
    min-width: 44px;
    border-radius: 4px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 12px;

  > div {
    flex-grow: 1;
    margin-top: 0;
  }
  button {
    min-width: 60px;
    min-height: 44px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px !important;
  }
`;

const Styles = styled.div`
  // display: flex;
  background: #fff;
  color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
  padding: 0.5rem;
  table {
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
      img {
        width: 60px;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
  button {
    max-width: 50px;
    max-height: 20px;
    padding-top: unset;
    padding-bottom: unset;
    border-radius: unset;
  }
`;

const TableWrapper = styled.div`
  width: calc(100% - 600px);
  min-width: 280px;
  overflow: scroll;
  table th {
    margin: 0;
    padding: 0 0.5rem;
    font-size: 14px;
    white-space: nowrap;
  }
`;

const UserDetails = styled.div`
  width: 600px;
  top: 0;
  right: 0;
  padding-top: 20px;
  padding-left: 10px;
  button.verification {
    max-width: 200px;
    max-height: 40px;
    padding: 10px;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    width: 600px;
  }
  td span.name {
    min-width: 200px;
  }
  .userDetails {
    .info {
      font-size: 12px;
      font-weight: 500;
      display: inline-block;
      width: 100px;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      min-width: 160px;
    }
    button.verification {
      max-width: 150px;
      margin-right: 15px;
    }
    .idphoto img {
      max-width: 100%;
    }
  }
`;

function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  setSelectedUser,
  refresh,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 40 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Listen for changes in parent component
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [refresh]);

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => setSelectedUser(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan={10000}>Loading...</td>
            ) : (
              <td colSpan={10000}>
                Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
                results
              </td>
            )}
          </tr>
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className='pagination'>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
