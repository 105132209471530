import React, {useState} from 'react'
import Heading from "../../components/heading/heading";
import GoBack from "../../components/goBack/goBack";
import {Container} from "../../styling/globalStyling";
import {useHistory} from "react-router-dom";
import styled from "@emotion/styled";
import {useSelector} from "react-redux";
import Input from "../../components/input/input";

const Premium = (props: any) => {
	const history = useHistory()
	const lang = useSelector((state: any) => state.home.language)
	const [months, setMonths] = useState(1)

	const buy = () => {
		const premium = {
			months,
			currency: 'EUR'
		}

		history.push({pathname: '/premiumpay', state: {premium}})
	}

	return <Container>
		<Heading title={'Premium'}/>
		<GoBack customAction={() => history.push('/profile')}/>
		<Container className={'p016'}>
			<Desc>{lang.premium_buy_description}</Desc>
			<Feature className="desc">{lang.premium_desc_availability}</Feature>
			<Feature className="desc">{lang.premium_desc_myagent}</Feature>
			<Price>
				<div className={'big'}>{lang.premium_price_1}{lang.EUR}{lang.premium_price_2}</div>
				<div className={'text-danger'}>{lang.premium_price_discount_1}{lang.EUR} {lang.premium_price_discount_2}</div>
			</Price>
			<BuyPremium>
				<Input
					wrapperstyle={{marginTop: 0}}
					min={1}
					value={months}
					label={lang.premium_label_months}
					type={'number'}
					descriptionPositionUp
					description={lang.premium_label_months_desc}
					onClick={e => {
						e.stopPropagation();
						e.preventDefault()
					}}
					onChange={e => setMonths( e.target.value)}
					isvalid={months < 1 ? 'error' : 'success'}
				/>
				<Button disabled={months < 1} onClick={buy}>{lang.buy}</Button>
			</BuyPremium>
			<PriceCalculated>{lang.premium_to_pay} {months < 12 ? months * 50 : months * 40} {lang.EUR}</PriceCalculated>
		</Container>
	</Container>
}

export default Premium


const Desc = styled.div`
text-align: justify;
margin-bottom: 40px;
`

const Feature = styled.div`
margin-bottom: 40px;
text-align: justify;
`

const Button = styled.button`
	font-size: 16px;
	padding: 5px;
	height: 44px;
	width: 120px;
`

const BuyPremium = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 20px;
`

const Price = styled.div`
	margin-bottom: 30px;
	.big {
		font-size: 32px;
	}
	.text-danger {
		
	}
`

const PriceCalculated = styled.div`
	margin-bottom: 400px;
`