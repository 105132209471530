import React from 'react';
import Heading from '../../components/heading/heading';
import Input from '../../components/input/input';
import styled from 'styled-components';
import GoBack from '../../components/goBack/goBack';
import Portal from '../../components/portal/portal';
import Popup from '../../components/popup/popup';
import { Container } from '../../styling/globalStyling';
import ReactGA from 'react-ga4';
import authService from './authService';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const Summary = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin-top: 32px;
`;

const SubmitButton = styled.button`
  margin-top: 24px;
  margin-bottom: 32px;
`;

class ForgotPassword extends React.Component {
  state = {
    showPopup: false,
    emailOrUsername: '',
    error: '',
  };

  send = () => {
    this.setState({ error: '' });
    authService
      .requestResetPassword(this.state.emailOrUsername)
      .then(() => {
        this.setState({ showPopup: true });
      })
      .catch((e) => {
        this.setState({ error: e.message });
      });
  };

  onPopupSubmit = () => {
    ReactGA.event({
      action: 'Click',
      category: 'Modal',
      label: 'Forgot password',
    });
    this.setState({ showPopup: false });
    this.props.history.push('/login');
  };

  render() {
    const lang = this.props.lang;
    return (
      <Container>
        <GoBack />
        <Heading title={lang.forget_pass_heading} />
        <Container className={'p016'}>
          <Summary>{lang.forget_pass_summary}</Summary>
          <Input
            type={'email'}
            label={'Email'}
            value={this.state.emailOrUsername}
            onChange={(e) => this.setState({ emailOrUsername: e.target.value })}
          />
          {this.state.error && (
            <div className={'text-danger text-center'}>{this.state.error}</div>
          )}
          <SubmitButton onClick={this.send}>{lang.send}</SubmitButton>
          {this.state.showPopup && (
            <Portal>
              <Popup
                title={lang.forget_pass_title}
                content={lang.forget_pass_message}
                submitAction={this.onPopupSubmit}
              />
            </Portal>
          )}
        </Container>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
  };
}
export default withRouter(connect(mapStateToProps)(ForgotPassword));
