import React from 'react';
import Heading from '../../components/heading/heading';
import Input from '../../components/input/input';
import { withRouter } from 'react-router-dom';
import authService from './authService';
import * as constants from '../../constants';
import { Container } from '../../styling/globalStyling';
import { store } from '../../index';
import { setUserData } from '../profile/profileActions';
import { redirectIfLoggedIn, subscribeToFirebase } from '../../utils';
import { connect } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import {
  RegisterWrapper,
  LoginButton,
  RegisterButton,
  ForgotPassword,
  LoginButtonWrapper,
} from './styledComponetns';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_G_CLIENT_ID;

class Login extends React.Component {
  state = {
    eyeIcon: false,
    email: '',
    password: '',
    error: '',
  };

  componentWillMount() {
    redirectIfLoggedIn(this.props.history);
  }

  handleSuccessLogin = (res) => {
    localStorage.setItem(constants.SESSION_KEY, JSON.stringify(res.data));
    setUserData(store.dispatch, res.data.userProfile);
    subscribeToFirebase();
    if (this.props.location.state && this.props.location.state.from) {
      this.props.history.push(this.props.location.state.from.pathname);
    } else {
      this.props.history.push('/home');
    }
  };

  login = () => {
    this.setState({ error: '' });
    const data = {
      emailOrUsername: this.state.email,
      password: this.state.password,
    };

    authService
      .login(data)
      .then(this.handleSuccessLogin)
      .catch((e) => {
        this.setState({ error: e.message });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onGoogleLoginSuccess = (res) => {
    this.setState({ error: '' });
    const data = {
      contactInformationType: 'GOOGLE',
      value: res.tokenId,
    };
    console.log('success', res);
    authService
      .socialLogin(data)
      .then(this.handleSuccessLogin)
      .catch((e) => {
        this.setState({ error: e.message });
      });
  };

  onGoogleLoginFaluire = (res) => {
    console.log('fail', res);
  };

  render() {
    const lang = this.props.lang;
    return (
      <Container>
        <Heading title={'Login'} />
        <Container className={'p016'}>
          <LoginButtonWrapper className="mt2">
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              buttonText='Continue with Google'
              onSuccess={this.onGoogleLoginSuccess}
              onFailure={this.onGoogleLoginFaluire}
              cookiePolicy='single_host_origin'
              isSignedIn={true}
            />
          </LoginButtonWrapper>
          <div className='text-size-xl fw500 text-center mt1'>
            {lang.or}
          </div>
          <Input
            type={'text'}
            label={'email'}
            name={'email'}
            value={this.state.email}
            onChange={this.handleChange}
          />
          <Input
            type={this.state.eyeIcon ? 'text' : 'password'}
            label={'password'}
            name={'password'}
            icon={
              this.state.eyeIcon
                ? 'icon-password_visible'
                : 'icon-password_hidden'
            }
            iconClickHandler={() =>
              this.setState({ eyeIcon: !this.state.eyeIcon })
            }
            value={this.state.password}
            onChange={this.handleChange}
          />
          <ForgotPassword
            onClick={() => this.props.history.push('/forgotPassword')}
          >
            {lang.forget_pass_heading}?
          </ForgotPassword>
          <LoginButton type='button' onClick={this.login}>
            Login
          </LoginButton>
          {this.state.error && (
            <div className={'text-danger text-center'}>{this.state.error}</div>
          )}
          <RegisterWrapper>
            <span className={'text-medium'}>{lang.login_dont_have_account}</span>{' '}
            <RegisterButton
              onClick={() => this.props.history.push('register')}
            >
              {lang.register}
            </RegisterButton>
          </RegisterWrapper>
        </Container>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
  };
}
export default withRouter(connect(mapStateToProps)(Login));
