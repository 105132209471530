import styled, { createGlobalStyle } from 'styled-components';
import InputMask from 'react-input-mask';

import AvenirNextLight from '../assets/fonts/AvenirNext-UltraLight-11.ttf';
import AvenirNext from '../assets/fonts/AvenirNext-Regular-08.ttf';
import AvenirNextBold from '../assets/fonts/AvenirNext-Bold-01.ttf';
import AvenirNextHeavy from '../assets/fonts/AvenirNext-Heavy-09.ttf';
import AvenirNextMedium from '../assets/fonts/AvenirNext-Medium-06.ttf';
import { NumericFormat } from 'react-number-format';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'AvenirNext';
    src: local('AvenirNext'),
    url(${AvenirNextLight}) format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: local('AvenirNext'),
    url(${AvenirNext}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: local('AvenirNext'),
    url(${AvenirNextMedium}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: local('AvenirNext'),
    url(${AvenirNextBold}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: local('AvenirNext'),
    url(${AvenirNextHeavy}) format('truetype');
    font-weight: 700;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  *:focus {
    outline: unset;
  }

  html, body {
    height: 100%;
    overflow: hidden;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  body {
    letter-spacing: 0.5px;
    align-items: center;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: AvenirNext;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.25s linear;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  #root {
    align-items: stretch;
    border: 0 solid #000;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
    overflow-y: hidden;

    -webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  .App {
    height: 100%;
    display: flex;
    max-width: 600px;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    color: ${({ theme }) => theme.text};
      // background-color: ${({ theme }) => theme.secondBackground};
    background-color: ${({ theme }) => theme.background};
    -webkit-tap-highlight-color: transparent;
    overflow-y: hidden;

    -webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }


  .pageContainer {
    height: 100%;
  }


  .hiddenScroll {
    -webkit-tap-highlight-color: transparent;
    overflow-y: hidden;

    -webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    -webkit-overflow-scrolling: touch !important;
    -webkit-overflow-scrolling: touch;
  }

  .noscroll {
    height: 100%;
    position: relative;
    overflow-y: scroll;

    -webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }

    -webkit-overflow-scrolling: touch !important;
  }

  button {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.buttonText};
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 15px;
    border-radius: 30px;
    border: none;
    width: 100%;
    font-family: AvenirNext, Arial;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      opacity: 90%;
    }

    &:active {
      opacity: 70%;
    }

    &.danger {
      background: ${({ theme }) => theme.dangerBackground};
      color: ${({ theme }) => theme.primary};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }

    &.slim {
      padding-top: 4px;
      padding-bottom: 2px;
    }

    &.small {
      max-width: 150px;
    }

    -webkit-tap-highlight-color: transparent;
  }

  .flex {
    display: flex;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .space-between {
    justify-content: space-between;
  }
  
  .space-around {
    justify-content: space-around;
  }

  .align-center {
    align-items: center;
  }

  .align-end {
    align-items: flex-end;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-end {
    justify-content: end;
  }

  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;

    &.column {
      flex-direction: column;
    }
  }
  
  .fit-content {
    width: fit-content;
  }

  .p016 {
    padding: 0 16px;
  }

  .full-width {
    width: 100%;
  }

  .text-center {
    text-align: center;
  }

  .text-justify {
    text-align: justify;
  }

  .text-small {
    font-weight: 300;
  }

  .text-normal {
    font-weight: 400;
  }

  .text-medium {
    font-weight: 500;
  }

  .text-bold {
    font-weight: 600;
  }

  .text-heavy {
    font-weight: 700;
  }

  .text-size-xs {
    font-size: 10px;
  }

  .text-size-s {
    font-size: 12px;
  }

  .text-size-m {
    font-size: 14px;
  }

  .text-size-l {
    font-size: 16px;
  }

  .text-size-xl {
    font-size: 20px;
  }

  .text-size-xxl {
    font-size: 24px;
  }

  .text-primary {
    color: ${({ theme }) => theme.primary}
  }

  .text-warning {
    color: ${({ theme }) => theme.yellow}
  }


  .text-danger {
    color: ${({ theme }) => theme.red}
  }


  .m0 {
    margin: 0 !important;
  }

  .mt0 {
    margin-top: 0;
  }

  .mt1 {
    margin-top: 1rem !important;
  }

  .mt2 {
    margin-top: 2rem !important;
  }

  .mt3 {
    margin-top: 3rem !important;
  }

  .mt5 {
    margin-top: 5px;
  }

  .mr1 {
    margin-right: 1rem !important;
  }

  .mr2 {
    margin-right: 2rem !important;
  }

  .mr3 {
    margin-right: 3rem !important;
  }

  .mr5 {
    margin-right: 5px !important;
  }

  .mr10 {
    margin-right: 10px !important;
  }

  .mb1 {
    margin-bottom: 1rem;
  }

  .mb2 {
    margin-bottom: 2rem;
  }

  .mb3 {
    margin-bottom: 3rem;
  }

  .mb5 {
    margin-bottom: 5px;
  }

  .fw300 {
    font-weight: 300;
  }

  .fw400 {
    font-weight: 400;
  }

  .fw500 {
    font-weight: 500;
  }

  .fw600 {
    font-weight: 600;
  }

  .fw700 {
    font-weight: 700;
  }

  .p0 {
    padding: 0 !important;
  }

  .pb50 {
    padding-bottom: 50px;
  }
  .pb60 {
    padding-bottom: 60px;
  }

  .pb130 {
    padding-bottom: 130px;
  }

  .pb200 {
    padding-bottom: 200px;
  }

  .pb300 {
    padding-bottom: 300px;
  }

  input.borderPrimary {
    border: 1px solid ${({ theme }) => theme.primary};
  }

  .hidden {
    display: none;
  }

  .relative {
    position: relative;
  }

  i.verification {
    position: absolute;
    right: -5px;
    top: -3px;
  }

  .terms {
    color: ${({ theme }) => theme.primary};
  }

  .link, .clickable {
    cursor: pointer;

    &:hover {
      opacity: 90%;
    }

    &:active {
      opacity: 70%;
    }
  }

  .fs12 {
    font-size: 12px;
  }

  .fs16 {
    font-size: 16px !important;
  }

  .no-border {
    border: none !important;
  }


  #portal {
    z-index: 1000;
  }

  .disabler {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    opacity: 0.4;
    * {
      cursor: not-allowed;
    }
  }

  .fullWidth {
    width: 100%;
  }
`;

export const Button = styled.button`
  margin-top: 20px;
  margin-bottom: 12px;
  position: relative;

  &.attention {
    background: ${({ theme }) => theme.red};
  }

  .attention {
    position: absolute;
    bottom: 1px;
    width: calc(100% - 12px);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.red};
  }

  &.small {
    width: auto;
    margin: 0;
    padding: 5px 16px;
  }

  &.second {
    background: transparent;
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  overflow-y: scroll;

  -webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  -webkit-overflow-scrolling: touch !important;
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-top: 32px;
  &.noMarginTop {
    margin-top: 0;
  }
`;

export const InputField = styled(InputMask)`
  position: relative;
  height: 44px;
  background: ${({ theme }) => theme.secondBackground};
  color: ${({ theme }) => theme.text};
  font-weight: 500;
  font-size: ${(props) =>
    props.type === 'date' || props.type === 'time' ? '12px' : '16px'};
  padding-left: 16px;
  border: none;
  border-radius: 4px;
  padding-right: 50px;
  letter-spacing: 1px;
  border: ${(props) =>
    props.isvalid === 'error'
      ? `1px solid ${props.theme.red}`
      : `1px solid ${props.theme.secondBackground}`};

  &:focus {
    border: ${(props) =>
      props.isvalid === 'error'
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.primary}`};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 50%;
  }
`;

export const ReactTextInput = styled(NumericFormat)`
  position: relative;
  height: 44px;
  background: ${({ theme }) => theme.secondBackground};
  color: ${({ theme }) => theme.text};
  font-weight: 500;
  font-size: ${(props) =>
    props.type === 'date' || props.type === 'time' ? '12px' : '16px'};
  padding-left: 16px;
  border: none;
  border-radius: 4px;
  padding-right: 50px;
  letter-spacing: 1px;
  border: ${(props) =>
    props.isvalid === 'error'
      ? `1px solid ${props.theme.red}`
      : `1px solid ${props.theme.secondBackground}`};

  &:focus {
    border: ${(props) =>
      props.isvalid === 'error'
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.primary}`};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 50%;
  }
`;

export const ImageEditIcon = styled.i`
  background: ${({ theme }) => theme.primary};
  border-radius: 100%;
  color: #000;
  position: absolute;
  width: 28px;
  bottom: -5px;
  z-index: 999;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  &.fa-save,
  fa-cut {
    left: -10px;
  }

  &.fa-camera {
    right: -10px;
  }

  &.fa-pencil-alt {
    right: -10px;
    font-size: 14px;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : '')};

  &.column {
    flex-direction: column;
  }

  &.right {
    margin-left: auto;
  }

  &.left {
    margin-right: auto;
  }

  &.center {
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }

  &.fg1 {
    flex-grow: 1;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.jusitfy-around {
    justify-content: space-around;
  }
  &.jusitfy-between {
    justify-content: space-between;
  }
`;

export const CancelButton = styled.button`
  margin-top: 12px;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 4px 20px;
  border-radius: 30px;
  background: ${({ theme }) => theme.secondaryButtonBackground};
  color: ${({ theme }) => theme.lightGray};
  //border: 1px solid ${({ theme }) => theme.middleGray};
  max-width: 300px;
`;

export const Link = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.primary};
  &.underline {
    text-decoration: underline;
  }
`;

export const TextContainer = styled.div`
  font-size: 12px;
  padding: 16px;
  p {
    text-align: justify;
  }
`;
