import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { Container, Link } from 'src/styling/globalStyling';
import styled from '@emotion/styled';

const PodaciKopmanije = () => {
  return (
    <Container className='p016'>
      <GoBack />
      <Heading title={'Podaci kompanije'} />
      <NisvilleContainer>
        <div>Naziv pravnog lica: NIŠVILLE FONDACIJA</div>
        <div>Adresa: Bulevar Doktora Zorana Đinđića 58a</div>
        <br />
        <div>PIB: 104623652</div>
        <div>Matični broj: 17660632</div>
        <div>
          Šifra delatnosti: 9499 Delatnost ostalih organizacija na bazi
          učlanjenja
        </div>
        <br />
        <div>Telefon: +381(0)648850565</div>
        <div>
          Web adresa:{' '}
          <Link href='https://nisville.com' target='_blank'>
            nisville.com
          </Link>
        </div>
        <div>Email: tickets@nisville.com</div>
      </NisvilleContainer>
    </Container>
  );
};

export default PodaciKopmanije;

const NisvilleContainer = styled.div`
  font-size: 14px;
`;
