import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import Spinner from 'src/components/Spinner/Spinner';
import Heading from 'src/components/heading/heading';
import config from '../../config';
import { Button, Container, Flex, Link } from 'src/styling/globalStyling';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLang } from 'src/components/useLang';
import moment from 'moment';
import { serbianFormatter } from 'src/utils';

type Transaction = {
  amount: number;
  currency: string;
  pgOrderId: string;
  pgTranApprCode: string;
  pgTranRefId: string;
  pgTranReturnCode: string;
  timeCreated: string;
  transactionId: string;
  transactionStatus: string;
};

type ResultResponse = {
  transactionDetails?: Transaction;
  user?: { name: string; email: string };
  productList?: any;
};

const PaytenPaymentResults = () => {
  const history = useHistory();
  const lang = useLang();
  const tid = new URLSearchParams(history.location.search).get('tid');
  //@ts-ignore
  const { userData } = useSelector((state) => state.profile);

  const { data, isLoading, isError } = useQuery<
    AxiosResponse<any>,
    AxiosError,
    AxiosResponse<ResultResponse>
  >(
    ['payment_results'],
    () => {
      const url = `${config.baseApi}/v1/payments/payten/status/${tid}`;
      return axios.get(url);
    },
    {
      enabled: !!tid,
    }
  );

  if (!tid) {
    history.push('/home');
  }

  return (
    <Container>
      <Heading title={'Plaćanje'} showLogo />
      {isLoading && <Spinner className='mt-2' />}
      {!!data?.data.transactionDetails?.pgTranRefId && (
        <SuccessComponent>
          <div className='text-center'>
            <h4>Uspešno – račun platne kartice zadužen</h4>
          </div>
          <UserData>
            <b>{lang.user}:</b>
            <div>{data?.data.user?.name}</div>
            <div>{data?.data.user?.email}</div>
          </UserData>
          <UserData style={{ maxWidth: '300px' }}>
            <b>{lang.order_details}:</b>
            <div>ID narudžbine: {data.data.transactionDetails.pgOrderId}</div>
            <ProductsTable>
              <thead>
                <td>Naziv</td>
                <td>Kol</td>
                <td>PDV</td>
                <td>Cena sa PDV-om</td>
              </thead>
              <tbody>
                {data.data.productList.map((ticket) => {
                  return (
                    <tr>
                      <td>
                        {ticket.name === 'Troskovi obrade'
                          ? 'Troškovi obrade'
                          : ticket.name}
                      </td>
                      <td>{ticket.quantity}</td>
                      <td>10%</td>
                      <td>{serbianFormatter.format(ticket.amount)}RSD</td>
                    </tr>
                  );
                })}
              </tbody>
            </ProductsTable>
            <div style={{ fontWeight: 'bold' }}>
              Ukupno:{' '}
              {serbianFormatter.format(data.data.transactionDetails.amount)}{' '}
              {data.data.transactionDetails.currency}
            </div>
          </UserData>
          <UserData>
            <b>{lang.transaction_details}:</b>
            <div>Broj narudžbine: {data.data.transactionDetails.pgOrderId}</div>
            <div>
              Autorizacioni kod: {data.data.transactionDetails.pgTranApprCode}
            </div>
            <div>
              Status transakcije:{' '}
              {data.data.transactionDetails.transactionStatus === 'AP'
                ? 'Uspešan'
                : 'Neuspešan'}
            </div>
            <div>
              Kod statusa transakcije:{' '}
              {data.data.transactionDetails.transactionStatus}
            </div>
            <div>
              Broj transakcije: {data.data.transactionDetails.transactionId}
            </div>
            <div>
              Datum transakcije:{' '}
              {moment(data.data.transactionDetails.timeCreated).format(
                'HH:mm DD.MM.YYYY.'
              )}
            </div>
            <div>
              Iznos transakcije:{' '}
              {serbianFormatter.format(data.data.transactionDetails.amount)}{' '}
              {data.data.transactionDetails.currency}
            </div>
            <div>
              Referentni ID transakcije:{' '}
              {data.data.transactionDetails.pgTranRefId}
            </div>
          </UserData>

          <UserData>
            <b>Podaci o Trgovcu:</b>
            <div>Naziv pravnog lica: NIŠVILLE FONDACIJA</div>
            <div>PIB: 104623652</div>
            <div>Adresa: Bulevar Doktora Zorana Đinđića 58a</div>
          </UserData>

          <div>Ulaznice možete naći na Vaš email ili u e-novčaniku.</div>
          <div>
            Prilikom ulaska na događaj ulaznice možete pokazati direktno sa
            telefona, pokazati printscreen QR koda ili pokazati odštampanu
            ulaznicu koju ste dobili na e-mail.
          </div>
          <div>
            Ulaznice čuvajte. Jednom iskorišćenu ulaznicu nije moguće ponovo
            upotrebiti.
          </div>
          <Button
            className='small mt1'
            onClick={() => history.push('/mytickets')}
          >
            Ulaznice
          </Button>
          <Link className='mt1 mb3' onClick={() => history.push('/show/61')}>
            Nazad na kupovinu ulaznica
          </Link>
        </SuccessComponent>
      )}
      {((!isLoading &&
        data?.data.transactionDetails?.transactionStatus !== 'AP') ||
        isError) && (
        <div>
          <div className={'mt1 mb2 text-size-xl text-center'}>
            Neuspešno – račun platne kartice nije zadužen.
          </div>
          <div>
            Plaćanje nije uspešno, vaš račun nije zadužen. Najčešći uzrok je
            pogrešno unet broj kartice, datum isteka ili sigurnosni kod.
            Pokušajte ponovo, a u slučaju uzastopnih grešaka pozovite vašu banku
          </div>
          <Button onClick={() => history.push('/')}>Pokušajte ponovo</Button>
        </div>
      )}
    </Container>
  );
};

export default PaytenPaymentResults;

const SuccessComponent = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 0 16px;
`;

const UserData = styled.div`
  display: flex;
  flex-direction: column;
  div {
    font-size: 12px;
  }
`;

const ProductsTable = styled.table`
font-size: 12px;
  thead {
    font-weight: bold;
  }
`