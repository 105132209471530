import ActionTypes from './ActionTypes'

const initalState = {
	test: true,
	update: null,
	offline: false,
	posts: []
}

export default function postsReducer(state = initalState, action) {
	switch (action.type) {
		case 'UPDATE_AVAILABLE':
			return Object.assign({}, state, {update: action.payload})
		case ActionTypes.SET_OFFLINE:
			return Object.assign({}, state, {offline: action.payload})
		case ActionTypes.SET_POSTS:
			return Object.assign({}, state, {posts: action.posts.content})
		default:
			return state
	}
}
