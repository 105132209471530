import { HTMLAttributes } from 'react';
import { Flex, Link } from 'src/styling/globalStyling';
import styled from 'styled-components';

const NisvilleFooter = (props: HTMLAttributes<HTMLDivElement>) => (
  <NisvilleFooterContainer {...props}>
    <Flex className='fullWidth jusitfy-around'>
      <Flex gap='2px' className='column'>
        <Link href='/#/o_nama'>O nama</Link>
        <Link href='/#/podaci_kompanije'>Podaci kompanije</Link>
        <Link href='/#/deliveryMethods'>Način dostave</Link>
      </Flex>
      <Flex gap='2px' className='column'>
        <Link href='/#/paymentMethods'>Način plaćanja</Link>
        <Link href='/#/politikaPrivatnosti'>Politika privatnosti</Link>
        <Link href='/#/usloviKoriscenja'>Uslovi korišćenja</Link>
      </Flex>
    </Flex>
  </NisvilleFooterContainer>
);

export default NisvilleFooter;

const NisvilleFooterContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.gray};
  
  &.pb60 {
    padding-bottom: 60px;
  }
`;
