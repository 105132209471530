export const Tab = (props: any) => {
  const { index, selectedTab, setIndex } = props;
  const isActive = index === selectedTab;
  return (
    <div
      onClick={() => setIndex(index)}
      style={{
        color: '#000',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        backgroundColor: isActive ? '#cecece' : '#fff',
        fontSize: '12px',
        fontWeight: isActive ? 'bold' : 'normal',
        width: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #cecece',
        cursor: 'pointer',
      }}
    >
      {props.children}
    </div>
  );
};
