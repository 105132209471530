import axios from 'axios';

const { REACT_APP_HERE_APP_ID: appId, REACT_APP_HERE_API_KEY: apiKey } =
  process.env;

const dicoverURL = `https://discover.search.hereapi.com/v1/discover?q={TERM}&apiKey=${apiKey}`;
const searchURL = `https://geocoder.ls.hereapi.com/search/6.2/geocode.json?languages=en-US&maxresults=4&searchtext={TERM}&apiKey=${apiKey}`;

const suggest =
  'https://autosuggest.search.hereapi.com/v1/autosuggest' +
  '?lang=en' +
  '&q={TERM}' +
  '&apiKey=' +
  apiKey;

const geocode =
  'https://geocode.search.hereapi.com/v1/geocode' +
  '?q={TERM}' +
  '&lang=en' +
  '&apiKey=' +
  apiKey;

class heremapsService {
  geocode(term) {
    const url = geocode.replace('{TERM}', term);
    return axios.create().get(url);
  }
  lookup(hereId) {
    const url = `https://lookup.search.hereapi.com/v1/lookup?id=${hereId}&apiKey=${apiKey}`;
    return axios.create().get(url);
  }
  discover(term) {
    const url = dicoverURL.replace('{TERM}', term);
    return axios.create().get(url);
  }
  search(term) {
    const url = searchURL.replace('{TERM}', term);
    return axios.create().get(url);
  }

  suggest(term) {
    const url = suggest.replace('{TERM}', term);
    return axios.create().get(url);
  }

  autoComplete(term) {
    const url = `${suggest}?appId=${appId}&apiKey=${apiKey}&query=${term}`;
    return axios.create().get(url);
  }
}

export default new heremapsService();
