import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import config from "src/config";


export type PaymentData = {
  showId: number;
  ticketList: {
    numberOfTickets: number;
    ticketTypeId: number;
  }[];
};

const usePayten = () => {
    const url = `${config.baseApi}/v1/payments/payten/initiate/tickets`
    return useMutation<AxiosResponse<any>, AxiosError, PaymentData, unknown>(
      (data: PaymentData) => {
        return axios.post(url, data);
      }
    );
}

export default usePayten;