import React, {useRef, useState} from 'react'
import styled, {useTheme} from "styled-components";
import Popup from "../popup/popup";
import Portal from "../portal/portal";
import profileService from "../../pages/profile/profileService";
import {useDispatch, useSelector} from "react-redux";
import {getUserData} from "../../pages/profile/profileActions";
import {useHistory} from "react-router-dom";
import Spinner from "../Spinner/Spinner";

function VerifyProfile(props) {
	const theme = useTheme()
	const dispatch = useDispatch()
	const history = useHistory()
	const lang = useSelector(state => state.home.language)
	const [verifyPhoto, setVerifyPhoto] = useState(null)
	const [verifyPhotoB64, setVerifyPhotoB64] = useState(null)
	const [loading, setLoading] = useState(false)
	const verifyFileInput = useRef()

	const handleUploadClick = () => {
		verifyFileInput.current.click()
	}

	const handleVerifyPhoto = (e) => {
		if (e.target.files.length) {
			const file = e.target.files[0]
			const fileReader = new FileReader()
			fileReader.onloadend = () => {
				setVerifyPhoto(file)
				setVerifyPhotoB64(fileReader.result)
			}
			fileReader.readAsDataURL(e.target.files[0]);
		}
	}

	const getVerificationInput = () => {
		return <div>
			<div className={'mb1'}>{lang.verify_message1}</div>
			<div className={'mb1'}>{lang.verify_message2}</div>
			<div className={'mb1'}>{lang.profile_help_message2}</div>
			{verifyPhoto ?
				<div style={{height: '60px', alignText: 'center'}}>
					<div>{verifyPhoto.name}</div>
					<i
						className={'fas fa-check'}
						style={{
							fontSize: '30px',
							color: theme.primary,
							marginTop: '5px'
						}}
					/>
				</div> :
				<UploadButton
					className={'flexCenter column'}
					onClick={handleUploadClick}
				>
					<i className={'icon-upload_img'}/>
					<div>Upload</div>
				</UploadButton>}
		</div>
	}

	const clearState = () => {
		setVerifyPhoto(null)
		setVerifyPhotoB64(null)
		props.closeModal(false)
	}

	const onPopupSubmit = () => {
		setLoading(true)
		profileService.verifyUser(verifyPhotoB64)
			.then(res => {
				getUserData(dispatch)
				clearState()
				history.push('/profile')
			})
			.catch(err => console.log('err', err))
			.finally(() => setLoading(false))
	}

	return <>
		<input
				ref={verifyFileInput}
				style={{display: 'none'}}
				type="file"
				onChange={handleVerifyPhoto}
				accept="image/*"
			/>
		{props.openVerifyModal && <Portal>
		{loading && <Spinner fullscreen />}
		<Popup
			position={'top'}
			title={lang.verification}
			content={getVerificationInput()}
			cancelAction={clearState}
			submitAction={onPopupSubmit}
			submitText={lang.upload}
			submitDisabled={!verifyPhoto}
			trackingTitle={'verify_modal'}
		/>
	</Portal>}</>
}

export default VerifyProfile


export const UploadButton = styled.div`
	cursor: pointer;
	//height: 60px;
	border: 2px dashed ${({theme}) => theme.uploadImageBorder};
	padding: 20px 0;
`
