import { Container, TextContainer } from "src/styling/globalStyling"
import GoBack from "../goBack/goBack"
import Heading from "../heading/heading"

const ONama = () => {
    return (
      <Container>
        <GoBack />
        <Heading title='O nama' />
        <TextContainer>
          <h3>NIŠVILLE JAZZ FESTIVAL – BREND UMETNIČKE MUZIKE</h3>
          <p>
            Internacionalni Nišville Jazz Festival – najposećeniji džez festival
            jugoistočne Evrope, od samog osnivanja 1995. godine dosledno brani
            evropske vrednosti multikulturalnosti i strpljivo neguje prefinjen
            muzički ukus pojedinaca. Potvrda toga je, između ostalog i tekst o
            festivalu “Nišville – evropsko lice Srbije” (“Nišville – European
            Face Of Serbia”) objavljen u glasilu Evropske unije, magazinu “New
            Europe” koji izlazi u Briselu, kao i tekst u prestižnom britanskom
            Gardijanu iz 2016, gde je Nišville uvršten u 10 najboljih jazz
            festivala u Evropi!
            https://www.theguardian.com/travel/2016/apr/20/top-10-jazz-festivals-europe-montreux-umbria-cork
          </p>
          <p>
            Ove godine, Nišville je pod sloganom FESTIVAL FESTIVALA obeležio
            značajan jubilej – 25. godina postojanja, a pored vrlo kvalitenog
            muzičkog programa na 12 bina, održan je i sada već tradicionalni
            “Nišville jazz teatarski festival” sa 16 internacionalnih pozorišnih
            predstava i filmski festival “Nišville Movie Summit”, na kome je
            prikazano preko 100 filmova međunarodne i domaće produkcije sa
            muzičkom igranom i dokumentarnom tematikom na više lokacija u niškoj
            Tvrđavi, kao i u strogom centru grada Niša. U bogatom pratećem
            programu festival (takođe besplatno za publiku) poslednjih godina
            predstavlja i primenjenu slikarsku umetnost kroz umetničku koloniju
            međunarodnog karaktera – Art To Go Jazzy, Jazz balet, promocije
            knjiga i časopisa sa jazz tematikom – Nišville Book Session, izložbe
            strip novela i radionice stripa – Striporama, izložbe fotografija,
            te razne tribine, performanse („Nišville Body Art“, „Nišville
            Oldtimers Fest“), itd. Nišville 2019 je trajao 11 dana, od 1. do 11.
            avgusta i na preko 20 scena okupio je preko 1.000 izvođača iz celog
            sveta, a izvedeno je oko 300 programa, što je pratilo približno
            200.000 posetilaca! Nišville Jazz Festival je Javna Gradska
            Manifestacija grada Niša od 2005, a od 2010. godine odlukom
            Ministarstva kulture Republike Srbije bio je i Manifestacija od
            Nacionalnog značaja. Nišville je i dobitnik statue “Najbolje iz
            Srbije” za 2011. godinu, po izboru Ministarstva trgovine i usluga,
            Privredne komore Srbije i časopisa “Privredni pregled”, a dobitnik
            je i nagrade “Projekat budućnosti” u akciji Kluba privrednih
            novinara Srbije 2010 i Centra za mala i srednja preduzeća.
            Turistička organizacija Srbije uvrštava Nišville u svoju zvaničnu
            sajamsku ponudu širom Evrope, a mnoge domaće i strane turističke
            organizacije i agencije pozivaju zaljubljenike u džez da u avgustu
            posete Srbiju i mitski grad Niš, upravo zbog ovog jedinstvenog
            festivala na našim prostorima.
            </p>
            <p>
              Logično, Nišville Jazz Festival 2015 dobija i prestižnu nagradu
              “Turistički cvet” za najbolju turističko – kulturnu manifestaciju
              u Srbiji. Ove godine Nišville je proglašen i za najbolji festival
              u Srbiji, pod patronatom „TOP SERBIAN BRANDS 2018“. Koncept
              festivala je od početka, osim na „tradicionalnijim” formama džeza,
              bio zasnovan pre svega na fuziji ovog pravca sa etno tradicijama
              različitih delova sveta a naročito Balkana. Najpoznatiji džez
              magazin na svetu, američki „Downbeat” je u opširnom prikazu
              festivala 2008. godine ocenio Nišville kao festival koji na
              najbolji mogući način istovremeno promoviše džez kao pravac nastao
              na američkom kontinentu, muzičku tradiciju Balkana, ali i posebno
              spoj ta dva stila, čime je Nišville u mnogome doprineo
              predstavljanju balkanske muzike kao novog svetskog trenda. Glavni
              program festivala održava se na otvorenom prostoru Platoa niške
              Tvrđave, na dve bine – Earth & Sky stage, a mnogi kvalitetni
              domaći i strani izvođači nastupaju na binama koje su besplatne za
              publiku: River, Open, Air, Hip Hop, Midnight Jazzdance, Movie,
              Jazz For Kids, Welcome, Youth, Jazz Museum stage, Kristina stage
              itd. Važan segment festivala predstavlja Nišville Jazz Workshop,
              uspostavljen 2007. godine.
            </p>
            <p>
              Prvu godinu džez radionica gitarski workshop je držao legendarni
              gitarista Larry Coryell, a 2008. predavanja je držao Duško
              Gojković, kao i jedan od najboljih bubnjara na svetu Billy Cobhan.
              Od 2009. gotovo svi značajniji učesnici festivala učestvovali su
              kao predavači u konceptu besplatnih džez radionica za skoro sve
              instrumente koji se koriste u ovom muzičkom žanru. Ovde treba
              svakako naznačiti edukativni značaj Nišville muzičkih radionica,
              koje po pravilu inspirišu mlade talentovane muzičare, tako da ne
              čudi činjenica da se svake godine u Nišu, ali i u celoj Srbiji
              osnivaju novi mladi jazz sastavi koji u relativno kratkom
              vremenskom periodu dobijaju šansu da nastupe što na samom Nišvilu,
              što na drugim domaćim i internacionalnim jazz festivalima. Takođe,
              od pre dve godine uveden je i „Nišville Serbian Showcase“ program,
              koji promoviše ovdašnje mlade jazz sastave, a njihove nastupe
              prati i žirira eminentna postava jazz impresarija, promotera,
              novinara i direktora jazz festivala iz čitavog sveta (Amerika,
              Rusija, Kina, Litvanija, Makedonija, Bugarska, Holandija,
              Hrvatska, Nemačka, Albanija, BiH, Crna Gora, itd.). Između
              ostalog, na taj način Nišville pomaže mladim bendovima da nastupe
              na festivalima širom sveta i predstave ovdašnju jazz scenu. Svake
              godine Nišville organizuje i besplatne koncerte učesnika festivala
              u različitim gradovima Srbije i regiona, uključujući Dimitrovgrad,
              Paraćin, Beograd, Novi Sad, Suboticu, Aleksinac, Prokuplje,
              Vranje, Pirot, Leskovac, Zaječar, Aranđelovac, itd).
            </p>
            <p>
            Festival je celovečernjim programima gostovao i u glavnim gradovima
            susednih država ( „Nišville u Sofiji“ u Nacionalnom dvorcu kulture i
            „Nišville u Skoplju“ u Univerzalnoj sali), itd. Između ostalih, na
            Nišvilu su do sada nastupali: Solomon Burke, Tom Harrell, All Di
            Meola, Monty Alexander, Brian Blade, Richard Galliano, Benny Golson,
            Randy Brecker, Miroslav Vitous, John Patitucci, Candy Dulfer, Roy
            Hargrove, Sly & Robbie, Mingus Dinasty, The Cookers, Tania Maria,
            Billy Cobham, Rosenberg Trio, The Original Blues Brothers Band, Ron
            Carter, Dr Donald Byrd, Ginger Baker, Duško Gojković, Brussels Jazz
            Orchestra, Incognito, De Phazz, Jazzanova, Joss Stone, Bob Geldof,
            Maceo Parker, Alpha Blondy, Omar Hakim, Inner Circle, Gipsy Kings,
            Christian Scott, Chris Potter, Stanley Jordan, Seun Kuti, Lenny
            White, Bill Evans, Nouvell Vague, September, Bisera Veletanlić,
            Vasil Hadžimanov, Vlatko Stefanovski, Rambo Amadeus, The Brand New
            Heavies, Gilles Peterson & Jose James, David Murrey, Al Foster, Sun
            Ra Arkestra itd. Dvadeset šesti Nišville Jazz Festival biće održan
            od 7. do 16.avgusta 2020.
          </p>
        </TextContainer>
      </Container>
    );
}

export default ONama;