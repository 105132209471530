import config from '../../config'
import axios from 'axios'


class profileService {
	getMyData() {
		const url = config.baseApi + '/v1/users/profile/me'
		return axios.get(url)
	}

	verifyUser(photo) {
		const pureBase64 = photo.split(',')[1]
		const url = config.baseApi + '/v1/users/verify'
		return axios.post(url, {photo: pureBase64})
	}

	getReferrals() {
		const url = config.baseApi + '/v1/users/profile/refered_by_me'
		return axios.get(url)
	}

	deleteProfile() {
		const url = config.baseApi + '/v1/users/profile/me'
		return axios.delete(url)
	}

	activatePremium(code) {
		const url = config.baseApi + '/v1/users/profile/premium'
		return axios.put(url, {premiumCode: code})
	}
}

export default new profileService()
