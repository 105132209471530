import React, {useState} from 'react'
import styled from "styled-components";
import {useSelector} from "react-redux";

interface IMoreProps {
	children?: any
}

const More = (props: IMoreProps) => {
	const lang = useSelector((state: any) => state.home.language)
	const [expanded, setExpanded] = useState(false)

	return <MoreContainer>
		<div className={`content${expanded ? ' expanded' : ''}`}>
			{props.children}
		</div>
		<div onClick={() => setExpanded(!expanded)} className={expanded ? 'less' : 'more'}>{expanded ? lang.less : lang.more + '...'}</div>
	</MoreContainer>
}

export default More

const MoreContainer = styled.div`
  position: relative;

  .content {
    max-height: 100px;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
		transition: max-height 0.1s ease-in-out;
		user-select: text;
    &.expanded {
      max-height: 2000px;
			-webkit-mask-image: unset;
    }
  }

  .more, .less {
    text-align: center;
    font-weight: 500;
    margin-top: -5px;
		z-index: 1;
		cursor: pointer;
  }
	.less {
		margin-top: 0;
	}


`