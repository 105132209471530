import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Flex, Label } from "../../styling/globalStyling";
import { Tab, Tabs } from "../../components/sweapable-tabs";
import { useTheme } from "styled-components";
import GoBack from "../../components/goBack/goBack";
import Heading from "../../components/heading/heading";
import { useHistory } from "react-router-dom";
import { Unsubscribe } from "../publicProfile/publicProfile";
import styled from "styled-components";
import SubscriptionService from "./subscriptionService";
import { getSubscriptions } from "../profile/profileActions";
import Input from "../../components/input/input";
import { getLocationObject, handleLoadAddressOptions } from "../../utils";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Spinner from "../../components/Spinner/Spinner";
import { colourStyles } from "../Login/utils";
import { NothingHere } from "../myTickets/myTickets";
import UserDetailsBean from "../../components/userDetailsBean";

const Subscriptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const addressOptions = useRef([]);
  const subs = useSelector((state) => state.home.subscriptions);
  const lang = useSelector((state) => state.home.language);

  const [selectedTab, setSelectedTab] = useState(0);
  const [addressObject, setAddressObject] = useState({
    label: "",
    value: null,
  });
  const [radius, setRadius] = useState(20);
  const [followLocationDisabled, setFollowLocationDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (addressObject?.value?.address?.city) {
      setFollowLocationDisabled(false);
    } else {
      setFollowLocationDisabled(true);
    }
  }, [addressObject]);

  const unsubscribe = (e, subId) => {
    e.stopPropagation();
    setError("");
    setLoading(true);
    SubscriptionService.unsubscribe(subId)
      .then(() => {
        getSubscriptions(dispatch);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const followLocation = () => {
    setError("");
    try {
      const location = getLocationObject(addressObject.value);
      if (!location.city) return;
      const data = {
        subscribedLocation: location,
        subscriptionRadius: radius,
      };

      setLoading(true);
      SubscriptionService.subscribeToLocation(data)
        .then((res) => {
          setAddressObject({ label: "", value: null });
          setRadius(20);
          getSubscriptions(dispatch);
        })
        .catch((e) => {
          setError(e.message);
        })
        .finally(() => setLoading(false));
    } catch (e) {
      setError(e.message);
    }
  };

  const goToProfile = (e, user) => {
    e.stopPropagation();
    history.push({ pathname: "/public/" + user.id, user });
  };

  return (
    <Container>
      <GoBack customAction={() => history.push("/profile")} />
      <Heading
        title={lang.subscriptions_title}
        help={[lang.subscriptions_title_help]}
      />
      {loading && <Spinner fullscreen />}
      <Tabs
        value={selectedTab}
        onChange={(t) => setSelectedTab(t.key)}
        tabBarCSS={`
						font-size: 16px;
						font-weight: 500;
						height: 56px;
						margin-top: -16px;
						margin-bottom: 12px;
						`}
        tabItemCSS={`
						line-height: 56px;
						font-size: 24px;
						padding-top: 5px;
						`}
        styleProps={{
          barColor: "transparent",
          selectedHeaderTextColor: theme.primary,
          headerTextColor: "#fff",
          activeInkBarColor: theme.primary,
          inkBarColor: "transparent",
          inkBarSize: "1px",
          size: "medium",
          lineHeigth: "56px",
        }}
      >
        <Tab label={<i className={"icon-profile"} />} key={0}>
          <div className={"pb200 noscroll"} id={"scroll-container-0"}>
            {Object.values(subs.users).map((sub) => {
              const { subject: user } = sub;
              return (
                <UserDetailsBean
                  onClick={(e) => goToProfile(e, user)}
                  key={"l" + user.id}
                  user={user}
                  topRight={
                    <UnsubscribeUser
                      className={"profile"}
                      onClick={(e) => unsubscribe(e, sub.id)}
                    >
                      <i className={"fas fa-times"} />
                    </UnsubscribeUser>
                  }
                />
              );
            })}

            {!Object.values(subs.users).length && (
              <NothingHere>{lang.no_following}</NothingHere>
            )}
          </div>
        </Tab>
        <Tab label={<i className={"fas fa-map-marker-alt"} />} key={1}>
          <div className={"pb200 noscroll"} id={"scroll-container-1"}>
            <Flex style={{ position: "relative", paddingLeft: "16px" }}>
              <div
                className={"flex flex-direction-column"}
                style={{ flexGrow: 1 }}
              >
                <Label style={{ marginTop: 0 }}>Location</Label>
                <AsyncSelect
                  id={"address"}
                  value={addressObject}
                  onChange={(location) => setAddressObject(location)}
                  loadOptions={handleLoadAddressOptions}
                  defaultOptions={addressOptions.current}
                  className={"address"}
                  styles={colourStyles(theme)}
                  cacheOptions
                />
              </div>
              <>
                <Input
                  value={radius}
                  label={"Radius"}
                  type={"number"}
                  descriptionPositionUp
                  description={"km"}
                  min={1}
                  onChange={(e) => setRadius(e.target.value)}
                  isvalid={radius < 1 ? "error" : "success"}
                  wrapperstyle={{
                    width: "80px",
                    marginLeft: "16px",
                    marginTop: 0,
                    padding: 0,
                  }}
                />
              </>
              <div className={"flex align-end"}>
                <SubscribeLocationButton
                  className={`${
                    (followLocationDisabled || radius < 1) && "disabled"
                  } fas fa-plus-circle`}
                  onClick={(e) => {
                    e.stopPropagation();
                    followLocation();
                  }}
                />
              </div>
            </Flex>
            {error && <div className={"text-danger text-center"}>{error}</div>}
            {subs.locations.map((sub) => {
              const { subscribedLocation: location, subscriptionRadius: rad } =
                sub;
              return (
                <LocationSub key={sub.id} className={"flex align-center"}>
                  <div>
                    {location?.city} ({location?.countryName}) + {rad}km radius
                  </div>
                  <UnsubscribeUser
                    className={"location"}
                    onClick={(e) => unsubscribe(e, sub.id)}
                  >
                    <i className={"fas fa-times"} />
                  </UnsubscribeUser>
                </LocationSub>
              );
            })}
            {!subs.locations?.length && (
              <NothingHere>{lang.no_following_locations}</NothingHere>
            )}
          </div>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Subscriptions;

export const UnsubscribeUser = styled(Unsubscribe)`
  width: 40px;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  // justify-content: center;
  // align-items: center;

  &.profile {
    margin-top: 4px;
  }

  &.location {
    margin-right: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SubscribeLocationButton = styled.i`
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary};
  font-size: 28px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.9;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
const LocationSub = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: ${({ theme }) => theme.secondBackground};
  margin: 12px 16px;
  border-radius: 4px;
`;
// export const colourStyles = (theme) => {
// 	return ({
// 		container: (styles) => ({...styles, borderRadius: '4px', flexGrow: 1}),
// 		menuList: (styles) => ({...styles, background: theme.secondBackground}),
// 		control: (styles, props) => {
// 			return ({
// 				...styles,
// 				backgroundColor: theme.secondBackground,
// 				height: '44px',
// 				color: theme.text,
// 				fontWeight: 500,
// 				fontSize: '16px',
// 				borderRadius: '4px',
// 				letterSpacing: '1px',
// 				border: `1px solid ${props.isFocused && props.menuIsOpen ? theme.primary : theme.secondBackground}`,
// 				'&:hover': {
// 					border: `1px solid ${theme.primary}`
// 				},
// 				boxShadow: 'unset'
// 			})
// 		},
// 		option: (styles, {data, isDisabled, isFocused, isSelected}) => {
// 			return {
// 				...styles,
// 				backgroundColor: isDisabled
// 					? null
// 					: isSelected
// 						? theme.selectOptionBgrSelected
// 						: isFocused
// 							? theme.selectOptionBgrFocused
// 							: null,
// 				color: isDisabled
// 					? theme.selectOptionText
// 					: isSelected ? theme.selectOptionTextSelected
// 						: isFocused ? theme.selectOptionTextSelected
// 							: theme.selectOptionText,
// 				cursor: isDisabled ? 'not-allowed' : 'default',
//
// 				':active': {
// 					...styles[':active'],
// 					backgroundColor: theme.selectOptionBgrSelected,
// 				},
// 			};
// 		},
// 		input: styles => ({...styles, color: theme.text}),
// 		placeholder: styles => ({...styles, color: theme.selectBackground}),
// 		singleValue: (styles, {data}) => ({...styles, color: theme.text}),
// 		indicatorSeparator: (styles) => ({...styles, display: 'none'}),
// 		dropdownIndicator: (base, state) => ({
// 			...base,
// 			transition: 'all .2s ease',
// 			transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
// 			color: theme.text
// 		}),
// 		valueContainer: styles => ({...styles, color: theme.text}),
// 	})
// }
