import React from 'react'
import {FangoutComponent, Details, Fangout, Main, FangoutDescription, FoutName} from './fangoutComponent'
import {currencyMap, foutStatus} from '../../constants'
import moment from 'moment'
import {connect} from 'react-redux'
import Input from '../input/input'
import createFangoutService from '../../pages/createFangout/createFangoutService'
import styled, {withTheme} from 'styled-components'
import emptyAvatar from '../../assets/images/empty-avatar.jpg'
import {getRequests} from "../../pages/requests/requestsActions";
import {setFangouts} from "../../pages/fangouts/fangoutsActions";
import {withRouter} from "react-router-dom";
import {Helper} from "../../pages/profile/profile";
import Portal from "../portal/portal";
import Popup from "../popup/popup";
import Countdown from "react-countdown";


const DISPUTE = 'DISPUTE'

const inputWrapperStyle = {
	alignSelf: 'flex-start',
	margin: '10px 16px',
}

function idAsc(a, b) {
	if (a.messageId > b.messageId) {
		return 1
	} else if (a.messageId < b.messageId) {
		return -1
	}
	return 0
}

class RequestsFangoutComponent extends FangoutComponent {
	state = {
		message: '',
		conversation: [],
		sendingMessage: false,
		error: '',
		price: 100,
		disputeModal: false,
		disputeMessage: '',
		disputeHelpModal: false,
	}

	componentDidMount() {
		// this.getConversation()
		if (this.props.match.params.fangoutId && parseInt(this.props.match.params.fangoutId) === this.props.f.id) {
			this.setConversationInterval()
		}
	}

	componentWillUnmount() {
		// clear interval if there is one
		if (this.interval) clearInterval(this.interval)
	}

	getConversation = () => {
		this.setState({error: ''})
		createFangoutService.getConversation(this.props.f.id)
			.then(res => {
				this.setState({conversation: res.data.content})
			})
			.catch(e => this.setState({error: e.message}))
	}

	setConversationInterval = () => {
		this.getConversation()
		this.interval = setInterval(this.getConversation, 10000)
	}

	expandAndSetUpdateInterval = (e) => {
		const expandedFromProps = this.props.expanded
		const expanded = expandedFromProps ? this.props.expanded[this.props.f.id] : this.state.expanded
		if (!expanded) {
			// should expand - set update interval
			// but first update the conversations
			this.setConversationInterval()
		} else {
			// clear the interval
			clearInterval(this.interval)
		}

		if (expandedFromProps) {
			this.props.expandFangout(e, this.props.f.id)
		} else {
			// switch the state
			this.setState({expanded: !expanded})
		}
	}

	sendMessage = () => {
		const f = this.props.f
		const message = {
			"fangoutId": f.id,
			"message": this.state.message
		}
		this.setState({sendingMessage: true, error: ''})
		createFangoutService.sendMessage(message)
			.then(res => {
				this.getConversation()
				this.setState({message: ''})
			})
			.catch(e => {
				this.setState({error: e.message})
			})
			.finally(() => {
				this.setState({gettingMessage: false, sendingMessage: false})
			})
	}

	setPriceForRequest = (e) => {
		e.stopPropagation()
		e.preventDefault()
		const fangoutId = this.props.f.id
		const price = this.state.price
		this.setState({error: ''})
		createFangoutService.setFangoutRequestPrice(fangoutId, price)
			.then(res => {
				this.props.getRequests()
				this.props.setFangouts()
			}).catch(e => {
			this.setState({error: e.message})
		})
	}

	acceptAndPay = e => {
		e.stopPropagation()
		e.preventDefault()
		this.props.history.push({pathname: '/pay', fangout: this.props.f})
	}

	onKeyPress = e => {
		const keyCode = e.code || e.key;
		if (keyCode == 'Enter') {
			// Enter pressed
			this.sendMessage()
		}
	}

	getDisputeContent = () => {
		return <div>
			{this.props.lang.requests_dispute_content}
			<TextArea
				autoFocus={'autofocus'}
				className={'mt1'}
				maxLength={2000}
				value={this.state.disputeMessage}
				onChange={e => this.setState({disputeMessage: e.target.value})}/>
		</div>
	}

	disputeFangout = (e) => {
		e.stopPropagation()
		if (this.props.f.id) {
			createFangoutService.disputeFangout(this.props.f.id, {message: this.state.disputeMessage})
				.then(() => {
					this.props.getRequests()
					this.props.setFangouts()
					this.setState({disputeModal: false, disputeConfirmation: true})
				})
				.catch(e => {
					this.setState({error: e.message})
				})
		}
	}

	openDisputeModal = e => {
		e.stopPropagation()
		this.setState({disputeModal: true})
	}

	openDisputeHelpModal = e => {
		e.stopPropagation()
		this.setState({disputeHelpModal: true})
	}

	render() {
		const fangout = this.props.f
		const lang = this.props.lang
		const sender = this.props.toMe ? fangout.fan : fangout.celebrity
		const requestByFanToMe = this.props.toMe && fangout.createdByFan
		const requestByFanFromMe = this.props.fromMe && fangout.createdByFan
		const price = fangout.price
		const createdByFan = fangout.createdByFan
		const toMe = this.props.toMe
		const fromMe = this.props.fromMe

		const fanHasPaid = fangout.status === foutStatus.CONFIRMED && price && toMe

		const isExpanded = this.state.expanded || (this.props.expanded && this.props.expanded[fangout.id])

		// console.log('fangout', fangout)
		// console.log('createdByFan', createdByFan)
		// console.log('TOME', toMe)
		// console.log('requestByFanToMe', requestByFanToMe)
		// console.log('requestByFanFromMe', requestByFanFromMe)
		// console.log('price', price)

		return <Fangout
			id={this.props.id}
			key={fangout.id}
			// style={{
			// 	height: this.state.expanded ? 'auto' : '100px',
			// 	overflow: 'hidden'
			// }}
			onClick={(e) => this.expandAndSetUpdateInterval(e)}
		>
			{fangout.sponsor && <FoutName>{fangout.sponsor}</FoutName>}
			<Main>
				<div className={'relative'}>
					<img
						src={sender.profilePhoto || emptyAvatar}
						alt={sender.name}
						onClick={(e) => this.props.goToProfile(e, sender)}
						className={'userPhoto'}
					/>
				</div>
				<div className={'center'}
						 onClick={() => this.props.goToFangoutDetails && this.props.goToFangoutDetails(fangout)}>
					<div className={'name'}>{sender.name}</div>
					<div
						className={'location'}>{fangout.location && fangout.location.city}</div>
					<div>{price} {currencyMap[fangout.currency]}</div>
					<div className={'type'}>{lang[fangout.fangoutType]}</div>
				</div>
				<div className={'status'}>
					<Status>{fangout.status} {fanHasPaid && `& ${lang.fan_has_paid}`}</Status>
					<div className={'date'}>{moment(fangout.date).format('DD MMM YYYY')}</div>
					<div className={'date'}>{moment(fangout.date).format('HH:mm')}</div>
					<i className={`${isExpanded ? 'icon-arrow-up' : 'icon-drop_down'}`}/>
				</div>
			</Main>
			{isExpanded && <div>
				<Details className={'flex space-between'}>
					<div className={'row'}>
						{fangout.fangoutType === 'CASUAL' && <div>
							<div className={'title'}>{lang.fangouts_bill}</div>
							<div className={'text-center'}>{lang[fangout.billType]}</div>
						</div>}
						<div>
							<div className={'title'}>{lang.fangouts_fans}</div>
							<div className={'text-center'}>{fangout.numOfAttendees}</div>
						</div>
						<div>
							<div className={'title'}>{lang.label_duration}</div>
							<div className={'text-center'}>{fangout.duration}</div>
						</div>
					</div>
					{fangout.description && <FangoutDescription>{fangout.description}</FangoutDescription>}
				</Details>

				{fangout.status !== foutStatus.CONFIRMED && fangout.status !== foutStatus.DISPUTE &&
				<div className={'approveDiscard'}>
					<div className={'discard'}><i className={'fas fa-times'} onClick={(e) => {
						e.stopPropagation();
						this.props.confirmFangout(fangout, 1)
					}}/></div>
					{!createdByFan && price !== null && toMe &&
					<div className={'approve'}><i className={'fas fa-check'} onClick={(e) => {
						e.stopPropagation();
						this.props.confirmFangout(fangout, 0)
					}}/></div>}
					{requestByFanToMe && price === null && <div className={'setPriceOnRequest'}>
						<Input
							wrapperstyle={inputWrapperStyle}
							min={30}
							value={this.state.price}
							label={lang.label_set_price}
							type={'number'}
							icon={'fas fa-euro-sign'}
							descriptionPositionUp
							description={'(MIN 30)'}
							onClick={e => {
								e.stopPropagation();
								e.preventDefault()
							}}
							onChange={e => this.setState({price: e.target.value})}
						/>
						<button onClick={this.setPriceForRequest}>Set</button>
					</div>}
					{requestByFanToMe && price !== null &&
					<div className={'payForRequest grayItalicNote'}>{lang.price_is_set_note}</div>}

					{requestByFanFromMe && price !== null &&
					<div className={'payForRequest'}>
						<div
							className={'mb5 payForRequestTitle'}>{fangout?.celebrity?.name} {lang.has_set_the_price}</div>
						<button
							onClick={this.acceptAndPay}
						>
							{lang.pay} {price}{currencyMap[fangout.currency]}
						</button>
					</div>}
				</div>}

				{fangout.status === foutStatus.CONFIRMED && fromMe && <div className={'p016 mt1'}>
					<button onClick={(e) => {
						e.stopPropagation();
						this.props.completeFangout(fangout)
					}}>{lang.fangouts_complete}</button>
					<div className={'flex align-center mt1'}>
						<Dispute onClick={this.openDisputeModal}>Dispute</Dispute> <Helper
						onClick={this.openDisputeHelpModal}
						className={'fas fa-question-circle clickable mr1'}/>
						{moment().isAfter(moment(fangout.date)) && <CountdownDom>
							<Countdown
								date={moment(fangout.date).add(24, 'hour').toDate()}
								renderer={props => !props.completed ?
									<div>({lang.fout_autocompletion_label1} {props.hours}h {props.minutes}min)</div> :
									<div>({lang.fout_autocompletion_label2})</div>}
							/>
						</CountdownDom>}
					</div>
				</div>}

				{fangout.status !== foutStatus.DISPUTE ?
					<div className={'conversation'}>
						{this.state.conversation.length === 0 ?
							<div className={'noConversation'}>{lang.message_noConversation}</div> :
							this.state.conversation.sort(idAsc)
								.map(m => <div
									key={m.messageId}
									className={`messageContainer ${m.sender.id === this.props.userData.id ? 'right' : ''}`}>
									<div className={'message'}>{m.message}</div>
								</div>)
						}
						{this.state.error && <div className={'text-danger text-center'}>{this.state.error}</div>}
						<div style={{position: 'relative'}}>
							<Input
								placeholder={lang.message_placeholder}
								type={'text'}
								name={'message'}
								wrapperstyle={{marginTop: '16px'}}
								inputstyle={{paddingRight: '44px'}}
								value={this.state.message}
								onChange={e => this.setState({message: e.target.value})}
								onClick={(e) => e.stopPropagation()}
								onKeyPress={this.onKeyPress}
							/>
							<i className={`sendMessage fas ${this.state.sendingMessage ? 'fa-spinner fa-spin' : 'fa-paper-plane'}`}
								 onClick={(e) => {
									 e.stopPropagation()
									 this.sendMessage()
								 }}
							/>
						</div>
					</div> : <DisputeNotification>{lang.requests_dispute_notification}</DisputeNotification>}
			</div>}
			{this.state.disputeModal && <Portal>
				<Popup
					title={lang.requests_dispute_help_title}
					content={this.getDisputeContent()}
					submitAction={this.disputeFangout}
					submitText={lang.requests_dispute_submitAction}
					cancelAction={() => this.setState({disputeModal: false})}
					cancelText={lang.close}
					trackingTitle={'requests_dispute_modal'}
					position={'top'}
				/></Portal>}
			{this.state.disputeHelpModal && <Portal>
				<Popup
					title={lang.requests_dispute_help_title}
					content={lang.requests_dispute_help_content}
					cancelAction={() => this.setState({disputeHelpModal: false})}
					cancelText={lang.close}
					trackingTitle={'requests_help_dispute'}
				/>
			</Portal>}
			{this.state.disputeConfirmation && <Portal>
				<Popup
					title={lang.requests_dispute_help_title}
					content={lang.requests_dispute_confirmation}
					cancelAction={() => this.setState({disputeConfirmation: false})}
					cancelText={lang.close}
					trackingTitle={'requests_help_dispute'}
				/>
			</Portal>}
		</Fangout>
	}
}

function mapStateToProps(state) {
	return {
		lang: state.home.language,
		userData: state.profile.userData
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getRequests: () => getRequests(dispatch),
		setFangouts: () => setFangouts(dispatch)
	}
}


export default withTheme(withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestsFangoutComponent)))


export const Status = styled.span`
	color: ${props => props.children && (props.children.includes(foutStatus.CONFIRMED) ? props.theme.primary : props.theme.yellow)};
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;
	min-width: 80px;
	text-align: right;
`

export const Dispute = styled.div`
	color: ${({theme}) => theme.gray};
	margin-right: 10px;
	opacity: 0.8;
	display: inline-block;
	&:hover {
		opacity: 1;
	}
	&:active {
		opacity: 0.8;
	}
`

export const TextArea = styled.textarea`
	background: ${({theme}) => theme.secondBackground};
	color: ${({theme}) => theme.text};
	border: 1px solid transparent;
	border-radius: 4px;
	display: block;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	min-height: 100px;
	max-height: 100px;
	padding: 4px 8px;
	font-family: inherit;
	&:active, &:focus {
		border: 1px solid ${({theme}) => theme.primary};
	}
	&.error {
		border: 1px solid ${({theme}) => theme.red};
	}
`
const CountdownDom = styled.div`
	color: ${({theme}) => theme.yellow};
	font-size: 12px;
	`

const DisputeNotification = styled.div`
	color: ${({theme}) => theme.gray};
	text-align: center;
	`