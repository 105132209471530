import React from 'react'
import Switch from "react-switch";
import {useTheme} from "styled-components";

interface IFSwitch {
	checked: boolean
	onChange: any
}

function FSwitch(props: IFSwitch) {
	const {checked, onChange} = props
	const theme = useTheme()
	return <Switch
		onChange={onChange} checked={checked}
		className={'react-switch'}
		onColor={theme.primary}
		onHandleColor={theme.secondBackground}
		handleDiameter={22}
		height={24}
		checkedIcon={<i
			className={'fas fa-check'}
			style={{
				color: theme.secondBackground,
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		/>}
	/>
}

export default FSwitch