import React from 'react'
import Heading from '../../components/heading/heading'
	import GoBack from '../../components/goBack/goBack'

const NotificationsComponent = props => {
	return <div>
		<GoBack />
		<Heading title={'Notifications'} />
	</div>
}


export default NotificationsComponent
