import * as React from 'react'
import {useCallback, useRef, memo, useEffect, ReactElement} from "react";
import {
	TabsList,
	TabSelector as TabInkBar,
	TabGroupContainer,
	TabPane as TabsNavbar,
	ViewPane,
	TabBarStyle
} from "./styles";
import {SwipeableViews} from "./SwipeableTabs";
import {TabHeader, TabProps} from "./Tab";

type TabGroupProps = {
	value: string;
	styleProps?: TabBarStyle;
	onChange: (selectedTab: { label: string | any; key: string | number }) => void;
	children: Array<React.FunctionComponentElement<TabProps>>;
	tabBarCSS?: string;
	tabItemCSS?: string;
	blacklistedElement?: {
		identifierType: "className" | "id" | "nodeName";
		identifierName: string;
	};
};

const TabGroup: React.FC<TabGroupProps> = ({
																						 children = [],
																						 value,
																						 onChange,
																						 styleProps = {},
																						 tabBarCSS = "",
																						 tabItemCSS = "",
																						 blacklistedElement = undefined
																					 }) => {
	const inkBarRef = useRef<HTMLHRElement>(null);
	const tabLabels = useRef<Array<any>>([]);
	const handleSwipe = useCallback(
		(selectedTab: number) => {
			onChange({
				label: tabLabels.current[selectedTab],
				key: children[selectedTab].key || selectedTab
			});
		},
		[tabLabels, children, onChange]
	);
	const handleTabClick = useCallback(
		(tabName: string | ReactElement, tabKey: number | string) => () => {
			// const tabIndex = tabLabels.current.indexOf(tabName); // do not use tabIndex any momre because tabName may not be a string
			onChange({label: tabName, key: tabKey});
			if (!inkBarRef.current) return;
			const inkBarStyle = inkBarRef.current.style;
			const ind: number = +tabKey
			inkBarStyle.transition = "none";
			inkBarStyle.marginLeft = `${(ind / children.length) * 100}%`; // instead ind, tabIndex was used before when title was string
			inkBarStyle.transition = "0.1s ease-in-out";
		},
		[onChange, children, inkBarRef]
	);

	useEffect(() => {
		tabLabels.current = children.map(child => child.props.label);
	}, [children]);

	/** Verify that valid <Tab/> children are being passed */
		// useEffect(() => {
		//   if (children.some(child => !(child.type === Tab))) {
		//     const error = new Error("TabsGroup children need to be of <Tab> type");
		//     error.name = "Invalid Children";
		//     throw error;
		//   }
		// }, [children]);

		// Create children map depending on value type so it can be used later

	let selected: number = 0
	children.forEach(item => {
		if (item.key == value) {
			selected = parseInt(value)
		}
	})
	return (
		<TabGroupContainer styleProps={styleProps}>
			<TabsNavbar styleProps={styleProps} tabBarCSS={tabBarCSS}>
				<TabsList styleProps={styleProps}>
					{children.map((child, index) => (
						<TabHeader
							index={child.key || index}
							onClick={handleTabClick(child.props.label, index)}
							width={100 / children.length}
							label={child.props.label}
							isSelected={value == child.key}
							// key={child.props.label}
							key={index}
							styleProps={styleProps}
							tabItemCSS={tabItemCSS}
						/>
					))}
				</TabsList>
				<TabInkBar
					selectedTab={selected}
					tabCount={children.length}
					ref={inkBarRef}
					styleProps={styleProps}
				/>
			</TabsNavbar>
			<ViewPane>
				<SwipeableViews
					views={children}
					onSwipe={handleSwipe}
					selectedView={selected}
					inkBarRef={inkBarRef}
					blacklistedElement={blacklistedElement}
					// selectedTabName={value}
					// tabLabels={tabLabels}
				/>
			</ViewPane>
		</TabGroupContainer>
	);
};

export const Tabs = memo(TabGroup);
