import { useQuery } from '@tanstack/react-query';
import style from './Giveaway.module.scss';
import { fetchShowsQueryFn } from './giveawayService';
import Spinner from 'src/components/Spinner/Spinner';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { colourStyles } from 'src/pages/Login/utils';
import { IShow } from 'src/pages/createShow/createShow';
import { useMemo, useState } from 'react';
import { Label } from 'src/styling/globalStyling';
import Input from 'src/components/input/input';
import config from '../../../../config';
import axios from 'axios';
import toast from 'react-hot-toast';

type PostData = {
  showId: number;
  ticketList: { numberOfTickets: number; ticketTypeId: number }[];
  receiverUserId?: number;
};

const Giveaway = () => {
  const theme = useTheme();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [userId, setUserId] = useState<number | null>(null);

  const { data: shows, isLoading: isLoadingShows } = useQuery<any>(
    ['shows'],
    () => fetchShowsQueryFn(),
    {
      select: (response) => response?.data?.content,
    }
  );
  const showsSelectOptions = useMemo(
    () => shows?.map((show) => ({ label: show.showTitle, value: show })),
    [shows]
  );

  const selectStyles = colourStyles(theme);
  const [selectedShow, setSelectedShow] = useState<{
    label: string;
    value: IShow;
  }>();

  const [ticketListObj, setTicketListObj] = useState({});

  const setTicket = (id, value) => {
    const newObj = { ...ticketListObj, [id]: value };
    if (!value) {
      delete newObj[id];
    }
    setTicketListObj(newObj);
  };

  const createTickets = async () => {
    setError('');
    setLoading(true);
    if (selectedShow) {
      const data: PostData = {
        showId: selectedShow.value.id!,
        ticketList: Object.keys(ticketListObj).map((key) => ({
          numberOfTickets: ticketListObj[key],
          ticketTypeId: parseInt(key),
        })),
      };
      try {
        if (userId) {
          data.receiverUserId = userId;
          // Create ticket for specific user
          const url = config.baseApi + '/v1/admin/giveaway_for_user';
          await axios.post(url, data);
        } else {
          // Create ticket for admin
          const url = config.baseApi + '/v1/admin/generate_giveaway';
          await axios.post(url, data);
        }
        toast.success('Tickets successfully created');
        setTicketListObj({});
        setUserId(null);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={style.giveaway}>
      {isLoadingShows && <Spinner />}
      <h3 className={style.title}>Generate gratis ticket</h3>
      {shows && (
        <div className={style.form}>
          <div className={style.flexColumn}>
            <Label className='noMarginTop'>Select the event:</Label>
            <Select
              styles={selectStyles}
              value={selectedShow}
              options={showsSelectOptions}
              // defaultValue={getTypeValues(lang)[0]}
              onChange={(e) => setSelectedShow(e)}
            />
            <Input
              value={userId}
              label={'User ID'}
              type={'number'}
              description={'If empty ticket goes to Admin'}
              min={1}
              onChange={(e) => setUserId(e.target.value || null)}
            />
          </div>

          <div className={style.tickets}>
            {selectedShow &&
              selectedShow.value.ticketTypes.map((ticketType) => (
                <Input
                  key={ticketType.id}
                  label={`${ticketType.title} - ${ticketType.ticketPrice}${selectedShow?.value.currency}`}
                  type={'number'}
                  min={0}
                  value={ticketListObj?.[ticketType.id!] || 0}
                  onChange={(e) =>
                    setTicket(ticketType.id, parseInt(e.target.value))
                  }
                  wrapperstyle={{ marginTop: 0 }}
                />
              ))}
          </div>
        </div>
      )}
      <button
        className={style.button}
        disabled={
          loading || !selectedShow || Object.keys(ticketListObj).length === 0
        }
        onClick={createTickets}
      >
        Create tickets
      </button>
      {loading && <Spinner />}
      {error && <div className='text-danger text-center'>{error}</div>}
    </div>
  );
};
export default Giveaway;
