import config from '../../config'
import axios from 'axios'

class _AvailabilityService {
	url = config.baseApi + '/v1/availability'
	getAvailabilities(id = null) {
		let url = this.url
		return axios.get(url)
	}

	updateAvailabilities(data) {
		return axios.post(this.url, data)
	}

	getAvailabilitiesById(id) {
		let url = config.baseApi + '/v1/public/availability/' + id
		return axios.create().get(url)
	}
}

const AvailabilityService = new _AvailabilityService()

export default AvailabilityService