import axios from "axios";
import config from "../../config";
import * as constants from "../../constants";
import { forceLogout } from "../../App";

class authService {
  signup(data) {
    const url = config.baseApi + "/v1/auth/signup";
    return axios.post(url, data);
  }

  login(data) {
    const url = config.baseApi + "/v1/auth/login";
    return axios.post(url, data);
  }

  getAccessToken() {
    const sessionString = localStorage.getItem(constants.SESSION_KEY);
    if (typeof sessionString !== "string" || sessionString.length === 0) {
      return null;
    }
    const session = JSON.parse(sessionString);
    return session.accessToken;
  }

  getRefreshToken() {
    const sessionString = localStorage.getItem(constants.SESSION_KEY);
    if (typeof sessionString !== "string" || sessionString.length === 0) {
      return null;
    }
    const session = JSON.parse(sessionString);
    return session.refreshToken;
  }

  invalidateSession() {
    localStorage.removeItem(constants.SESSION_KEY);
  }

  refreshAccessToken(refreshToken = this.getRefreshToken()) {
    const url = `${config.baseApi}/v1/auth/token`;
    const instance = axios.create({
      baseURL: config.baseApi,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return instance
      .post(url, { refreshToken })
      .then((res) => {
        const session = res.data;
        localStorage.setItem(constants.SESSION_KEY, JSON.stringify(session));
        return res;
      })
      .catch((e) => {
        forceLogout();
        return Promise.reject(e);
      });
  }

  checkUsername(username) {
    const url = config.baseApi + "/v1/auth/username_available";
    return axios.post(url, { username });
  }

  checkEmail(email) {
    const url = config.baseApi + "/v1/auth/email_available";
    return axios.post(url, { email });
  }

  verifyAccount(token) {
    const url = config.baseApi + "/v1/auth/verify_account";
    return axios.post(url, { activationToken: token });
  }

  requestResetPassword(usernameOrEmail) {
    const url = config.baseApi + "/v1/auth/reset_password";
    return axios.post(url, { usernameOrEmail });
  }

  resetNewPassword(data) {
    const url = config.baseApi + "/v1/auth/reset_password/set_new";
    return axios.post(url, data);
  }

  /*
  "contactInformationType": "GOOGLE",
  "referredBy": "string",
  "value": "string"
	*/
  socialLogin(data) {
    const url = config.baseApi + "/v1/auth/social_login";
    return axios.post(url, data);
  }
}

export default new authService();
