import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { Container, Link, TextContainer } from 'src/styling/globalStyling';

const PolitikaPrivatnosti = () => {
  return (
    <Container>
      <GoBack />
      <Heading title='Politika privatnosti' />
      <TextContainer>
        <p>
          <em>Shodno odredbi člana 23 Zakona o zaštiti podataka</em>{' '}
          <em>o ličnosti </em>
          <em>("</em>
          <em>Sl. glasnik RS</em>
          <em>"</em>
          <em>, br. 87/2018</em>
          <em>, </em>
          <em>u daljem &nbsp;&nbsp;&nbsp;&nbsp;</em>
          <em>tekstu: </em>
          <em>"</em>
          <em>Zakon</em>
          <em>"</em>
          <em>) i shodno Opštoj uredbi o zaštiti podataka br. 20 76/679</em>
          <em>, </em>
          <em>dalje:</em>
        </p>
        <p>
          <em>(,,</em>
          <em>GDPR</em>
          <em>") </em>
          <em>Rukovalac Nišville Fondacija Niš</em>
          <em>, </em>
          <em>matični broj:</em>
          <strong>
            <em> 1766063</em>
          </strong>
          <em>, </em>
          <em>PIB:</em>
          <strong>
            <em> 104623652</em>
          </strong>
          <em>, </em>
          <em>čiji je &nbsp;</em>
          <em>zakonski zastupnik Ivan Blagojević, direktor, (dalje</em>
          <em>: "</em>
          <em>Rukovalac</em>
          <em>"</em>
          <em>), izdaje sledeće</em>
          <em>:</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>OBAVEŠTENJE O OBRADI PODATAKA O LIČNOSTI</strong>
        </p>
        <p>
          <em>Svrha ovog Obaveštenja je da Rukovalac</em>
          <em>, </em>
          <em>
            osigura ostvarivanje i zaštitu prava na privatnost lica koja su
            izvršila kupovinu ulaznica za događaje kod Rukovaoca:{' '}
            <strong>("kupci/korisnici</strong>
          </em>
          <strong>
            <em>'')</em>
          </strong>
          <strong>
            <em>, </em>
          </strong>
          <em>kao i da </em>
          <em>ih informiše </em>o{' '}
          <em>njihovim pravima u vezi sa obradom podataka </em>o{' '}
          <em>ličnosti.</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>Podaci o ličnosti koji se obrađuju</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          ime, prezime, adresa isporuke, broj telefona i Vaša e-mail adresa.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Svrha nameravane obrade podataka i pravni osnov za obradu podataka
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Podaci se obrađuju u svrhu zaključenja i izvršenja ugovora o prodaji
          ulaznica za događaj, kao i u svrhu slanja obaveštenja o događajima
          (npr. promena termina održavanja, otkazivanje i sl.)
        </p>
        <p>&nbsp;</p>
        <p>
          Pravni osnov obrade jeste: obrada je neophodna za izvršenje ugovora
          zaključenog sa licem na koje se podaci odnose ili za preduzimanje
          radnji, na zahtev lica na koje se podaci odnose, pre zaključenja
          ugovora.
        </p>
        <p>&nbsp;</p>
        <p>
          Davanje podataka o ličnosti kao ugovorna obaveza i neophodan uslov za
          zakljucenje ugovora.
        </p>
        <p>&nbsp;</p>
        <p>
          Predmetni podaci predstavljaju neophodan uslov za zaključenje ugovora
          o kupovini ulaznica za određeni događaj.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Obrađivač</strong> podataka
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Nišville Fondacija</p>
        <p>&nbsp;</p>
        <p>
          <strong>lznosenje</strong> <strong>podataka</strong>{' '}
          <strong>o licnosti u drugu drzavu ili me</strong>
          <strong>đ</strong>
          <strong>unarodnu organizaciju</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Podaci se ne izvoze iz Republike Srbije</p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>
            Rok čuvanja podataka i kriterijum za njegovo odredivanje
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Podaci se čuvaju najviše 10 godina.</p>
        <p>&nbsp;</p>
        <p>
          <strong>Prava lica čiji se podaci o ličnosti obrađuju</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Lice ima pravo pristupa, pravo na ispravku, dopunu, brisanje,
          ograničenje i prenosivost podataka, &nbsp;&nbsp;&nbsp;&nbsp;kao i
          pravo na prigovor i automatizovano donošenje pojedinačnih odluka.
        </p>
        <p>&nbsp;</p>
        <p>
          Lice ima pravo na opoziv pristanka u bilo koje vreme, pri čemu opoziv
          pristanka ne utiče na dopuštenost obrade pre opoziva.
        </p>
        <p>&nbsp;</p>
        <p>
          U slučaju opoziva pristanka, Rukovalac neće obrađivati podatke lica na
          koje se opoziv odnosi, osim ukoliko Rukovalac ima zakonsko ovlašćenje
          za obradu.
        </p>
        <p>&nbsp;</p>
        <p>
          Navedene zahteve Lica dostavljaju e-mailom na e-mail adresu
          tickets@nisville.com.
        </p>
        <p>&nbsp;</p>
        <p>
          Rukovalac će na svaki zahtev odgovoriti u najkraćem mogućem roku, a u
          svakom slučaju najkasnije &nbsp;u roku od 30 dana od prijema zahteva u
          skladu sa članom 21 Zakona i članom 12 GDPR.
        </p>
        <p>&nbsp;</p>
        <p>
          Lice može podneti pritužbu u vezi sa obradom svojih podataka o
          ličnosti pred Poverenikom za &nbsp;informacije od javnog značaja i
          zaštitu podataka o ličnosti.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <em>
              Davanje podataka o ličnosti kao ugovorna obaveza i neophodan uslov
              za zaključenje ugovora
            </em>
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Tehničke i organizacione mere radi zaštite podataka o ličnosti
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Rukovalac i obrađivač su sproveli tehničke i organizacione mere
          zaštite radi sigurnosti i integriteta podataka o ličnosti, zaštite
          podataka od neovlašcenih pristupa, zlonamernih postupaka, gubitka ili
          otkrivanja podataka neovlašćenim trećim licima.
        </p>
        <p>&nbsp;</p>
        <p>
          Prihvatanjem ovog Obaveštenja lice je&nbsp; svesno i saglasano da se
          njegovi podaci o ličnosti naveden i u ovom Obaveštenju, obrađuju od
          strane Rukovaoca i Obradivaca, kao i da navedenim podacima mogu
          pristupiti zaposlena i angažovana lica kod Rukovaoca i Obrađivača.
          Lica koja pristupaju podacima o ličnosti su obavezna da podatke čuvaju
          kao poverljive i na siguran način, a od njih se zahteva da koriste
          podatke o ličnosti samo u gore pomenute svrhe.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Rukovalac</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>Politika privatnosti</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Nišville Fondacija</p>
        <p>&nbsp;</p>
        <p>Bul Dr Zorana Đinđića 58a</p>
        <p>&nbsp;</p>
        <p>
          Delatnost i šifra delatnosti: 9499, Ostale usluge i delatnosti
          povezane s njima
        </p>
        <p>Maticni broj: 17660632</p>
        <p>PIB: 104623652</p>
        <p>
          Web adresa:{' '}
          <Link href='http://www.nisville.com'>www.nisville.com</Link>
        </p>
        <p>
          E-mail:{' '}
          <Link href='mailto:nisville@nisville.com'>nisville@nisville.com</Link>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Kriptovane porudžbine</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Svi Vaši lični podaci ce biti transferisani SSL (Secure Socket Layer)
          metodom u 128-bitnoj enkripciji (RSA s 1024bita) i zbog toga ne mogu
          biti iskorišćene od strane trećih osoba. Ovo je trenutno najnoviji
          standard sigurnosti na internetu.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Cookies / kolačići</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Kolačići su alfanumerički paketi informacija sa varijabilnim sadržajem
          koje šalje veb server koji se čuvaju na računaru korisnika i čuvaju u
          unapred određenom roku važenja. Upotreba kolačića omogućava da se
          ispituju neki podaci posetioca veb stranice i da se prati njihovo
          korisćenje na internetu. Kolačići pomažu da se prate interesovanja
          korisnika, obrasci upotrebe interneta i istorija poseta veb stranici
          kako bi se osiguralo da je iskustvo kupovine korisnika optimalno.
          Budući da se kolačići koriste kao vrsta oznake koja omogućava veb
          stranici da prepozna posetioca koji se vraća na stranicu, pomoću njih
          se takođe mogu sačuvati važeće korisnićko ime i lozinka za tu veb
          lokaciju. Ako pregledač pošalje prethodno sačuvani kolacic, provajder
          servisa koji obrađuje kolačić &nbsp;ima mogućnost povezivanja trenutne
          posete korisnika sa prethodnom, ali samo u vezi sa njihovim sopstvenim
          sadržajem.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          Informacije koje kolačići šalju olakšavaju prepoznavanje veb
          pretraživača, pa korisnici mogu primiti relevantan i
          &bdquo;personalizovan&ldquo; sadržaj. Kolačići čine pregledavanje
          praktičnijim, uključujući potrebe za bezbednošću podataka na mreži i
          relevantno oglašavanje. Uz pomoć kolačića, provajder servisa takođe
          može kreirati anonimne statistike o navikama gledalaca stranica, tako
          da može bolje prilagoditi izgled i sadržaj stranice.&nbsp;
        </p>
        <p>Veb lokacija dobavljača usluge koristi dve vrste kolačića:&nbsp;</p>
        <p>
          - Privremeni kolačići (<em>obavezni</em>)&ndash; kolačići za upotrebu
          sesije (session-id) neophodni za upotrebu veb stranice. Njihova
          upotreba je od suštinskog značaja za navigaciju i za funkcionisanje
          veb stranice. Bez njih, sajt ili njegovi delovi neće biti prikazani,
          pregledavanje postaje ometano, stavljanje ulaznica u korpu ili
          bankovno plaćanje ne mogu se pravilno sprovesti.&nbsp;
        </p>
        <p>
          - Trajni kolačići (<em>analitički i marketing/oglašavanje</em>
          )&nbsp; - koji će ostati na uređaju, ovisno o podešavanjima veb
          pretraživača, dugo ili dok ih korisnik ne obriše. Unutar njih postoje
          interni i eksterni kolačići. Interni kolačići se stvaraju ako server
          provajdera servisa instalira kolačić i podaci se proslede u njegovu
          sopstvenu bazu podataka. Ako je kolačić instalirao server provajdera
          servisa, ali se podaci prosleđuju spoljnom provajderu, koristi se
          spoljni kolačić. Kolačići treće strane koje je treća strana postavila
          u pregledač korisnika (Google Analitics, Facebook Pixel) su spoljni
          kolačići. Oni se stavljaju u pretraživač ako posećena veb lokacija
          koristi usluge koje pruža treća strana. Svrha stalnih kolačića je da
          se osigura da veb lokacija funkcioniše na najvišem nivou u cilju
          povećanja korisničkog iskustva.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          Prilikom posete veb stranici Korisnik može dati svoju saglasnost za
          čuvanje trajnih kolačića sačuvanih na svom računaru kojima može
          pristupiti provajder servisa klikom na dugme upozorenja o kolačićima
          na stranici za prijavu.&nbsp;
        </p>
        <p>
          Korisnik može konfigurisati i sprečiti aktivnosti vezane za kolačiće
          pomoću programa pretraživača, međutim, imajte na umu da je bez
          upotrebe kolačića moguće da korisnik neće moći da koristi svaku uslugu
          koju nudi veb lokacija, a naročito opcije plaćanja.&nbsp;
        </p>
        <p>
          Svrha obrade podataka: obavljanje platnih transakcija s pružateljem
          platnih usluga, identifikacija i razlikovanje korisnika,
          identifikacija korisnikove trenutne sesije, čuvanje podataka stvorenih
          tokom sesije, sprečavanje gubitka podataka, identifikacija i praćenje
          korisnika, veb analitika.&nbsp;
        </p>
        <p>
          Obim obrađenih podataka: identifikacioni broj, datum, vreme i
          prethodno posećena veb stranica.&nbsp;
        </p>
        <p>
          Period obrade podataka: privremeni kolačići se čuvaju dok se ne
          zatvore sve veb lokacije iste vrste.&nbsp;
        </p>
        <p>
          Trajni kolačići se čuvaju na računaru korisnika godinu dana ili dok ih
          korisnik ne izbriše.&nbsp;
        </p>
        <p>
          Moguće posledice neuspeha u pružanju podataka: nedostupnost određenih
          servisa veb stranice, neuspešne platne transakcije, netačnosti u
          analitici.
        </p>
        <p>
          &nbsp;
          <br /> <strong>E-Mail&nbsp;</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Nišville Fondacija koristi vašu e-mail adresu samo za postupak
          poručivanja ulaznica, kao i dostavljanja važnih obaveštenja o
          događajima za koji ste kupili ulaznicu (kao što su promena termina,
          otkazivanje događaja ili sl).&nbsp;
        </p>
        <p>
          Nišville Fondacija će vam obaveštenja o važnim poboljšanjima naše web
          stranice / webshopa / postupka kupovine dostavljati isključivo uz
          prethodno pribavljenu vašu saglasnost.
        </p>
        <p>
          Nišville Fondacija će oglasne poruke dostavljati isključivo uz
          prethodno pribavljenu vašu saglasnost .<br /> <br />{' '}
          <strong>Ugovor&nbsp;</strong>
          <br /> <br /> Naš online servis podrazumeva sporazumno korišćenje i
          čuvanje Vaših podataka. Sve izmene u našem poslovanju koje se odnose
          na bezbednost i poverljivost podataka biće objavljene na našoj web
          stranici.
        </p>
        <p>
          <strong>
            <br /> <strong>Zaštita ličnih podataka</strong>
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Registracija, prikupljanje i upotreba informacija</em>
        </p>
        <p>
          Tokom procesa kupovine / registracije skupljamo podatke kao što
          su&nbsp;
          <em>
            <strong>
              ime, prezime, adresa isporuke, broj telefona i Vaša e-mail
              adresa.&nbsp;
            </strong>
          </em>
          U slučaju kada se korisnik registruje putem Facebook ili Google
          naloga, ti podaci se koriste u skladu sa Facebook ili Google politikom
          privatnosti, sa kojom je korisnk saglasan. Vaše podatke koristićemo
          samo u svrhu izvršenja naše usluge i plaćanja. Ime, prezime i email
          adresa se ispisuju na e-ulaznici kako bi se sprečila moguća
          zloupotreba.
        </p>
        <p>
          Da biste primali naš newsletter, potrebna nam je Vaša e-mail adresa.
          Da biste dobili vesti, potrebno je da se prijavite na naš newsletter i
          potvrdite da ste saglasni da vam isti bude dostavljen putem mail-a.
          Poštujući Vašu privatnost, omogućili smo vam da se u svakom trenutku
          sami odjavite sa ove liste. Pogledajte odeljak "Odjavljivanje /
          isključivanje" ovog dokumenta. Nišville Fondacijaje ekskluzivni
          vlasnik podataka prikupljenih na web sajtu www.nisville.com
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Svrha nameravane obrade podataka</em>
        </p>
        <p>
          Svrha obrade podataka jeste izvršavanje ugovornih obaveza u vezi sa
          kupovinom ulaznica za događaj.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Pravni osnov obrade podataka</em>
        </p>
        <p>
          Pravni osnov obrade jeste: obrada je neophodna za izvršenje ugovora
          zaključenog sa licem na koje se podaci odnose ili za preduzimanje
          radnji, na zahtev lica na koje se podaci odnose, pre zaključenja
          ugovora.
        </p>
        <p>
          Davanje podataka o ličnosti kao ugovorna obaveza i neophodan uslov za
          zaključenje ugovora.
        </p>
        <p>
          Predmetni podaci predstavljaju neophodan uslov za zaključenje ugovora
          o kupovini ulaznica za događaj.
        </p>
        <p>&nbsp;&nbsp;</p>
        <p>
          <em>Rok čuvanja podataka:</em>
        </p>
        <p>Podaci se čuvaju 10 godina.</p>
        <p>&nbsp;</p>
        <p>
          <em>Narudžbine</em>
        </p>
        <p>
          Morate uneti podatke o kontaktu (kao što su Vaše ime, adresa e-pošte,
          broj telefona i adresa isporuke) i informacije o plaćanju (kao što su
          broj platne kartice, datum roka i ime platne kartice ako se odlučite
          da platite platnom karticom).{' '}
          <em>
            Nišville Fondacija nema uvid o podacima vezanim za platnu karticu,
            ve
          </em>
          <em>ć</em>
          <em> se proces unosa tih podataka vr</em>
          <em>š</em>
          <em>i na platformi provajdera pla</em>
          <em>ć</em>
          <em>anja.</em>
        </p>
        <p>
          Mi ćemo koristiti ove informacije za izdavanje fakture i ispunjenje
          Vašeg naloga. U zavisnosti od ličnih podataka koje pružate, biće vam
          poslata faktura sa identifikacionim brojem kupovine i svim ostalim
          potrebnim informacijama. Ako dođe do problema pri popunjavanju Vašeg
          naloga, koristićemo te informacije da Vas kontaktiramo.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Odnosi s kupcima</em>
        </p>
        <p>
          Lične informacije koje unesete će nam pomoći da stupimo u kontakt sa
          Vama, odgovorimo na Vaša pitanja, pružimo usluge koje ste izabrali i
          upravljate nalogom. Ako je potrebno, kontaktiraćemo Vas putem e-maila
          ili telefona.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Profil</em>
        </p>
        <p>
          Sakupljamo informacije koje primamo putem kolačića i datoteka
          dnevnika, ali mi ne stvaramo nikakav "profil" Vaših preferencija.
          Povezaćemo Vaše lične podatke sa Vašim kupovinama, ali ih nećemo
          deliti sa trećim licima.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Ankete i takmičenja</em>
        </p>
        <p>
          Povremeno ćete imati priliku da učestvujete na takmičenjima ili
          anketama koje će biti objavljene na našoj web stranici. Ako odlučite
          da učestvujete, moraćete da unesete određene lične informacije.
          Učestvovanje u ovakvim događajima je potpuno dobrovoljno i sami
          odlučujete da li želite da unesete ove informacije. U većini slučajeva
          biće potrebni kontakt podaci (kao što su ime i adresa za isporuku) i
          demografske informacije (kao što je poštanski broj).
        </p>
        <p>
          Koristićemo ove informacije da informišemo pobednike i dodelimo
          nagrade, kontrolišemo posećenost sajta i ponudimo učesnicima unos na
          našoj e-mail listi. Nećemo otkriti lične informacije koje ćete uneti
          tokom takmičenja ili istraživanja trećim licima.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Korišćenje i objavljivanje podataka</em>
        </p>
        <p>
          U slučaju da bilo koja treća strana koja nije naš agent ili pružalac
          usluga, traži informacije, bićete obavešteni, a Vi ćete imati
          mogućnost da odlučite da li želite da date svoje lične podatke ovom
          klijentu.
        </p>
        <p>
          Banka&nbsp;<strong>prihvatilac plaćanja</strong>&nbsp;ne učestvuje u
          prikupljanju ovih ličnih podataka (npr. JMBG),&nbsp;
          <strong>niti se bavi obradom ovih podataka</strong>, te se svi podaci
          isključivo distribuiraju Organizatoru manifestacije.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Odricanje</em>
        </p>
        <p>
          Zadržavamo pravo otkrivanja Vaših ličnih podataka ako to zahteva zakon
          i ako verujemo da je obelodanjivanje neophodno za zaštitu naših prava
          i / ili za postupanje u skladu sa sudskom istragom, sudskim nalogom
          ili pravnim postupkom.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Odjavljivanje / isključivanje</em>
        </p>
        <p>
          Na zahtev za unos Vaših ličnih podataka, takođe imate mogućnost da
          "odbijete" upotrebu ovih informacija u određene svrhe.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Datoteke dnevnika</em>
        </p>
        <p>
          U skladu sa pravilima većine web lokacija, automatski prikupljamo
          određene informacije i čuvamo ih u log fajlovima. Ove informacije
          uključuju Internet protokol (IP) adrese, tip pretraživača, Internet
          Service Provider (ISP) stranica sa kojih ste pristupili sajtu i
          stranicu na koju se ide iz našeg sistema, datum i vreme pristupa, i
          tok podataka po kliku.
        </p>
        <p>
          Ova informacija, koja ne identifikuje pojedinačne korisnike, se
          koristi za analizu trendova, administraciju sajta, praćenje
          korisničkog kretanja i prikupljanje demografskih podataka o našim
          korisnicima uopšte.
        </p>
        <p>Ne povezujemo ove podatke sa ličnim podacima.</p>
        <p>
          <em>
            <br /> <em>Linkovi na druge stranice</em>
          </em>
        </p>
        <p>
          Ova stranica sadrži linkove na web lokacije koje nisu u vlasništvu ili
          kontrolisane od strane Nišville Fondacija&nbsp;Nismo odgovorni za
          politiku privatnosti ovih stranica. Preporučujemo da pročitate
          politiku privatnosti za svaku lokaciju koja prikuplja lične podatke i
          da budete pažljivi kada napustite našu web lokaciju. Naša politika
          privatnosti se odnosi samo na informacije koje sakupljamo na ovoj web
          lokaciji.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Pristup ličnim informacijama</em>
        </p>
        <p>
          Ako se Vaše lične informacije menjaju, možete ih ispraviti ili
          ažurirati unošenjem promena u polju "Korisnički nalog" ili putem
          e-maila tickets@nisville.com ili poslati pismo na Nišville Fondacija,
          Cara Dušana 15, Niš, 18000.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Sigurnost</em>
        </p>
        <p>
          Sigurnost Vaših ličnih podataka je važna za nas. Svi Vaši lični podaci
          će biti transferisani SSL (Secure Socket Layer) metodom u 128-bitnoj
          enkripciji (RSA s 1024bita) i zbog toga ne mogu biti iskorišćene od
          strane trećih osoba. Ovo je trenutno najnoviji standard sigurnosti na
          internetu. Nišville Fondacija je sertifikovan od strane AlphaSSL
          (https://www.alphassl.com/). Ovlašćeni overilac sertifikata garantuje
          siguran prenos Vaših podataka i autentičnost našeg servera.&nbsp;
        </p>
        <p>
          Za zaštitu Vaših ličnih podataka koristimo industrijski prihvaćene
          standarde, kako tokom samog prenosa tako i kada dođu do nas. Međutim,
          nikakav način slanja podataka preko Interneta ili elektronskog
          skladišta nije 100% siguran. Stoga, uprkos upotrebi opšte prihvaćenih
          standarda i metoda zaštite Vaših ličnih podataka, ne možemo
          garantovati njihovu apsolutnu sigurnost. Da biste saznali više o
          bezbednosti na našem sajtu možete nam poslati e-mail na
          tickets@nisville.com ili poštom na Nišville Fondacija, Cara Dušana 15,
          Niš, 18000.
        </p>
        <p>
          <em>
            <br /> <em>Promena u poslovanju</em>
          </em>
        </p>
        <p>
          Ako Nišville Fondacija prolazi kroz poslovne promene, kao što su
          spajanja, sticanja druge kompanije ili prodaje veći deo svoje imovine
          Vaši lični podaci će verovatno biti deo prodate imovine. Bićete
          obavešteni o bilo kakvoj većoj promeni vlasništva ili kontroli Vaših
          ličnih podataka putem e-pošte ili putem hitnog obaveštenja na našoj
          web lokaciji 30 dana pre promene.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Izmene Izjave o privatnosti</em>
        </p>
        <p>
          Ako odlučimo da promenimo našu politiku privatnosti, objavićemo ove
          izmene na našoj web lokaciji i na drugim sajtovima za koje smatramo da
          su prikladni. Ovo će vam pomoći da znate kako se sakupljaju i koriste
          podaci, kao i okolnosti pod kojima se mogu otkriti.
        </p>
        <p>
          Zadržavamo pravo izmene ove Izjave o privatnosti, tako da je redovno
          proveravate. Ovde ćete biti obavešteni o promenama naše politike
          privatnosti, ili putem e-pošte ili putem obaveštenja na našoj web
          stranici.&nbsp;
        </p>
        <p>
          <em>
            <br /> <em>Prava lica čiji se podaci obrađuju</em>
          </em>
        </p>
        <p>
          Lice ima pravo pristupa, pravo na ispravku, dopunu, brisanje,
          ograničenje i prenosivost podataka, kao i pravo na prigovor i
          automatizovano donošenje pojedinačnih odluka.
        </p>
        <p>
          Lice ima pravo na opoziv pristanka u bilo koje vreme, pri čemu opoziv
          pristanka ne utiče na dopuštenost obrade pre opoziva.
        </p>
        <p>
          U slučaju opoziva pristanka, Rukovalac neće obrađivati podatke Lica na
          koje se opoziv odnosi, osim ukoliko Rukovalac ima zakonsko ovlašćenje
          za obradu.
        </p>
        <p>
          Navedene zahteve Lica dostavljaju e-mailom na e- mail adresu
          tickets@nisville.com
        </p>
        <p>
          Rukovalac će na svaki zahtev odgovoriti u najkraćem mogućem roku, a u
          svakom slučaju najkasnije u roku od 30 dana od prijema zahteva u
          skladu sa članom 21 Zakona i članom 12 GDPR.
        </p>
        <p>
          Lice može podneti pritužbu u vezi sa obradom svojih podataka o
          ličnosti pred Poverenikom za informacije od javnog značaja i zaštitu
          podataka o ličnosti.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Kontaktirajte nas</em>
        </p>
        <p>
          Ako imate pitanja ili sugestije u vezi sa našom politikom privatnosti,
          možete nam poslati e-mail na tickets@nisville.com ili poštom na
          Nišville Fondacija, Cara Dušana 15, Niš, 18000.
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <em>Verzija: 1.0</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
      </TextContainer>
    </Container>
  );
};

export default PolitikaPrivatnosti;
