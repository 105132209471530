import languages from '../localization/languages'
// import english from '../localization/langFiles/english'
import srpski from '../localization/langFiles/srpski'

export const ActionTypes = {
	SET_LANGUAGE: 'SET_LANGUAGE',
	SET_LIGHT_THEME: 'SET_LIGHT_THEME',
	SET_IS_PULLABLE: 'SET_IS_PULLABLE',
	SET_TOP_POPULAR: 'SET_TOP_POPULAR',
	SET_TOP_ACTIVE: 'SET_TOP_ACTIVE',
	SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS'
}

const initialState = {
	language: (localStorage.getItem('language') && languages[localStorage.getItem('language')]) || srpski,
	lightTheme: localStorage.getItem('lightTheme') === 'true',
	isPullable: true,
	topPopular: new Array(8).fill(null),
	topActive: new Array(8).fill(null),
	subscriptions: {
		users: {},
		locations: []
	}
}

export default function homeReducer(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_LANGUAGE:
			localStorage.setItem('language', action.lang)
			return Object.assign({}, state, {language: languages[action.lang]})
		case ActionTypes.SET_LIGHT_THEME:
			localStorage.setItem('lightTheme', action.lightTheme)
			return Object.assign({}, state, {lightTheme: action.lightTheme})
		case ActionTypes.SET_IS_PULLABLE:
			return Object.assign({}, state, {isPullable: action.payload})
		case ActionTypes.SET_TOP_POPULAR:
			return Object.assign({}, state, {topPopular: action.payload})
		case ActionTypes.SET_TOP_ACTIVE:
			return Object.assign({}, state, {topActive: action.payload})
		case ActionTypes.SET_SUBSCRIPTIONS:
			return Object.assign({}, state, {subscriptions: action.payload})
		default:
			return state
	}
}


export function setLanguage(dispatch, lang) {
	dispatch({
		type: ActionTypes.SET_LANGUAGE,
		lang
	})
}


export function setLightTheme(dispatch, lightTheme) {
	dispatch({
		type: ActionTypes.SET_LIGHT_THEME,
		lightTheme
	})
}
