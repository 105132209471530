import React, {useEffect, useRef, useState} from 'react'
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker, SingleDatePicker, DayPickerRangeController} from 'react-dates';
import {Container, Label} from "../../styling/globalStyling";
import GoBack from "../../components/goBack/goBack";
import Heading from "../../components/heading/heading";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import styled, {useTheme} from 'styled-components'
import Switch from "react-switch";
import AvailabilityService from './availabilityService'
import {Hr} from "../profile/profile";
import {getLocationObject, handleLoadAddressOptions, sortAvByDate} from "../../utils";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {SubscribeLocationButton} from "../subscriptions/subscriptions";
import moment from "moment";
import {Unsubscribe} from "../publicProfile/publicProfile";
import {availDateFormat} from "../../constants";
import FSwitch from "../../components/Switch/FSwitch";
import {colourStyles} from "../Login/utils";


const Availability = () => {
	const history = useHistory()
	const theme = useTheme()
	const lang = useSelector(state => state.home.language)
	const [error, setError] = useState(false)

	const [isAvailable, setIsAvailable] = React.useState(false)
	const [availabilities, setAvailabilities] = React.useState([])

	const [startDate, setStartDate] = React.useState(null)
	const [endDate, setEndDate] = React.useState(null)
	const [focusedInput, setFocusedInput] = React.useState(null)

	// Location input
	const addressOptions = useRef([])
	const [addressObject, setAddressObject] = useState({label: '', value: null})
	const [addNewAvailButton, setAddNewAvailButton] = useState(false)

	const fetchAvailabilities = () => {
		AvailabilityService.getAvailabilities()
			.then(res => {
				setIsAvailable(!!res.data.isAvailable)
				setAvailabilities(res.data.availability?.sort(sortAvByDate))
			})
	}

	useEffect(() => {
		fetchAvailabilities()
	}, [])

	useEffect(() => {
		if (addressObject?.value?.address?.city) {
			setAddNewAvailButton(false)
		} else {
			setAddNewAvailButton(true)
		}
	}, [addressObject])

	const addAvailability = () => {
		setError('')
		if (!isAvailable) return
		const data = {
			start: startDate,
			end: endDate,
			location: getLocationObject(addressObject.value)
		}
		const av = {
			availability: availabilities ? [...availabilities, data] : [data],
			isAvailable
		}
		AvailabilityService.updateAvailabilities(av)
			.then((res) => {
				setAvailabilities(res.data.availability?.sort(sortAvByDate))
			})
			.catch(e => setError(e.message))
	}

	const saveIsAvailable = checked => {
		setError(false)
		const data = {
			availability: availabilities ? [...availabilities] : [],
			isAvailable: checked
		}
		AvailabilityService.updateAvailabilities(data)
			.then(res => {
				setIsAvailable(res.data.isAvailable)
			})
			.catch(e => {
				setError(e.message)
			})
	}

	const removeAvailability = index => {
		setError('')
		let newAvailabilities = [...availabilities]
		newAvailabilities.splice(index, 1)
		const data = {
			isAvailable,
			availability: newAvailabilities
		}
		AvailabilityService.updateAvailabilities(data)
			.then(res => {
				setAvailabilities(res.data.availability?.sort(sortAvByDate))
			})
			.catch(e => setError(e.message))
	}

	return (
		<Container className={'p016'}>
			<GoBack customAction={() => history.push('/profile')}/>
			<Heading title={lang.availability_title} help={[lang.availability_help]}/>
			<Content>
				<div className={'flex space-between align-center'}>
					<Label style={{marginTop: 0, marginBottom: 0}}>{lang.availability_isAvailable}</Label>
					<FSwitch
						onChange={(checked) => saveIsAvailable(checked)}
						checked={isAvailable}/>
				</div>
				{error && <div className={'text-danger text-center'}>{error}</div>}
				<Hr style={{width: '100%'}}/>
				<NewAvail className={`${!isAvailable && 'disabled'}`}>
					<div className={'inputs'}>
						<Label style={{marginTop: 0, marginBottom: '6px'}}>{lang.availability_newAvailability}</Label>
						<DateRangePicker
							startDate={startDate} // momentPropTypes.momentObj or null,
							startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
							endDate={endDate} // momentPropTypes.momentObj or null,
							endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
							onDatesChange={({startDate, endDate}) => {
								setStartDate(startDate);
								setEndDate(endDate)
							}} // PropTypes.func.isRequired,
							focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
							onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
							firstDayOfWeek={1}
							numberOfMonths={1}
							small={true}
							daySize={35}
							disabled={!isAvailable}
						/>
						<div style={{maxWidth: 201, marginTop: '6px'}}>
							<AsyncSelect
								id={'address'}
								// value={addressObject}
								onChange={location => setAddressObject(location)}
								loadOptions={handleLoadAddressOptions}
								defaultOptions={addressOptions.current}
								className={'address'}
								styles={colourStyles(theme)}
								cacheOptions
								isDisabled={!isAvailable}
								placeholder={lang.city + '...'}
							/></div>
					</div>
					<AddAvail
						className={`${(!isAvailable || !startDate || !endDate || addNewAvailButton) && 'disabled'} fas fa-plus-circle`}
						onClick={(e) => {
							e.stopPropagation()
							addAvailability()
						}}
					/>
				</NewAvail>
				<Hr style={{width: '100%'}}/>
				{availabilities && availabilities.length && <Label style={{marginTop: 0, marginBottom: '6px'}}>{lang.availability_title}</Label>}
				{availabilities?.map((av, i) => <AvailItem key={av.id}
																									className={`flex space-between align-center ${!isAvailable && 'disabled'}`}>
					<div>
						<div>{moment(av.start).format(availDateFormat)} - {moment(av.end).format(availDateFormat)}</div>
						<div>{av.location ? `${av.location.city} (${av.location.countryName})` : '' }</div>
					</div>
					<RemoveAvail
						disabled={!isAvailable}
						className={'fas fa-times'}
						onClick={() => removeAvailability(i)}
					/>
				</AvailItem>)}
			</Content>


		</Container>
	)
}

export default Availability

const Content = styled.div`
	display: flex;
	flex-direction: column;
`
const NewAvail = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	&.disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
`

const AvailItem = styled.div`
	background: ${({theme}) => theme.secondBackground};
	padding: 4px 12px;
	margin-bottom: 6px;
	border-radius: 4px;
	color: ${({theme}) => theme.lightGray};
	&.disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
`
const AddAvail = styled(SubscribeLocationButton)`
	font-size: 30px;
`
const RemoveAvail = styled(Unsubscribe)`
	margin-right: 0px;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
`