import axios from 'axios'
import config from '../../config'


class createFangoutService {
	createFangout(data) {
		const url = config.baseApi + '/v1/fangouts'
		return axios.post(url, data)
	}
	updateFangout(data, fId) {
		const url = config.baseApi + '/v1/fangouts/'+fId
		return axios.put(url, data)
	}
	deleteFangout(fId) {
		const url = config.baseApi + '/v1/fangouts/' + fId
		return axios.delete(url)
	}

	bookFangout(fangoutId) {
		const url = config.baseApi + '/v1/fangouts/requests/' + fangoutId
		return axios.put(url)
	}

	requestFangout(celebrityId, fangoutData) {
		const url = config.baseApi + '/v1/fangouts/create_fangout_request/' + celebrityId
		return axios.post(url, fangoutData)
	}

	setFangoutRequestPrice(fangoutId, price) {
		const url = config.baseApi + `/v1/fangouts/${fangoutId}/price`
		return axios.put(url, {"price": price})
	}

	getConversation(fangoutId, fromMessageId = -1) {
		const url = config.baseApi + `/v1/messenger/fangout/${fangoutId}/messages/${fromMessageId}`
		return axios.get(url)
	}
	sendMessage(message) {
		const url = config.baseApi + `/v1/messenger/send`
		return axios.post(url, message)
	}

	approveFangout(fangoutId, status) {
		const url = config.baseApi + `/v1/fangouts/accept_or_reject_or_complete/${fangoutId}/${status}`
		return axios.put(url)
	}

	completeFangout(fangoutId) {
		const url = config.baseApi + `/v1/fangouts/complete_fangout/${fangoutId}`
		return axios.put(url)
	}

	disputeFangout(fangoutId, message) {
		const url = config.baseApi + `/v1/fangouts/${fangoutId}/dispute`
		return axios.post(url, message)
	}
}


export default new createFangoutService()
