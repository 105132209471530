import config from "./config";
import axios from "axios";
import {getDeviceType} from "./utils";

class notificationsService {
	subscribeUser(data) {
		const url = config.baseApi + '/v1/push'
		return axios.post(url, data)
	}

	unsubscribeUser(accessToken) {
		const notif = localStorage.getItem('notification')
		if (notif) {
			try {
				const obj = JSON.parse(notif)
				const url = config.baseApi + '/v1/push'
				localStorage.removeItem('notification')
				const ax = axios.create()
				return ax.delete(url, {data: obj, headers: {'Authorization': 'Bearer ' + accessToken}})
			} catch (e) {
				return Promise.reject(e)
			}
		}
	}
}

export default new notificationsService()