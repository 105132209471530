import {ReqActionTypes} from './requestsActions'

const initialState = {
	requests: {
		toMe: [],
		fromMe: [],
		completed: []
	}
}

export function requestsReducer(state = initialState, action) {
	switch (action.type) {
		case ReqActionTypes.SET_REQUESTS:
			return Object.assign({}, state, {requests: action.requests})
		default:
			return state
	}
}

