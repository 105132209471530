export const darkTheme = {
  primary: '#dfc01b',
  // primary: "#00fff0",
  // primary: '#00cebd',
  // primary: '#ffb703',
  background: '#120a0c',
  // background: '#182023',
  secondBackground: '#303030',
  // secondBackground: '#1e2e33',
  contastBackground: '#f8f8f8',
  selectBackground: '#f8f8f8',
  selectOptionBgrSelected: '#d5edeb',
  selectOptionBgrFocused: '#e3f9f6',
  selectOptionText: '#ccc',
  selectOptionTextSelected: '#000',
  body: '#000',
  text: '#ffffff',
  buttonText: '#000',
  contrastText: '#000000',
  dangerBackground: '#301d28',
  red: '#ff0058',
  yellow: '#ffb100',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
  fontFamily: 'AvenirNext',
  gray: '#757575',
  middleGray: '#808080',
  lightGray: '#ccc',
  darkGreen: '#182023',
  secondaryButtonColor: '#1e2e33',
  secondaryButtonBackground: '#182023',

  // Semantic colors
  uploadImageBorder: '#384950',
  tabsBackgroundColor: 'rgb(31 33 35/.2)',
};

export const lightTheme = {
  primary: '#ceb41e',
  // primary: '#00cebd',
  background: '#fff',
  secondBackground: '#edf7f6',
  contastBackground: '#333',
  selectBackground: '#f8f8f8',
  body: '#fff',
  text: '#1e2e33',
  buttonText: '#1e2e33',
  contrastText: '#1e2e33',
  dangerBackground: '#301d28',
  red: '#ff0058',
  yellow: '#ffb100',
  gradient: 'linear-gradient(#39598A, #79D7ED)',
  fontFamily: 'AvenirNext',
  gray: '#757575',
  selectOptionBgrSelected: '#d5edeb',
  selectOptionBgrFocused: '#e3f9f6',
  selectOptionText: '#ccc',
  selectOptionTextSelected: '#000',
  middleGray: '#808080',
  lightGray: '#ccc',
  darkGreen: '#182023',
  secondaryButtonColor: '#1e2e33',
  secondaryButtonBackground: '#fff',

  // Semantic colors
  uploadImageBorder: '#384950',
  tabsBackgroundColor: 'transparent',
};
