import ReactGA from 'react-ga4';

// Initialize GA4
export const initGA = () => {
  ReactGA.initialize('G-BFCH6B8LMR');
};

// Track page views
export const trackPageView = (path: string) => {
  // GA4 page view
  ReactGA.send({ hitType: "pageview", page: path });

  // Matomo page view
  const _paq = window._paq;
  if (_paq) {
    _paq.push(['setCustomUrl', path]);
    _paq.push(['trackPageView']);
  }
};

// Track events
export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
  // GA4 event
  ReactGA.event({
    category,
    action,
    label,
    value,
  });

  // Matomo event
  const _paq = window._paq;
  if (_paq) {
    _paq.push(['trackEvent', category, action, label, value]);
  }
};

declare global {
  interface Window {
    _paq: any[];
  }
}
