import React, { useEffect, useState } from 'react';
import GoBack from '../../components/goBack/goBack';
import Heading from '../../components/heading/heading';
import {
  Button,
  Container,
  Label as _Label,
  Flex,
} from '../../styling/globalStyling';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Portal from '../../components/portal/portal';
import Popup from '../../components/popup/popup';
import styled from 'styled-components';
import Input from '../../components/input/input';
import { Hr } from '../profile/profile';
import myAgentService from './myAgentService';
import FSwitch from '../../components/Switch/FSwitch';
import Spinner from '../../components/Spinner/Spinner';
import toast from 'react-hot-toast';

const wrapperStyle = {
  marginTop: '0px',
  marginLeft: '30px',
  maxWidth: '116px',
};
const MAX_AUDIENCE = 'maxAudience';
const MAX_DURATION = 'maxDuration';
const FIRST_HOUR = 'firstHour';
const NEXT_HOUR = 'nextHour';

const MyAgent = () => {
  const history = useHistory();
  const lang = useSelector((state: any) => state.home.language);
  const [helpModalContent, setHelpModalContent] = useState('');
  const [error, setError] = useState('');
  const [spinner, setSpinner] = useState(false);

  const [isOn, setIsOn] = useState(false);

  const [service, setService] = useState({
    maxAudience: 1,
    maxDuration: 1,
    hours: [300],
  });
  const [education, setEducation] = useState({
    maxAudience: 1,
    perHour: 30,
  });
  const [casual, setCasual] = useState({
    maxAudience: 1,
    maxDuration: 1,
    firstHour: 100,
    nextHour: 50,
  });

  useEffect(() => {
    myAgentService.getMyAgent().then((res) => {
      if (res.data) {
        let { isOn, service, casual, education } = res.data;
        setIsOn(!!isOn);
        setService(service);
        setCasual(casual);
        setEducation(education);
      } else {
        setIsOn(false);
      }
    });
  }, []);

  const updateMyManager = () => {
    const obj = {
      service,
      education,
      casual,
      isOn,
    };

    setError('');
    setSpinner(true);
    myAgentService
      .updateMyAgent(obj)
      .then((res) => {
        toast.success('Successfully updated.');
      })
      .catch((e) => setError(e.message))
      .finally(() => setSpinner(false));
  };

  const createHelpContent = () => {
    return (
      <div className={'mt1'}>
        <div>{helpModalContent}</div>
        <div className={'mt1'}>{lang.myagent_help_additinal}</div>
      </div>
    );
  };

  const handleServiceChange = (e, i = -1) => {
    try {
      const name = e.target.name;
      const value = parseInt(e.target.value);
      const obj = { ...service };
      if (name === MAX_AUDIENCE) {
        obj.maxAudience = value;
      } else if (name === MAX_DURATION) {
        obj.maxDuration = value;
      } else if (i !== -1) {
        obj.hours[i] = value;
      }
      setService(obj);
    } catch (e) {
      console.log('handleServiceChange - error - ', e);
    }
  };

  const addNextHour = () => {
    const srvs = { ...service };
    srvs.hours = [...srvs.hours, 100];
    setService(srvs);
  };

  const removeNextHour = () => {
    const srvs = { ...service };
    if (srvs.hours.length > 1) {
      srvs.hours.pop();
      setService(srvs);
    }
  };

  const handleEducationChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newEducation = { ...education };
    if (name === MAX_AUDIENCE) {
      newEducation.maxAudience = value;
    } else {
      newEducation.perHour = value;
    }
    setEducation(newEducation);
  };

  const handleCasualChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newCasual = { ...casual };
    if (name === MAX_AUDIENCE) {
      newCasual.maxAudience = value;
    } else if (name === MAX_DURATION) {
      newCasual.maxDuration = value;
    } else if (name === FIRST_HOUR) {
      newCasual.firstHour = value;
    } else if (name === NEXT_HOUR) {
      newCasual.nextHour = value;
    }

    setCasual(newCasual);
  };

  const getHourString = (i) => {
    if (i === 0) {
      return `${i + 1}${lang.myagent_h_first} ${lang.myagent_h}`;
    } else if (i === 1) {
      return `${i + 1}${lang.myagent_h_second} ${lang.myagent_h}`;
    } else if (i === 2) {
      return `${i + 1}${lang.myagent_h_third} ${lang.myagent_h}`;
    } else if (i > 1) {
      return `${i + 1}${lang.myagent_h_n} ${lang.myagent_h}`;
    }
    return '';
  };

  return (
    <MyAgentContainer className={'p016 pb200'}>
      <GoBack customAction={() => history.push('/profile')} />
      <Heading title={lang.myagent_title} help={[lang.myagent_title_help]} />
      {spinner && <Spinner fullscreen />}
      <div style={{ fontSize: '12px' }}>{lang.myagent_title_help}</div>
      <div className={'flex align-center mt1	space-between'}>
        <div className={'flex align-center'}>
          <Label style={{ marginTop: 0, marginBottom: 0, minWidth: '100px' }}>
            {isOn ? lang.myagent_on : lang.myagent_off}
          </Label>
          <FSwitch checked={isOn} onChange={setIsOn} />
        </div>
        <Button
          disabled={!isOn && spinner}
          className={'small'}
          onClick={updateMyManager}
        >
          {lang.label_save}
        </Button>
      </div>
      {error && <div className={'text-danger text-center mt1'}>{error}</div>}
      <Hr className={'mt1 mb1 full-width'} />
      <div className={`${!isOn && 'disabled'}`}>
        <BigLabel style={{ marginTop: 12 }}>
          {lang.PERFORMANCE}
          <HelpButton
            onClick={() => setHelpModalContent(lang.myagent_help_performance)}
            className={'fas fa-question-circle clickable'}
          />
        </BigLabel>
        <div className={'flex align-center mt1'}>
          <Label>{lang.myagent_max_audience}</Label>
          <Input
            value={service.maxAudience}
            type={'number'}
            name={MAX_AUDIENCE}
            descriptionPositionUp
            onChange={handleServiceChange}
            isvalid={service.maxAudience < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-users'}
          />
        </div>
        <div className={'flex align-center mt1'}>
          <Label>{lang.myagent_max_duration}</Label>
          <Input
            value={service.maxDuration}
            type={'number'}
            name={MAX_DURATION}
            descriptionPositionUp
            onChange={handleServiceChange}
            isvalid={service.maxDuration < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-clock'}
          />
        </div>
        {service?.hours.map((h: any, i: number) => (
          <div key={`hour-${i}`} className={'flex align-center mt1'}>
            <Label>{getHourString(i)}</Label>
            <Input
              value={service.hours[i]}
              type={'number'}
              descriptionPositionUp
              onChange={(e) => handleServiceChange(e, i)}
              isvalid={service.hours[i] < 0 ? 'error' : 'success'}
              wrapperstyle={wrapperStyle}
              icon={'fas fa-euro-sign'}
            />
          </div>
        ))}
        <Flex>
          <AddNextHour onClick={addNextHour} style={{ marginRight: 44 }}>
            <div>{lang.myagent_add_next_hour}</div>
            <i className={'icon-add'} />
          </AddNextHour>
          {service.hours.length > 1 && (
            <AddNextHour onClick={removeNextHour} className={'border-gray'}>
              <i
                className={'fas fa-minus flex align-center'}
                style={{ margin: 0 }}
              />
            </AddNextHour>
          )}
        </Flex>

        <Hr className={'mt1 mb1'} />

        <BigLabel>
          {lang.EDUCATION}
          <HelpButton
            onClick={() => setHelpModalContent(lang.myagent_help_education)}
            className={'fas fa-question-circle clickable'}
          />
        </BigLabel>
        <div className={'flex align-center mt1'}>
          <Label>Max audience</Label>
          <Input
            value={education.maxAudience}
            name={MAX_AUDIENCE}
            type={'number'}
            descriptionPositionUp
            onChange={handleEducationChange}
            isvalid={education.maxAudience < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-users'}
          />
        </div>
        <div className={'flex align-center mt1'}>
          <Label>{lang.myagent_h_per_person}</Label>
          <Input
            value={education.perHour}
            type={'number'}
            name={'hour'}
            descriptionPositionUp
            onChange={handleEducationChange}
            isvalid={education.perHour < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-euro-sign'}
          />
        </div>

        <Hr className={'mt1 mb1'} />

        <BigLabel>
          {lang.CASUAL}
          <HelpButton
            onClick={() => setHelpModalContent(lang.myagent_help_casual)}
            className={'fas fa-question-circle clickable'}
          />
        </BigLabel>
        <div className={'flex align-center mt1'}>
          <Label>{lang.myagent_max_audience}</Label>
          <Input
            value={casual.maxAudience}
            type={'number'}
            name={MAX_AUDIENCE}
            descriptionPositionUp
            onChange={handleCasualChange}
            isvalid={casual.maxAudience < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-users'}
          />
        </div>
        <div className={'flex align-center mt1'}>
          <Label>{lang.myagent_max_duration}</Label>
          <Input
            value={casual.maxDuration}
            type={'number'}
            name={MAX_DURATION}
            descriptionPositionUp
            onChange={handleCasualChange}
            isvalid={casual.maxDuration < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-clock'}
          />
        </div>
        <div className={'flex align-center mt1'}>
          <Label>
            1{lang.myagent_h_first} {lang.myagent_h}
          </Label>
          <Input
            value={casual.firstHour}
            type={'number'}
            name={FIRST_HOUR}
            descriptionPositionUp
            onChange={handleCasualChange}
            isvalid={casual.firstHour < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-euro-sign'}
          />
        </div>
        <div className={'flex align-center mt1'}>
          <Label>{lang.myagent_h_evry_next}</Label>
          <Input
            value={casual.nextHour}
            type={'number'}
            name={NEXT_HOUR}
            descriptionPositionUp
            onChange={handleCasualChange}
            isvalid={casual.nextHour < 0 ? 'error' : 'success'}
            wrapperstyle={wrapperStyle}
            icon={'fas fa-euro-sign'}
          />
        </div>
      </div>
      {helpModalContent && (
        <Portal>
          <Popup
            position={'top'}
            content={createHelpContent()}
            cancelAction={() => setHelpModalContent('')}
            cancelText={lang.close}
          />
        </Portal>
      )}
    </MyAgentContainer>
  );
};

export default MyAgent;

const MyAgentContainer = styled(Container)`
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  i {
    font-size: 18px;
  }
`;

const Label = styled(_Label)`
  margin: 0;
  min-width: 140px;
  font-weight: 500;
`;

const BigLabel = styled(Label)`
  font-size: 16px;
`;

const HelpButton = styled.i`
  font-size: 16px;
  margin-left: 5px;
`;

const AddNextHour = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 1rem;
  border: 1px solid ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.secondBackground};
  width: fit-content;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 1;
  }
  i {
    margin-left: 16px;
    font-size: 12px;
  }
  &.border-gray {
    border: 1px solid ${({ theme }) => theme.gray};
  }
`;
