export const ActionTypes = {
	SET_ACTIVE_TAB: 'SET_ACTIVE_TAB'
}

const initialState = {
	activeTab: 0
}

export function tabsReducer(state = initialState, action) {
	switch(action.type) {
		case ActionTypes.SET_ACTIVE_TAB:
			return Object.assign({}, state, {activeTab: action.payload})
		default:
			return state
	}
}


export function setActiveTab(dispatch, tab) {
	dispatch({
		type: ActionTypes.SET_ACTIVE_TAB,
		payload: tab
	})
}
