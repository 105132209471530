import React from 'react';
import GoBack from '../../components/goBack/goBack';
import Heading from '../../components/heading/heading';
import { Container } from '../../styling/globalStyling';
import { connect } from 'react-redux';
import { getMyFangouts } from './myFangoutsActions';
import FangoutComponent from '../../components/fangoutComponent/fangoutComponent';
import ReactGA from 'react-ga4';
import { AddFangout, Fab } from '../../components/tabs/tabs';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

class MyFangouts extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      expanded: {},
    };
  }

  componentDidMount() {
    ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });

    !this.props.myFangouts.length && this.props.getMyFangouts();
  }

  expandFangout = (e, id) => {
    e.stopPropagation();
    this.setState({
      expanded: Object.assign({}, this.state.expanded, {
        [id]: !this.state.expanded[id],
      }),
    });
  };

  goToProfile = (e, celebrity) => {
    e.stopPropagation();
    this.props.history.push({
      pathname: '/public/' + celebrity.id,
      user: celebrity,
    });
  };

  goToFangoutDetails = (f) => {
    this.props.history.push({
      pathname: '/fangout/' + f.id,
      state: { fangout: f },
    });
  };

  render() {
    return (
      <Container>
        <GoBack />
        <Heading title={this.props.lang.label_my_fangouts} />
        <Container className={'p016'}>
          <div>
            {/*{this.props.myFangouts.filter(f=>f.fun === null).map(f => <FangoutComponent*/}
            {this.props.myFangouts
              .filter((f) => !f.fan)
              .map((f) => (
                <FangoutComponent
                  f={f}
                  key={f.id}
                  expanded={this.state.expanded}
                  expandFangout={this.expandFangout}
                  goToProfile={this.goToProfile}
                  goToFangoutDetails={this.goToFangoutDetails}
                  withShare
                />
              ))}
          </div>
        </Container>
        <TopFab onClick={() => this.props.history.push('/newfangout')}>
          <AddFangout />
        </TopFab>
      </Container>
    );
  }
}

export const TopFab = styled(Fab)`
  bottom: unset;
  padding: 5px;
  top: 12px;

  i.icon-logo_glass {
    font-size: 14px;
    position: absolute;
    top: 4px;
    left: 6px;
  }

  i.icon-add {
    font-size: 10px;
    position: absolute;
    top: 6px;
    left: -2px;
  }
`;

function mapStateToProps(state) {
  return {
    lang: state.home.language,
    myFangouts: state.myFangoutsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMyFangouts: () => getMyFangouts(dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyFangouts)
);
