import styled from "styled-components";
import { IUserData } from "src/models";
import { ReactNode } from "react";

import emptyAvatar from "../../assets/images/empty-avatar.jpg";
const UserDetailsBean = ({
  user,
  topRight,
  onClick,
}: {
  user: Partial<IUserData>;
  topRight?: ReactNode;
  onClick: (e: any) => void;
}) => {
  return (
    <UserBeanComponent onClick={onClick}>
      <img src={user.profilePhoto || emptyAvatar} alt={"avatar"} />
      <div className={"details"}>
        <div className={"name"}>{user.name}</div>
        <div className={"profession"}>{user.profession}</div>
        <div className={"bio"}>{user.bio || ""}</div>
      </div>
      {topRight && <div className="topRight">{topRight}</div>}
    </UserBeanComponent>
  );
};

export default UserDetailsBean;

const UserBeanComponent = styled.div`
  position: relative;
  padding: 16px;
  display: flex;
  margin: 0 8px 12px 8px;
  border-radius: 8px;
  max-height: 140px;
  background-color: ${({ theme }) => theme.secondBackground};
  gap: 16px;
  img {
    height: 90px;
    width: 90px;
    border-radius: 8px;
  }
  .details {
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
  }
  .profession {
    color: ${({ theme }) => theme.middleGray};
  }
  .bio {
    font-size: 12px;
  }
  .topRight {
    position: absolute;
    top: 16px;
    right: 0px;
  }
`;
