import React from "react";
import Spinner from "../Spinner/Spinner";
import PullToRefresh from "react-simple-pull-to-refresh";
// import {useHistory} from "react-router-dom";
import styled from 'styled-components'

const PullingContent = styled.div`
	text-align: center;
	padding-top: 15px;
	width: 100%;
`
const pullingContent = <PullingContent>Pull to refresh</PullingContent>

export const PTR = props => {

	// const history = useHistory()
	const handleRefresh = () => {
		const promise = new Promise((resolve) => {
			// history.go(0)
			window.location.reload()
			resolve()
		})
		return promise
	}


	return <PullToRefresh
		onRefresh={props.onRefresh || handleRefresh}
		refreshingContent={<Spinner style={{marginTop: '25px'}}/>}
		isPullable={props.isPullable}
		pullDownThreshold={300}
		pullingContent={pullingContent}
		maxPullDownDistance={300}
	>
		{props.children}
	</PullToRefresh>
}