import React from 'react'
import {currencyMap} from '../../constants'
import moment from 'moment'
import {connect} from 'react-redux'
import styled from 'styled-components'
import emptyAvatar from '../../assets/images/empty-avatar.jpg'
import config from "../../config";
import {shareFeature} from "../../utils";
import {withRouter} from "react-router-dom";
import fangoutService from "../../pages/createFangout/createFangoutService";
import {getMyFangouts} from "../../pages/myFangouts/myFangoutsActions";
import Portal from "../portal/portal";
import Popup from "../popup/popup";
import {setFangouts} from "../../pages/fangouts/fangoutsActions";

export class FangoutComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			openDeleteConfirm: false,
			submitDisabled: false
		}
	}

	editFangout = f => {
		this.props.history.push({
			pathname: '/editfangout/' + f.id,
			state: f
		})
	}


	goToProfile = (e, celebrity) => {
		e.stopPropagation()
		this.props.history.push({pathname: '/public/' + celebrity.id, user: celebrity})
	}

	goToFangoutDetails = f => {
		this.props.history.push({pathname: '/fangout/' + f.id, state: {fangout: f}})
	}

	deleteFangout = () => {
		this.setState({error: '', submitDisabled: true})
		fangoutService.deleteFangout(this.props.f.id)
			.then(res => {
				this.props.getMyFangouts()
				this.props.setFangouts()
			})
			.catch(err => this.setState({error: err.message}))
			.finally(() => {
				this.setState({submitDisabled: false})
			})
	}

	render() {
		const fangout = this.props.f
		const isMyFangout = (fangout.celebrity?.id === this.props.userData?.id && !fangout.cereatedByFan) ||
			(fangout.fan?.id === this.props.userData?.id && fangout.cereatedByFan)
		const mdate = moment(fangout.date)
		const isInPast = moment().isAfter(mdate)
		const lang = this.props.lang
		const isHistory = this.props.isHistory
		const isMyFangoutsPage = this.props.location.pathname === '/myfangouts'
		return <Fangout key={fangout.id}>
			{fangout.sponsor && <FoutName>{fangout.sponsor}</FoutName>}
			<Main>
				<div className={'relative'}>
					<img
						src={fangout.celebrity.profilePhoto || emptyAvatar}
						alt={fangout.celebrity.name}
						onClick={(e) => this.props.goToProfile ? this.props.goToProfile(e, fangout.celebrity) :
							this.goToProfile(e, fangout.celebrity)}
						className={'userPhoto'}
					/>
				</div>
				<div className={'center'}
						 onClick={() => this.props.goToFangoutDetails ? this.props.goToFangoutDetails(fangout) : this.goToFangoutDetails(fangout)}>
					<div className={'name'}>{fangout.celebrity.name}</div>
					<div
						className={'location'}>{fangout.location && (fangout.location.city || 'Unknown')}</div>
					<div>{fangout.price} {currencyMap[fangout.currency]}</div>
					<div className={'type'}>{lang[fangout.fangoutType]}</div>
				</div>
				<div className={'status'}>
					{isMyFangout && isMyFangoutsPage &&
					<i className={'icon-remove'} onClick={() => this.setState({openDeleteConfirm: true})}/>}
					<div className={'date'}>{moment(fangout.date).format('DD MMM YYYY')}</div>
					<div className={'date'}>{moment(fangout.date).format('HH:mm')}</div>
					{isInPast && !isHistory && <div style={{marginTop: '-10px'}} className={'text-danger'}>Outdated</div>}
					<div className={'flex space-between fullWidth'} style={{fontSize: '16px'}}>
						{isMyFangout && <i className={'fas fa-pencil-alt'} onClick={() => this.editFangout(fangout)}/>}
						{this.props.withShare && <i className={'icon-share'}
																				onClick={() => shareFeature(`${config.baseApi + config.shareApi}/fangouts/${fangout.id}`)}/>}
						<i className={`${this.state.expanded ? 'icon-arrow-up' : 'icon-drop_down'}`}
							 onClick={() => {
								 this.setState({expanded: !this.state.expanded})
							 }}/>
					</div>
				</div>
			</Main>
			{this.state.expanded && <Details className={'flex space-between'}>
				<div className={'row'}>
					{fangout.fangoutType === 'CASUAL' && <div>
						<div className={'title'}>{lang.fangouts_bill}</div>
						<div className={'text-center'}>{lang[fangout.billType]}</div>
					</div>}
					<div>
						<div className={'title'}>{lang.fangouts_fans}</div>
						<div className={'text-center'}>{fangout.numOfAttendees}</div>
					</div>
					<div>
						<div className={'title'}>{lang.label_duration}</div>
						<div className={'text-center'}>{fangout.duration}</div>
					</div>
				</div>
				{fangout.description && <FangoutDescription>{fangout.description}</FangoutDescription>}
			</Details>}

			{this.state.openDeleteConfirm && <Portal>
				<Popup
					title={lang.delete}
					content={lang.confirm_are_you_sure}
					cancelAction={() => this.setState({openDeleteConfirm: false})}
					submitAction={this.deleteFangout}
					submitDisabled={this.state.submitDisabled}
					submitText={lang.delete}
					cancelText={lang.cancel}
					error={this.state.error}
				/>
			</Portal>}
		</Fangout>
	}
}

function mapStateToProps(state) {
	return {
		lang: state.home.language,
		userData: state.profile.userData
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getMyFangouts: () => getMyFangouts(dispatch),
		setFangouts: () => setFangouts(dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FangoutComponent))

export const FoutName = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: -4px 0 5px 0;
`
export const Fangout = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.secondBackground};
  margin-bottom: 16px;
  display: block;
  border-radius: 16px;
  padding: 12px 16px;
  cursor: pointer;

  .fangout-enter {
    height: 0;
  }

  .fangot-enter-active, .fangout-exit, .fangout-exit-active {
    height: auto;
    transition: all 200ms;
  }

  img {
    cursor: pointer;
    border-radius: 8px;
    width: 60px;
    height: 60px;
  }

  .flex {
    display: flex;
  }

  .space-between {
    justify-content: space-between;
  }

  .space-around {
    justify-content: space-around;
  }

  .fullWidth {
    width: 100%;
  }

  .center {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 12px;
    font-size: 16px;

    .name {
      font-weight: 600;
      width: fit-content;
    }

    .location {
      font-size: 12px;
      font-weight: 600;
    }

    .type {
      color: ${({theme}) => theme.lightGray};
      font-size: 14px;
    }
  }

  .status {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    i {
      cursor: pointer;
        // background: ${({theme}) => theme.primary};
      color: ${({theme}) => theme.primary};
      border-radius: 4px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      :active {
        opacity: 0.8;
      }

      &.icon-remove {
        color: ${({theme}) => theme.red};
        position: absolute;
        top: 6px;
        right: 12px;
      }
    }

    .date {
      margin-bottom: 3px;
      min-width: 80px;
      text-align: right;
    }
  }

  .approveDiscard {
    display: flex;
    margin: 16px -16px -16px;
    border-top: 1px solid ${({theme}) => theme.uploadImageBorder};

    .approve, .discard {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0;
      border-bottom: 1px solid ${({theme}) => theme.uploadImageBorder};

      i {
        cursor: pointer;
        background: ${({theme}) => theme.primary};
        color: ${({theme}) => theme.contrastText};
        border-radius: 100%;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        :active {
          opacity: 0.8;
        }
      }
    }

    .discard {
      border-right: 1px solid ${({theme}) => theme.uploadImageBorder};

      i {
        background: ${({theme}) => theme.red};
      }
    }

    .payForRequest {
      padding: 5px 16px 10px;
      justify-content: center;
      flex: 6 1;
      border-bottom: 1px solid ${({theme}) => theme.uploadImageBorder};
      display: flex;
      flex-direction: column;

      button {
        width: 180px;
        align-self: center;
      }
    }

    .setPriceOnRequest {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid ${({theme}) => theme.uploadImageBorder};
      align-items: center;
      padding-bottom: 10px;

      button {
        padding: 10px;
        width: 100px;
      }
    }

  }

  .conversation {
    border-top: 1px solid ${({theme}) => theme.uploadImageBorder};
    position: relative;
    margin: 16px -16px 0;
    padding: 16px;

    .noConversation {
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      color: ${({theme}) => theme.gray};

    }

    position: relative;

    i.sendMessage {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      :active {
        opacity: 0.8;
      }

      width: 44px;
      height: 44px;
    }

    input {
      background: ${({theme}) => theme.background};
    }

    .messageContainer {
      background: ${({theme}) => theme.background};
      padding: 4px 12px;
      border-radius: 4px;
      width: 75%;
      position: relative;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 400;

      &.right {
        margin-left: auto;
      }

      .cloud {
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid ${({theme}) => theme.background};
        width: 0;
        height: 0;
        position: absolute;
        left: -5px;
        bottom: 5px;

        &.right {
          right: -5px;
          border-left: 6px solid ${({theme}) => theme.background};
          border-right: 0;
          left: unset;
        }
      }
    }
  }

  .requestArrow {
    font-size: 12px;
    color: ${({theme}) => theme.primary};
    // margin: 0 auto;
  }

  .payForRequestTitle {
    text-align: center;
    font-size: 14px;
    font-style: italic;
    color: ${({theme}) => theme.gray};
  }

  .notificationMessage {
    text-align: center;
    font-size: 14px;
    font-style: italic;
    border: 1px solid ${({theme}) => theme.primary};
    border-radius: 4px;
    color: ${({theme}) => theme.lightGray}
  }

  .grayItalicNote {
    font-size: 14px;
    font-style: italic;
    color: ${({theme}) => theme.gray};
  }
`

export const Main = styled.div`
  display: flex;
`
export const Details = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    padding: 16px 16px 0 16px;
    margin: 16px -16px 0 -16px;
    border-top: 1px solid ${({theme}) => theme.uploadImageBorder};
      // border-bottom: 1px solid ${({theme}) => theme.uploadImageBorder};
  }

  .title {
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const FangoutDescription = styled.div`
  margin: 0 -16px;
  margin-top: 16px;
  font-size: 14px;
  border-top: 1px solid ${({theme}) => theme.uploadImageBorder};
	// border-bottom: 1px solid ${({theme}) => theme.uploadImageBorder};
  // margin-bottom: 16px;
  padding: 16px;
`
