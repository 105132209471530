import React from 'react'
import styled from 'styled-components'
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";


const Spinner = props => {
	return props.fullscreen ? <SpinnerFullscreen>
		<PulseLoader color={'#cccccc'} loading={true} size={15} />
	</SpinnerFullscreen> : <div className={`text-center ${props.className}`} style={{...props.style, width: '100%'}}>
	<i style={{display: 'inline-block', fontSize: props.fontSize || 20}} className={(props.icon || 'icon-logo_glass') + ' fout-spin'} />
	</div>
}

export default Spinner

const SpinnerFullscreen = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0,0,0,0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	backdrop-filter: blur(2px);
`
