import React from 'react'
import welcomeImage from '../../assets/images/welcomeImage.jpg'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'
import { useLang } from 'src/components/useLang'
import { Link } from 'src/styling/globalStyling'
import nisvilleLogo from '../../assets/images/nisville-white-logo.png'

const Welcome = props => {
	const history = useHistory()
	const lang = useLang()
	return <Wrapper>
		<img src={nisvilleLogo} alt="logo" className='icon-logo' />
		<div className={'subtitle'}>{lang.welcome_note}</div>
		<div className={'bottomBlock'}>
			<button onClick={() => history.push({pathname:'/register', state: props.location.state})}>{lang.register}</button>
			<div className={'bottomText'}>
				<span className={'text-medium'}>{lang.welcome_has_account}</span>{' '}
				<Link className={'text-bold underline'} onClick={() => history.push({pathname:'/login', state: props.location.state})}>{lang.login}</Link>
			</div>
		</div>
	</Wrapper>
}


export default Welcome


const Wrapper = styled.div`
	background: url(${welcomeImage}) no-repeat;
	background-size: cover;
	height: 100%;
	max-width: 600px;
	// margin: 0 -16px;
	display: flex;
	flex-direction: column;
align-items: center;
	padding: 0 32px;
	color: white;
	
	overflow-y: scroll;
	-webkit-scrollbar {
	  display: none;
	}
	-ms-overflow-style: none;
	::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}
	
	img {
	
		&.icon-logo {
			font-size: 270px;
			margin-top: auto;
			margin-bottom: -10px;
			justify-content: center;
			display: flex;
		}
	}
	.subtitle {
		text-align: center;
	}
	img {
		&.logo {
			margin-top: auto;
		}
		&.logoText {
			margin-top: 24px;
		}
	}
	.bottomBlock {
		margin-top: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		button {
			margin-top: 24px;
		}
		.bottomText {
			margin: 32px 0;
		}
	}
`
