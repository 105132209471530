import React from 'react'
import Heading from '../../components/heading/heading'
import Tabs from '../../components/tabs/tabs'
import LogoTitle from '../../components/logoTitle/logoTitle'
import GoBack from "../../components/goBack/goBack";

const Page404 = props => {
	return <div>
		<Heading title={LogoTitle()} />
		<GoBack />
		<div className={'text-center'}>404 - Wrong way</div>
		<Tabs active={4}/>
	</div>
}


export default Page404
