import axios from 'axios'
import config from '../../config'


class publicProfileService {
	getUserData(userId) {
		const url = config.baseApi + '/v1/public/profile/' + userId
		return axios.get(url)
	}

	getUserPosts(username) {
		// /v1/public/posts/user/{username}/{fromPostId}
		const url = config.baseApi + '/v1/public/posts/user/' + username + '/-1'
		return axios.get(url)
	}

	getTopPopular(count) {
		const url = config.baseApi + '/v1/public/profile/top_popular/' + count
		return axios.create().get(url)
	}

	getTopActive(count) {
		const url = config.baseApi + '/v1/public/profile/top_active/' + count
		return axios.create().get(url)
	}

	getUsersShows(userId) {
		const url = `${config.baseApi}/v1/public/shows/foruser/${userId}`
		return axios.create().get(url)
	}
}

export default new publicProfileService()
