import axios from 'axios'
import config from "../../config";

export function getShowDetails(showId: string | number) {
	const url = `${config.baseApi}/v1/shows/${showId}`
	return axios.get(url)
}
export function saveShow(data: any) {
	const url = `${config.baseApi}/v1/shows`
	return axios.post(url, data)
}

export function updateShow(data: any, imageDirty = false) {
	const url = `${config.baseApi}/v1/shows/${data.id}${imageDirty ? '?updateShowImage=true' : ''}`
	return axios.put(url, data)
}

export function publishShow(showId: string | number) {
	const url = `${config.baseApi}/v1/shows/${showId}/publish`
	return axios.put(url)
}

export function removeShow(id: any) {
	const url = `${config.baseApi}/v1/shows/${id}`
	return axios.delete(url)
}

export function getPublicShow(id: any) {
	const url = `${config.baseApi}/v1/public/shows/${id}`
	return axios.get(url)
}


export function checkShowByShowCode(showCode: string) {
	const url = `${config.baseApi}/v1/public/shows/showCode/${showCode}`
	return axios.create().get(url)
}

export function getShowStatistics(showId: string | number) {
	const url = `${config.baseApi}/v1/shows/${showId}/statistics`
	return axios.get(url)
}

export function findShows(data: any, operatorOR) {
	let url = config.baseApi + '/v1/public/shows/filter'
	if (operatorOR) {
		url+='/?operator=or'
	}
	return axios.create().post(url, data)
}

export function getAllShows(fromId = -1) {
	let url = config.baseApi + `/v1/public/shows/all/${fromId}`
	return axios.create().get(url)
}

export function saveStreamingCodeService(show, code) {
	let url = config.baseApi + `/v1/shows/${show.id}/streaming`
	const data = {
		streamingCode: code
	}
	return axios.put(url, data)
}

export function getStreamingCode(ticketInvalidationId) {
	let url = config.baseApi + `/v1/public/tickets/streaming`
	const data = {ticketInvalidationId}
	return axios.create().post(url, data)
}