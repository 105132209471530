import axios from 'axios'
import config from '../../config'


class requestsService {
	getRequests() {
		const url = config.baseApi + '/v1/fangouts/requests'
		return axios.get(url)
	}
}


export default new requestsService()
