import axios from 'axios';
import config from '../../config';

class adminService {
  getUsers(fromUserId, size, page) {
    const url =
      config.baseApi +
      `/v1/admin/users/${fromUserId}/?size=${size}&page=${page}`;
    return axios.get(url);
  }

  getUserById(id) {
    const url = config.baseApi + '/v1/admin/users/userId/' + id;
    return axios.get(url);
  }

  updateUser(id, status, data) {
    const url = config.baseApi + '/v1/admin/users/userId/' + id + '/' + status;
    return axios.put(url, data);
  }

  getUserByUsedname(username) {
    const url = config.baseApi + '/v1/admin/users/usernames/' + username;
    return axios.get(url);
  }

  searchByUsedname(username) {
    const url = config.baseApi + '/v1/admin/users/usernames/search/' + username;
    return axios.get(url);
  }

  setUserActivity(id, val) {
    const url =
      config.baseApi +
      '/v1/admin/users/userId/' +
      id +
      '/activity_counter/' +
      val;
    return axios.put(url);
  }

  setUserPopularity(id, val) {
    const url =
      config.baseApi +
      '/v1/admin/users/userId/' +
      id +
      '/popularity_counter/' +
      val;
    return axios.put(url);
  }

  getDisputes() {
    const url = config.baseApi + '/v1/admin/disputes/?resolved=true';
    return axios.get(url);
  }

  resolveDispute(disputeId) {
    const url = config.baseApi + `/v1/admin/disputes/${disputeId}`;
    return axios.put(url);
  }

  generatePremiumKey(data) {
    const url = config.baseApi + '/v1/admin/premium';
    return axios.post(url, data);
  }
}

export default new adminService();
