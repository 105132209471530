import React from 'react'
import {withTheme} from 'styled-components'


class IsVerifiedBadge extends React.PureComponent {

	render() {
		return <i
			title={this.props.isVerified ? 'Verified' : 'Unverified'}
			style={{color: this.props.isVerified ? this.props.theme.primary : this.props.theme.red,
			fontSize: this.props.fontSize}}
			className={'fas fa-certificate text-primary verification'}
		/>
	}
}

IsVerifiedBadge.defaultProps = {
	isVerified: false,
	fontSize: '12px'
}

export default withTheme(IsVerifiedBadge)
