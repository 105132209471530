import React, {useState} from 'react'
import styled from "styled-components";
import {Flex, Label} from "../../styling/globalStyling";

const Expandable = (props) => {
	const [expanded, setExpanded] = useState(false)
	const {children, title} = props
	return <ExpandableComponent>
		<Flex onClick={() => setExpanded(!expanded)} className={'space-between mt2 mb1'}>
			{title ? <Label className={'titleMain'}>{title}</Label> : <span/>}
			<i className={`fas fa-chevron-down ${expanded && 'expanded'}`}/>
		</Flex>
		<div className={`container ${expanded ? 'expanded' : ''}`}>
			{children}
		</div>
	</ExpandableComponent>
}

export default Expandable


const ExpandableComponent = styled.div`
cursor: pointer;
	border-bottom: 1px solid ${({theme}) => theme.middleGray};
	&:hover {
		.titleMain {
			opacity: 0.8;
		}
	}
  .title, .titleMain {
    margin: 0;
  }
	
	i {
		transition: transform 0.2s ease;
		&.expanded {
			transform: rotate(180deg);
		}
	}

  .container {
    height: auto;
    max-height: 0;
    transition: max-height 0.2s;
    overflow: hidden;

    &.expanded {
      max-height: 10000px;
    }
  }

`