import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useLang } from '../useLang';

const NeedLogin = (props: any) => {
  const history = useHistory();
  const lang = useLang();
  const { children, customMessage, from } = props;
  const userData = useSelector((state: any) => state.profile.userData);
  return (
    <div>
      {userData.id ? (
        children
      ) : (
        <Wrapper>
          {customMessage ? (
            <div className={'custom-message'}>{customMessage}</div>
          ) : null}
          <RegisterLoginButtons>
            <button
              onClick={() =>
                history.push({ pathname: '/register', state: { from } })
              }
            >
              {lang.register}
            </button>
            <button
              onClick={() =>
                history.push({ pathname: '/login', state: { from } })
              }
            >
              Login
            </button>
          </RegisterLoginButtons>
        </Wrapper>
      )}
    </div>
  );
};

export default NeedLogin;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.contastBackground}22;
  padding: 16px 0;

  .custom-message {
    text-align: center;
    margin-bottom: 1rem;
    font-style: italic;
  }
`;
const RegisterLoginButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  button {
    padding: 10px 20px;
    width: auto;
    min-width: 100px;
  }
`;
