import config from "../../config";
import axios from "axios";
import {ActionTypes} from "./myFangoutsReducer";


class MyFangoutsService {
	getMyFangouts() {
		const url = config.baseApi + '/v1/fangouts'
		return axios.get(url)
	}
}


const myFangoutService = new MyFangoutsService()

export function getMyFangouts(dispatch) {
	myFangoutService.getMyFangouts()
		.then(res => dispatch({
			type: ActionTypes.SET_MY_FANGOUTS,
			payload: res.data
		}))
}

