import axios from 'axios'
import config from '../../config'

class MyAgentService {
	url = config.baseApi + '/v1/my_manager'
	getMyAgent() {
		return axios.get(this.url)
	}
	updateMyAgent(data) {
		return axios.post(this.url, data)
	}
}

export default new MyAgentService()