import firebase from "firebase/app";
import "firebase/messaging";

export let messaging = null
if (firebase.messaging.isSupported()) {
	const initializedFirebaseApp = firebase.initializeApp({
		// Project Settings => Add Firebase to your web app
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: "fangout-463ed.firebaseapp.com",
		projectId: "fangout-463ed",
		storageBucket: "fangout-463ed.appspot.com",
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_MEASUREMENT_ID
	});
	messaging = initializedFirebaseApp.messaging();
}


export default firebase