import fangoutsPublicService from '../../fangouts/fangoutsPublicService'
import * as showService from "../../createShow/showService";

const actionTypes = {
	SET_SEARCH_USERS: 'SET_SEARCH_USERS',
	SET_SEARCH_FANGOUTS: 'SET_SEARCH_FANGOUTS',
	SET_SEARCH_SHOWS: 'SET_SEARCH_SHOWS',
	SET_SEARCH_TAB: 'SET_SEARCH_TAB',
	RESET_SEARCH_RESULTS: 'RESET_SEARCH_RESULTS',
	SET_SEARCH_TERM: 'SET_SEARCH_TERM'
}

export function searchUsers(dispatch, term) {
	fangoutsPublicService.findUsers(term)
		.then(res => {
			dispatch({
				type: actionTypes.SET_SEARCH_USERS,
				payload: res.data
			})
		})
}

export function searchFangouts(dispatch, data, operatorOR) {
	return fangoutsPublicService.findFangouts(data, operatorOR)
		.then(res => {
			dispatch({
				type: actionTypes.SET_SEARCH_FANGOUTS,
				payload: res.data
			})
			return res.data
		})
}

export function searchShows(dispatch, data, operatorOR) {
	return showService.findShows(data, operatorOR)
		.then(res => {
			dispatch({
				type: actionTypes.SET_SEARCH_SHOWS,
				payload: res.data
			})
			return res.data
		})
}

export function setTab(dispatch, tab) {
	dispatch({
		type: actionTypes.SET_SEARCH_TAB,
		payload: tab
	})
}

export function clearSearchResults(dispatch){
	dispatch({
		type: actionTypes.RESET_SEARCH_RESULTS
	})
}

export function setSearchTerm(dispatch, term) {
	dispatch({
		type: actionTypes.SET_SEARCH_TERM,
		payload: term
	})
}

const initialState = {
	users: [],
	fangouts: [],
	shows: [],
	searchTerm: '',
	tab: 0
}


export function searchReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SET_SEARCH_USERS:
			return Object.assign({}, state, {users: action.payload})
		case actionTypes.SET_SEARCH_FANGOUTS:
			return Object.assign({}, state, {fangouts: action.payload})
		case actionTypes.SET_SEARCH_SHOWS:
			return Object.assign({}, state, {shows: action.payload})
		case actionTypes.SET_SEARCH_TAB:
			return Object.assign({}, state, {tab: action.payload})
		case actionTypes.RESET_SEARCH_RESULTS:
			return Object.assign({}, state, {fangouts: [], users: [], shows: []})
		case actionTypes.SET_SEARCH_TERM:
			return Object.assign({}, state, {searchTerm: action.payload})
		default:
			return state
	}
}
