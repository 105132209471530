import requestsService from './requestsService'

export const ReqActionTypes = {
	SET_REQUESTS: 'SET_REQUESTS'
}


export function getRequests(dispatch) {
	return requestsService.getRequests()
		.then(res => {
			dispatch({
				type: ReqActionTypes.SET_REQUESTS,
				requests: res.data
			})
			return res
		})
}
