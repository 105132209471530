import poweredBy from '../../assets/images/powered_by.svg';
import styled from 'styled-components';

const PoweredByFangout = () => {
  return (
    <Container>
      <img src={poweredBy} alt='fangout.app' />
    </Container>
  );
};
export default PoweredByFangout;

const Container = styled.div`
  background-color: ${({ theme }) => theme.background};
  text-align: right;
  padding: 8px;
  img {
    height: 20px;
  }
`;
