import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { Hr } from 'src/pages/profile/profile';
import {
  Container,
  Flex,
  Link,
  TextContainer,
} from 'src/styling/globalStyling';

const UsloviKoriscenja = () => {
  return (
    <Container>
      <GoBack />
      <Heading title='Uslovi korišćenja' />
      <TextContainer>
        <Hr />
        <Flex className='column'>
          <div>Korisni linkovi:</div>
          <Link href='/#/podaci_kompanije'>Podaci kompanije</Link>
          <Link href='/#/deliveryMethods'>Način dostave</Link>
          <Link href='/#/paymentMethods'>Način plaćanja</Link>
          <Link href='/#/politikaPrivatnosti'>Politika privatnosti</Link>
        </Flex>
        <Hr />
        <ul>
          <li>
            <strong> VAŽNA INFORMACIJA</strong>
          </li>
        </ul>
        <p>
          NIŠVIL FONDACIJA (u daljem tekstu: "<strong>Organizator</strong>").
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> KUPOVINA ULAZNICA</strong>
          </li>
        </ul>
        <ul>
          <ul>
            <li>
              2.1 Kupovina ulaznica na prodajnim mestima Nišvil Fondacija-a
            </li>
          </ul>
        </ul>
        <p>Pod prodajnim mestom Nišvil Fondacija-a se podrazumevaju:</p>
        <ul>
          <li>- sopstvena prodajna mesta Nišvil Fondacija-a,</li>
          <li>
            - internet prodavnica na
            veb-sajtu&nbsp;ulaznice-nisville.shop&nbsp;(u daljem tekstu: "
            <strong>Internet prodavnica</strong>"); i
          </li>
          <li>- prodajna mesta partnera.</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <ul>
            <li>
              2.2 Kupovina ulaznica na prodajnim mestima Nišvil Fondacija-a i
              prodajnim mestima partnera
            </li>
          </ul>
        </ul>
        <p>
          Ulaznice je moguće kupiti na prodajnim mestima Nišvil Fondacija-a i
          njegovih partnera. Ulaznice za događaje se štampaju i po isplati cene
          se izdaju kupcu.
        </p>
        <p>
          Nakon izvršene kupovine ulaznica preko prodajnih mesta Nišvil
          Fondacija-a kupovina je obavezujuća i kupac ne može odustati od
          izvršene kupovine u smislu člana 36 stav 1 tačka 12 Zakona o zaštiti
          potrošača ("Sl. glasnik RS", br. 88/2021), s obzirom na to da Nišvil
          Fondacija prodajom ulaznica pruža usluge povezane sa slobodnim
          aktivnostima koje se odvijaju u tačno određeno vreme odnosno u
          određenom periodu (odnosno za koje postoji konkretni rok ili period
          izvršenja). U skladu sa članom 26. stav 1. tačka 6. Zakona o zaštiti
          potrošača kupac prihvatanjem ovih Uslova korišćenja izričito
          izjavljuje da je obavešten od strane Nišvil Fondacija-a da ne može da
          koristi pravo na odustanak od ugovora, na šta kupac pristaje.
        </p>
        <ul>
          <li>
            2.3 Kupovina ulaznica na Internet
            prodavnici&nbsp;ulaznice-nisville.shop
          </li>
        </ul>
        <p>
          Nakon izvršene kupovine ulaznica na Internet prodavnici, kupovina je
          obavezujuća i kupac ne može odustati od izvršene kupovine u smislu
          člana 36. stav 1 tačke 12 i 13 Zakona o zaštiti potrošača, s obzirom
          na to da Nišvil Fondacija prodajom ulaznica pruža usluge povezane sa
          slobodnim aktivnostima koje se odvijaju u tačno određeno vreme odnosno
          u određenom periodu (odnosno za koje postoji konkretni rok ili period
          izvršenja), kao i da je reč o isporuci digitalnog sadržaja u vidu PDF
          ulaznica (PDF-TICKET i E-WALLET). U skladu sa članom 36 stav 1 tačka
          13 i članom 26. stav 1. tačka 6 Zakona o zaštiti potrošača, kupac
          prihvatanjem ovih Uslova korišćenja izričito izjavljuje da je
          obavešten od strane Nišvil Fondacija-a da ne može koristiti pravo na
          odustanak od ugovora, na šta kupac pristaje.
        </p>
        <p>
          Kada izvršite porudžbinu na internet prodavnici ulaznice-nisville.shop
          izjavljujete da imate više od 18 godina i da imate poslovnu sposobnost
          za zaključenje ovog pravnog posla.
        </p>
        <p>
          Za kupovinu ulaznica preko internet prodavnice ulaznice-nisville.shop
          pratite sledeće korake:
        </p>
        <p>
          korak 1 &ndash; upisivanje ličnih podataka i prihvatanje opštih
          uslova;
        </p>
        <p>korak 2 - biranje događaja i ulaznica prema cenovnoj kategoriji;</p>
        <p>korak 3 &ndash; plaćanje;</p>
        <p>korak 4 &ndash; potvrda porudžbine.</p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> NAČINI ISPORUKE</strong>
          </li>
        </ul>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ul>
          <li>PDF-TICKET</li>
        </ul>
        <p>
          Vaša PDF ulaznica će Vam biti poslata na e-mail. Ulaznicu je poželjno
          odštampati na papiru A4 formata. Ulaznicu čuvajte. Odštampanu ulaznicu
          donesite na događaj.
        </p>
        <ul>
          <li>E-WALLET (e novčanik)</li>
        </ul>
        <p>
          Vaša ulaznica će Vam biti poslata u Vašem e-novčaniku na portalu
          https://ulaznice-nisville.shop Ukoliko imate telefon pri sebi uvek
          možete pristupiti ulaznicama. Ulaznice iz e-novčanika možete deliti
          (sherovati) klikom na odgovarajuće share dugme.&nbsp;
        </p>
        <ul>
          <li>
            <strong> PLAĆANJE ULAZNICA</strong>
          </li>
        </ul>
        <p>
          Ulaznice kupljene na prodajnim mestima Nišvil Fondacija-a mogu se
          platiti gotovinom ili platnim karticama.
        </p>
        <p>
          Ulaznice kupljene na Internet prodavnici plaćaju se isključivo platnom
          karticom.
        </p>
        <p>
          Svaka ulaznica postaje važeća nakon celokupne uplate cene ulaznica i
          troškova obrade prikazanih prilikom kupovine. PDV je uračunat u cenu i
          nema skrivenih troškova.
        </p>
        <p>
          Troškovi obrade za kupovinu ulaznica na Internet prodavnici
          obračunavaju se na nivou porudžbine i iznose 4% od cene ulaznice.
          Troškovi obrade se odnose na pokriće nužnih troškova obrade platnih
          transakcija putem platnih kartica, te administrativnih, materijalnih i
          ostalih operativnih troškova Nišvil Fondacija-a.
        </p>
        <ul>
          <li>- Opšti uslovi prihvatanja platnih kartica</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>NIšVIL FONDACIJA</strong>, sa sedištem na adresi Bul Dr Zorana
          Đinđića 58a, matični broj:<strong> 17660632</strong>, PIB:
          <strong> 104623652 </strong>nastupa u pravnom prometu kao distributer
          za prodaju ulaznica na Internet prodavnici.
        </p>
        <p>
          Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije &ndash;
          dinar (RSD). Za informativni prikaz cena u drugim valutama koristi se
          srednji kurs Narodne Banke Srbije. Iznos za koji će biti zadužena Vaša
          platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u
          istu po kursu koji koriste kartičarske kompanije, a koji nama u
          trenutku kupovine ulaznice ne može biti poznat. Kao rezultat ove
          konverzije postoji mogućnost neznatne razlike u odnosu na originalne
          cene navedene na našem sajtu. Hvala Vam na razumevanju.
        </p>
        <p>
          Prilikom unošenja podataka o platnoj kartici, poverljive informacija
          se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi
          upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije
          kriptografske tehnologije. Sigurnost podataka prilikom kupovine
          garantuje procesor platnih kartica, pa se tako kompletni proces
          naplate obavlja u sigurnom okruženju. U nijednom trenutku podaci o
          platnoj kartici nisu dostupni našem sistemu.
        </p>
        <p>
          U slučaju povraćaja sredstava kupcu koji je prethodno platio ulaznicu
          nekom od platnih kartica, delimično ili u celosti, a bez obzira na
          razlog vraćanja, Nišvil Fondacija povraćaj vrši isključivo preko VISA,
          EC/MC i Maestro metoda plaćanja, što znači da će banka obaviti
          povraćaj sredstava na račun korisnika kartice.
        </p>
        <ul>
          <li>- PRAVNA LICA - PLAĆANJE NA OSNOVU PREDRAČUNA</li>
        </ul>
        <p>
          Za poručivanje i plaćanje ulaznica putem predračuna za pravno lice,
          potrebno je na e-mail adresu tickets@nisville.com poslati sledeće
          podatke: naziv firme, adresu, PIB i e-mail adresu na koju naša služba
          treba da pošalje predračun. Takođe je potrebno da se naglasi za koji
          događaj se poručuju ulaznice, količinu ulaznica kao i cenovnu
          kategoriju ulaznica za koje je potreban predračun.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> VRAĆANJE I ZAMENA ULAZNICA</strong>
          </li>
        </ul>
        <ul>
          <ul>
            <li>- PRAVO NA VRAĆANJE ULAZNICA</li>
          </ul>
        </ul>
        <p>
          Pravo na vraćanje ulaznica kupac ulaznice ima samo u sledećim
          slučajevima:
        </p>
        <ul>
          <li>- otkazivanje događaja;</li>
          <li>
            - odlaganje događaja na rok duži od 120 dana od dana planiranog
            održavanja događaja;
          </li>
          <li>
            - odlaganje događaja zbog nastupanja više sile koja traje duže od
            180 dana od dana planiranog održavanja događaja.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          Viša sila, tokom koje miruju obaveze Organizatora, nastupa kada
          Organizator događaja nije u stanju da ispuni svoju obavezu održavanja
          događaja zbog rata, pobune, terorističkih akata, štrajkova, nesreća,
          požara, blokade, poplave, odluke ili akcije epidemiološke vlasti,
          prirodne katastrofe, teškog poremećaja u snabdevanju energijom ili
          bilo čega drugog, iz razloga koji je van njegove moći i kontrole.
        </p>
        <p>
          Ako je događaj otkazan ili odložen zbog navedenih okolnosti, Nišvil
          Fondacija će o tome obavestiti kupce što je pre moguće putem veb-sajta
          www.nisville.com. Ukoliko je događaj odložen za novi termin, povraćaj
          novca za kupljene ulaznice korisnicima kojima novi termin ne odgovara
          biće moguć do početka događaja u novom terminu, osim ukoliko
          organizator događaja ne odluči drugačije.
        </p>
        <p>
          U slučajevima navedenim u prethodnom stavu, ulaznica će biti
          stornirana, a novac vraćen kupcu na tekući račun ili na platnu karticu
          preko VISA, EC/MC i Maestro metoda plaćanja,
        </p>
        <p>
          U slučaju povraćaja novca za kupovinu obavljenu na Internet
          prodavnici, kupcu će biti vraćen iznos cene ulaznice za predmetni
          događaj. Kupcu neće biti izvršen povraćaj troškova obrade od 4% od
          cene ulaznice, budući da se usluga Nišvil Fondacijaa smatra u celosti
          pruženom u trenutku kupovine ulaznice, o čemu je kupac obavešten i na
          šta pristaje.
        </p>
        <p>
          U slučaju otkazivanja ili odlaganja događaja ili drugih promena
          programa u vezi sa događajem, ni u kom slučaju neće biti izvršen
          povraćaj novca za povezane troškove (npr. prevoz, hotelski smeštaj,
          troškovi isporuke itd.).
        </p>
        <ul>
          <li>- OBAVEZA VRAĆANJA NOVCA</li>
        </ul>
        <p>
          U slučajevima kada Nišvil Fondacija ima obavezu povraćaja novca
          kupcima ulaznica, Nišvil Fondacija će izvršiti povraćaj novca na
          tekući račun kupca ili na prodajnom mestu gde su ulaznice kupljene, i
          to u roku od:
        </p>
        <ul>
          <li>- 90 dana od dana otkazivanja događaja,</li>
          <li>
            - 60 dana od dana planiranog održavanja događaja koji je odložen na
            rok duži od 120 dana, i
          </li>
          <li>
            - 240 dana od dana planiranog održavanja događaja u slučaju
            nastupanja više sile u trajanju preko 180 dana usled koje
            Organizator nije u mogućnosti da održi događaj,
          </li>
        </ul>
        <p>a sve pod uslovom da je kupac podneo zahtev za vraćanje novca.</p>
        <ul>
          <li>
            <strong> ZAšTITA PODATAKA</strong>
          </li>
        </ul>
        <p>
          Nišvil Fondacija se obavezuje da čuva privatnost svih kupaca.
          Prikupljamo samo neophodne, osnovne podatke o kupcima/ korisnicima i
          podatke neophodne za poslovanje i informisanje korisnika u skladu sa
          dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge.
          Dajemo kupcima mogućnost izbora uključujući mogućnost odluke da li
          žele ili ne da se izbrišu sa mailing lista koje se koriste za
          marketinške kampanje. Svi podaci o korisnicima/kupcima se strogo
          čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za
          obavljanje posla. Svi zaposleni u Nišvil Fondacija-u (i poslovni
          partneri) odgovorni su za poštovanje načela zaštite privatnosti.
        </p>
        <p>
          Korisnik prihvata da lični podaci mogu biti elektronski obrađeni od
          strane Nišvil Fondacija-a za potrebe izvršenja usluge. U skladu s
          pravnim odredbama, korisnik prihvata da se njegovi podaci proslede
          partnerskim kompanijama (kompanijama kćerkama ili poslovnicama) kao i
          organizatorima događaja za koje su ulaznice kupljene.
        </p>
        <p>
          Banka ne učestvuje u prikupljanju ovih ličnih podataka, te se svi
          podaci isključivo distribuiraju Organizatoru događaja.
        </p>
        <p>
          Klikom na sledeći link možete videti našu&nbsp;
          <Link href='https://ulaznice-nisville.shop/'>
            Politiku privatnosti.
          </Link>
        </p>
        <p>&nbsp;&nbsp;</p>
        <ul>
          <li>
            <strong> OBAVEšTENJE</strong>
          </li>
        </ul>
        <p>
          U cilju zaštite od zloupotrebe, Nišvil Fondacija u određenim
          okolnostima (ukoliko se transakcija pri proveri pokaže kao sumnjiva)
          može zahtevati od kupca dodatne podatke &ndash; sken platne kartice sa
          vidljive četiri poslednje cifre. Ukoliko ne prihvatate gore navedene
          uslove poslovanja, možete zatražiti povraćaj novčanih sredstava koja
          će biti vraćena na račun sa koga je izvršeno plaćanje.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> PRAVA KORIšĆENJA SOFTVERA</strong>
          </li>
        </ul>
        <p>
          Sistemsko rešenje web sajta www.nisville.com je vlasništvo kompanije
          Nišvil Fondacija koja je isključivi nosilac prava korišćenja
          softverskog rešenja (mape, ulaznice, sistem komunikacije&hellip;).
          Zabranjeno je svako korišćenje softverskog rešenja bez izričite
          prethodne saglasnosti vlasnika softvera i u skladu sa zakonom
          Republike Srbije, kao i važećim međunarodnim ugovorima. U slučaju
          kršenja ove odredbe, Nišvil Fondacija može da traži sudsku zaštitu.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> FISKALNI RAČUN</strong>
          </li>
        </ul>
        <p>
          Kupac je saglasan da elektronski fiskalni račun dobije u zasebnom
          e-mailu na adresu koja je uneta prilikom kupovine ulaznica. Na
          fiskalnom računu iskazan je hiperlink za verifikaciju, kojem se može
          pristupiti preko svih uređaja koji imaju pristup internetu.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> VANSUDSKO REšAVANJE SPOROVA</strong>
          </li>
        </ul>
        <p>
          Potrošački spor može se rešiti postupkom vansudskog rešavanja
          potrošačkih sporova. Kao trgovac smo dužni da vas obavestimo da smo po
          zakonu obavezni da učestvujemo u ovom postupku. Vansudsko rešavanje
          potrošačkih sporova obavlja se na transparentan, efikasan, brz i
          pravičan način pred telom za vansudsko rešavanje potrošačkih sporova.
        </p>
        <p>
          Ministarstvo sačinjava listu tela i javno je objavljuje. Dostupna je
          na adresi&nbsp;
          <Link href='https://vansudsko.mtt.gov.rs/adrbodies'>
            https://vansudsko.mtt.gov.rs/adrbodies&nbsp;
          </Link>
        </p>
        <p>
          Postupak pred telom može da pokrene potrošač samo ukoliko je prethodno
          izjavio reklamaciju ili prigovor trgovcu. Potrošač protekom jedne
          godine od dana (bezuspešnog) podnošenja reklamacije gubi pravo na
          podnošenje predloga za vansudsko rešavanje spora.
        </p>
        <p>
          Vansudsko rešavanje potrošačkog spora može da traje najduže 90 dana od
          dana podnošenja predloga.
        </p>
        <p>
          Vansudsko rešavanje potrošačkih sporova, ne primenjuje se, pored
          ostalog:
        </p>
        <ul>
          <li>
            - u potrošačkim sporovima koji su predmet Zakona o zaštiti
            potrošača, ako je vansudsko rešavanje sporova uređeno posebnim
            zakonom, a naročito u oblasti pružanja elektronskih komunikacionih
            usluga, poštanskih usluga, finansijskih usluga, osim finansijskih
            pogodbi, usluga putovanja;
          </li>
          <li>
            - za rešavanje sporova po procedurama koje je ustanovio sam trgovac;
          </li>
          <li>- na neposredne pregovore između potrošača i trgovca;</li>
          <li>
            - na pokušaj mirenja strana povodom spora u parničnom postupku;
          </li>
          <li>- u postupcima koje je trgovac pokrenuo protiv potrošača.</li>
          <li>&nbsp;</li>
        </ul>
        <p>
          Svaka stranka u postupku vansudskog rešavanja potrošačkog spora plaća
          svoje troškove (troškovi zastupanja, putni troškovi i sl.). Rad tela
          za vansudsko rešavanje potrošačkog spora je besplatan za stranke u
          postupku vansudskog rešavanja potrošačkog spora.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> SUDSKA NADLEŽNOST</strong>
          </li>
        </ul>
        <p>
          Za sve sporove koji ne mogu biti rešeni sporazumom, nadležan je sud u
          Nišu.
        </p>
        <p>&nbsp;</p>
      </TextContainer>
    </Container>
  );
};

export default UsloviKoriscenja;
