export const ActionTypes = {
	SET_MY_FANGOUTS: 'SET_MY_FANGOUTS'
}

export function myFangoutsReducer(state = [], action) {
	switch(action.type) {
		case ActionTypes.SET_MY_FANGOUTS:
			return action.payload
		default:
			return state
	}
}