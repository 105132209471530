import React, { useEffect, useState } from 'react';
import adminService from '../../adminService';
import styled from 'styled-components';

const DisputeItem = (props) => {
  const { d, resolve } = props;
  return (
    <DItem>
      <div>ID: {d.id}</div>
      <div className={'flex mt1'}>
        <div className={'mr3'}>
          <div>Celebrity:</div>
          <img width={200} src={d.fangout.celebrity.profilePhoto} />
          <div>
            {d.fangout.celebrity.name} - {d.fangout.celebrity.fullName}
          </div>
          <div>{d.fangout.celebrity.email}</div>
        </div>
        <div>
          <div>Fan:</div>
          <img width={200} src={d.fangout.fan.profilePhoto} />
          <div>
            {d.fangout.fan.name} - {d.fangout.fan.fullName}
          </div>
          <div>{d.fangout.fan.email}</div>
        </div>
      </div>
      <div className={'mt1'}>Message:</div>
      <div>{d.message}</div>
      <Button className={'mt2'} onClick={() => resolve(d.id)}>
        Resolve
      </Button>
    </DItem>
  );
};

function Disputes() {
  const [disputes, setDisputes] = useState([]);
  useEffect(() => {
    getDisputes();
  }, []);

  const getDisputes = () => {
    adminService.getDisputes().then((res) => setDisputes(res.data));
  };

  const resolve = (id) => {
    adminService.resolveDispute(id).then((res) => {
      getDisputes();
    });
  };

  return (
    <AdminPanel style={{ width: '100%', userSelect: 'text' }}>
      <Content>
        <h3>Disputes</h3>
        <div>
          {disputes.map((d) => (
            <DisputeItem key={d.id} d={d} resolve={resolve} />
          ))}
        </div>
      </Content>
    </AdminPanel>
  );
}

export default Disputes;

const Content = styled.div`
  padding: 20px;
  width: 100%;
`;

const DItem = styled.div`
  border-bottom: 1px solid #dedede;
  padding: 1rem 0;
`;

const Button = styled.button`
  background: #fafafa;
  border: 1px solid #eee;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  width: 200px;
  &:hover {
    background: #cecece;
  }
`;

const AdminPanel = styled.div`
  background: #efefef;
  color: black;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  /* -webkit-scrollbar {
  		display: none;
	} */
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -webkit-overflow-scrolling: touch !important;

  img.profilePhoto {
    height: 100px;
  }
  .userDetails {
    .info {
      font-size: 12px;
      font-weight: 500;
      display: inline-block;
      width: 100px;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
    }
    button.verification {
      max-width: 150px;
      margin-right: 15px;
    }
    .idphoto img {
      max-width: 100%;
      height: 800px;
    }
  }

  .rt-tr-group {
    cursor: pointer;
    &:hover {
      background: #dadada;
    }
  }
`;
