import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import style from './TextEditor.module.scss';

const toolbarOptions = {
  options: [
    'inline',
    'blockType',
    // 'image',
    'list',
    'textAlign',
    'link',
    'history',
  ],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3'],
  },
  fontSize: {
    options: [10, 12, 16, 18, 24],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  link: {
    defaultTargetOption: '_blank',
  },
};

interface TextEditorProps {
  editorState: EditorState;
  onEditorStateChange: any;
}

const TextEditor = (props: TextEditorProps) => {
  const { editorState, onEditorStateChange } = props;
  return (
    <Editor
      editorState={editorState}
      toolbarClassName={style.rdwToolbar}
      wrapperClassName={style.rdwWrapper}
      editorClassName={style.rdwEditor}
      textareaClassName={style.rdwTextarea}
      onEditorStateChange={onEditorStateChange}
      toolbar={toolbarOptions}
    />
  );
};

export default TextEditor;
