import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const portalRoot = document.getElementById('portal')

const PortalElement = styled.div`
	// display: flex;
	// justify-content: center;
	// align-items: center;
	// position: absolute;
	// top: 0;
	// bottom: 0;
	// left: 0;
	// right: 0;
`

export default class Portal extends React.Component {
	constructor(props) {
		super(props)
		this.el = document.createElement('div')
	}

	componentDidMount() {
		portalRoot.appendChild(this.el)
	}

	componentWillUnmount() {
		portalRoot.removeChild(this.el)
	}

	render() {
		const {children} = this.props
		const portalComponent = <PortalElement>
			{children}
		</PortalElement>
		return ReactDOM.createPortal(portalComponent, this.el)
	}
}
